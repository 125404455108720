import {
  BestSellersListOptions,
  Book,
  Books,
  GetBookStatsResponse,
  SearchBooksQuery,
  SearchBooksResponse,
} from '@mylibrary/api-types';
import * as Sentry from '@sentry/react';
import { Span, Transaction } from '@sentry/types';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

/**
 * Gets a book by its ISBN number.
 * @param {string} isbn
 * @param {string} authToken
 * @returns {Promise<Book>}
 */
export const GetBookByISBN = (
  BooksAPI: AxiosInstance,
  isbn: string,
  authToken: string
): Promise<Book | undefined> => {
  const transaction: Transaction | undefined = Sentry.getCurrentHub().getScope()?.getTransaction();
  const span: Span | undefined = transaction?.startChild({
    op: 'http.client',
    description: `GET /api/books/:isbn`,
  });
  const encodedAuthToken: string = btoa(authToken);
  return BooksAPI.get<{ [key: string]: Book }>(`/books/${isbn}`, {
    headers: {
      authorization: `Bearer ${encodedAuthToken}`,
    },
  })
    .then((response) => {
      span?.setTag('http.status_code', response.status);
      span?.setHttpStatus(response.status);
      span?.setStatus('ok');
      return response.data[isbn];
    })
    .catch((error: AxiosError) => {
      const status: number = error.response?.status || 500;
      span?.setTag('http.status_code', status);
      span?.setHttpStatus(status);
      throw error;
    })
    .finally(() => span?.finish());
};

/**
 * Gets multiple books by their ISBN's.
 * @param {string[]} isbns
 * @param {string} authToken
 * @returns {Promise<Books>}
 */
export const GetBooksByISBN = (
  BooksAPI: AxiosInstance,
  isbns: string[],
  authToken: string
): Promise<Books> => {
  const transaction: Transaction | undefined = Sentry.getCurrentHub().getScope()?.getTransaction();
  const span: Span | undefined = transaction?.startChild({
    op: 'http.client',
    description: `GET /api/books?isbns=:isbns`,
  });
  const encodedAuthToken: string = btoa(authToken);
  return BooksAPI.get<Books>(`/books`, {
    params: {
      isbns: isbns.join(','),
    },
    headers: {
      authorization: `Bearer ${encodedAuthToken}`,
    },
  })
    .then((response: AxiosResponse<Books>) => {
      span?.setTag('http.status_code', response.status);
      span?.setHttpStatus(response.status);
      span?.setStatus('ok');
      return response.data;
    })
    .catch((error: AxiosError) => {
      const status: number = error.response?.status || 500;
      span?.setTag('http.status_code', status);
      span?.setHttpStatus(status);
      throw error;
    })
    .finally(() => span?.finish());
};

/**
 * Given the url to a book cover, gets the book cover and returns it as a base64 string.
 * @param {string} url
 * @param {string} authToken
 * @returns {Promise<Blob>}
 */
export const GetBookCover = (
  BooksAPI: AxiosInstance,
  url: string,
  authToken: string
): Promise<Blob> => {
  const transaction: Transaction | undefined = Sentry.getCurrentHub().getScope()?.getTransaction();
  const span: Span | undefined = transaction?.startChild({
    op: 'http.client',
    description: `GET /api/books/cover?url=:url`,
  });
  const encodedAuthToken: string = btoa(authToken);
  return BooksAPI.get<Blob>(`/books/cover`, {
    responseType: 'blob',
    params: {
      url,
    },
    headers: {
      authorization: `Bearer ${encodedAuthToken}`,
    },
  })
    .then((response: AxiosResponse<Blob>) => {
      span?.setTag('http.status_code', response.status);
      span?.setHttpStatus(response.status);
      span?.setStatus('ok');
      return response.data;
    })
    .catch((error: AxiosError) => {
      const status: number = error.response?.status || 500;
      span?.setTag('http.status_code', status);
      span?.setHttpStatus(status);
      throw error;
    })
    .finally(() => span?.finish());
};

/**
 * Gets info on book stats.
 * @param {AxiosInstance} BooksAPI
 * @param {string} authToken
 * @returns {Promise<GetBookStatsResponse>}
 */
export const GetBookStats = (
  BooksAPI: AxiosInstance,
  authToken: string
): Promise<GetBookStatsResponse> => {
  const transaction: Transaction | undefined = Sentry.getCurrentHub().getScope()?.getTransaction();
  const span: Span | undefined = transaction?.startChild({
    op: 'http.client',
    description: `GET /api/books/stats`,
  });
  const encodedAuthToken: string = btoa(authToken);
  return BooksAPI.get<GetBookStatsResponse>(`/books/stats`, {
    headers: {
      authorization: `Bearer ${encodedAuthToken}`,
    },
  })
    .then((response: AxiosResponse<GetBookStatsResponse>) => {
      span?.setTag('http.status_code', response.status);
      span?.setHttpStatus(response.status);
      span?.setStatus('ok');
      return response.data;
    })
    .catch((error: AxiosError) => {
      const status: number = error.response?.status || 500;
      span?.setTag('http.status_code', status);
      span?.setHttpStatus(status);
      throw error;
    })
    .finally(() => span?.finish());
};

/**
 * Get featured books.
 * @param {AxiosInstance} BooksAPI
 * @param {string} authToken
 * @returns {Promise<Book[]>}
 */
export const GetFeaturedBooks = (BooksAPI: AxiosInstance, authToken: string): Promise<Book[]> => {
  const transaction: Transaction | undefined = Sentry.getCurrentHub().getScope()?.getTransaction();
  const span: Span | undefined = transaction?.startChild({
    op: 'http.client',
    description: `GET /api/books/featured`,
  });
  const encodedAuthToken: string = btoa(authToken);
  return BooksAPI.get<Book[]>(`/books/featured`, {
    headers: {
      authorization: `Bearer ${encodedAuthToken}`,
    },
  })
    .then((response: AxiosResponse<Book[]>) => {
      span?.setTag('http.status_code', response.status);
      span?.setHttpStatus(response.status);
      span?.setStatus('ok');
      return response.data;
    })
    .catch((error: AxiosError) => {
      const status: number = error.response?.status || 500;
      span?.setTag('http.status_code', status);
      span?.setHttpStatus(status);
      throw error;
    })
    .finally(() => span?.finish());
};

/**
 * Get best selling books.
 * @param {AxiosInstance} BooksAPI
 * @param {string} authToken
 * @param {BestSellersListOptions} bestSellerType
 * @returns {Promise<Book[]>}
 */
export const GetBestSellers = (
  BooksAPI: AxiosInstance,
  authToken: string,
  bestSellerType: BestSellersListOptions
): Promise<Book[]> => {
  const transaction: Transaction | undefined = Sentry.getCurrentHub().getScope()?.getTransaction();
  const span: Span | undefined = transaction?.startChild({
    op: 'http.client',
    description: `GET /api/books/best-sellers`,
  });
  const encodedAuthToken: string = btoa(authToken);
  return BooksAPI.get<Book[]>(`/books/best-sellers`, {
    headers: {
      authorization: `Bearer ${encodedAuthToken}`,
    },
    params: {
      bestSellerType,
    },
  })
    .then((response: AxiosResponse<Book[]>) => {
      span?.setTag('http.status_code', response.status);
      span?.setHttpStatus(response.status);
      span?.setStatus('ok');
      return response.data;
    })
    .catch((error: AxiosError) => {
      const status: number = error.response?.status || 500;
      span?.setTag('http.status_code', status);
      span?.setHttpStatus(status);
      throw error;
    })
    .finally(() => span?.finish());
};

/**
 * Search books or authors.
 * @param {AxiosInstance} BooksAPI
 * @param {string} authToken
 * @param {SearchBooksQuery} params
 * @returns {Promise<SearchBooksResponse>}
 */
export const SearchBooks = (
  BooksAPI: AxiosInstance,
  authToken: string,
  params: SearchBooksQuery
): Promise<SearchBooksResponse> => {
  const foundTransaction: Transaction | undefined = Sentry.getCurrentHub()
    .getScope()
    ?.getTransaction();
  const transaction: Transaction = foundTransaction
    ? foundTransaction
    : Sentry.startTransaction({
        name: `search-${params.searchType}`,
        op: 'standalone-api-request',
      });
  const span: Span = transaction.startChild({
    op: 'http.client',
    description: `GET /api/books/search`,
  });
  const encodedAuthToken: string = btoa(authToken);
  return BooksAPI.get<SearchBooksResponse>(`/books/search`, {
    headers: {
      authorization: `Bearer ${encodedAuthToken}`,
    },
    params,
  })
    .then((response: AxiosResponse<SearchBooksResponse>) => {
      span.setTag('http.status_code', response.status);
      span.setHttpStatus(response.status);
      span.setStatus('ok');
      return response.data;
    })
    .catch((error: AxiosError) => {
      const status: number = error.response?.status || 500;
      span.setTag('http.status_code', status);
      span.setHttpStatus(status);
      throw error;
    })
    .finally(() => {
      span.finish();
      transaction.finish();
    });
};
