import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import {
  ActivityContextType,
  AuthContextType,
  BooksContextType,
  MyLibraryContextType,
  useActivity,
  useAuth,
  useBooks,
  useMyLibrary,
} from '@libs/apps-shared/contexts';
import {
  Activity,
  ActivityItem,
  BorrowerInfoForm,
  MyLibraryBookItem,
  UserbaseError,
} from '@libs/apps-shared/custom-types';
import { arrowUndoOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Button from '../../components/core/Button';
import MarkReturnedDatePicker from '../../components/forms/MarkReturnedDatePicker';
import BorrowerForm from '../../components/lend-slides/choose-borrower/BorrowerForm';
import MyLibraryListItem from '../../components/lists/library/MyLibraryListItem';
import { useAvailableCopies } from '../../hooks/useAvailableCopies';
import { useDebounce } from '../../hooks/useDebounce';

const ActivityDetails = () => {
  let { itemId } = useParams<{ itemId: string }>();
  const { currentUser }: AuthContextType = useAuth();
  const { activity, isFetchingActivity, updateActivity }: ActivityContextType = useActivity();
  const { myLibrary }: MyLibraryContextType = useMyLibrary();
  const { getBook }: BooksContextType = useBooks();
  const [activityItem, setActivityItem] = useState<Activity>();
  const [bookItemFromLibrary, setBookItemFromLibrary] = useState<MyLibraryBookItem | null>(null);
  const [notFoundError, setNotFoundError] = useState<boolean>(false);
  const [borrowerInfo, setBorrowerInfo] = useState<BorrowerInfoForm>();
  const debouncedBorrowerInfo = useDebounce(borrowerInfo, 1000);
  const [wantsToReturn, setWantsToReturn] = useState<boolean>(false);
  const { availableCopies } = useAvailableCopies(bookItemFromLibrary?.itemId);

  /**
   * Updates the activity.
   * @param {string} itemId
   * @param {Activity} activity
   */
  const handleUpdateActivity = async (itemId: string, activity: Activity): Promise<void> => {
    try {
      await updateActivity(itemId, activity);
    } catch (error) {
      const err: UserbaseError = error as UserbaseError;
      alert(err.message);
    }
  };

  const markAsStillBeingBorrowed = () => {
    if (!!activityItem && !!debouncedBorrowerInfo?.valid) {
      const { values }: BorrowerInfoForm = debouncedBorrowerInfo;
      handleUpdateActivity(itemId, {
        ...activityItem,
        borrower: {
          ...(activityItem.borrower.borrowerType === 'user'
            ? {
                ...activityItem.borrower,
                username: values.username!,
                firstName: values.firstName,
              }
            : {
                ...activityItem.borrower,
                firstName: values.firstName!,
              }),
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          email: values.email,
        },
        borrowDate: values.borrowDate,
        dueDate: values.dueDate,
        numberOfCopiesBorrowed: values.numberOfCopiesBorrowed,
        notes: values.notes,
        dateReturned: null,
      });
    }
  };

  useEffect(() => {
    const foundActivityItem: Activity | undefined = activity.find((a: ActivityItem) => {
      return a.itemId === itemId;
    })?.item;
    if (!!foundActivityItem && currentUser) {
      setActivityItem(foundActivityItem);
      (async () => {
        try {
          await getBook(foundActivityItem.book.isbn, currentUser.authToken);
        } catch (error) {
          alert('Error getting book details: ' + (error as Error).message);
        }
      })();
      const foundBookItem: MyLibraryBookItem | undefined = myLibrary.find(
        (b: MyLibraryBookItem) => {
          return b.itemId === foundActivityItem.book.itemId;
        }
      );
      if (!!foundBookItem) setBookItemFromLibrary(foundBookItem);
    } else if (!isFetchingActivity) {
      setNotFoundError(true);
    }
  }, [itemId, activity, isFetchingActivity, currentUser]);

  useEffect(() => {
    if (!!activityItem && !!debouncedBorrowerInfo?.valid) {
      const { values }: BorrowerInfoForm = debouncedBorrowerInfo;
      handleUpdateActivity(itemId, {
        ...activityItem,
        borrower: {
          ...(activityItem.borrower.borrowerType === 'user'
            ? {
                ...activityItem.borrower,
                username: values.username!,
                firstName: values.firstName,
              }
            : {
                ...activityItem.borrower,
                firstName: values.firstName!,
              }),
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          email: values.email,
        },
        borrowDate: values.borrowDate,
        dueDate: values.dueDate,
        numberOfCopiesBorrowed: values.numberOfCopiesBorrowed,
        notes: values.notes,
        dateReturned: values.dateReturned,
      });
    }
  }, [debouncedBorrowerInfo]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="pl-2">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/app/lending" />
          </IonButtons>
          {/* <IonTitle>Lend Book</IonTitle> */}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {activityItem && (
          <MarkReturnedDatePicker
            itemId={itemId}
            activity={activityItem}
            wantsToReturn={wantsToReturn}
            onCancel={() => setWantsToReturn(false)}
            onMarkReturned={() => setWantsToReturn(false)}
          />
        )}
        <div className="pt-4">
          {!!bookItemFromLibrary && (
            <MyLibraryListItem isbn={bookItemFromLibrary.item.isbn} lines="none" />
          )}
        </div>
        <div className="p-4">
          <hr className="bg-black dark:bg-white mt-4 mb-8" />
          <h2>Borrower Details</h2>
          {!!activityItem && (
            <BorrowerForm
              username={activityItem.borrower.username}
              showUsername={activityItem.borrower.borrowerType === 'user'}
              firstName={activityItem.borrower.firstName}
              lastName={activityItem.borrower.lastName}
              phoneNumber={activityItem.borrower.phoneNumber}
              email={activityItem.borrower.email}
              borrowDate={activityItem.borrowDate}
              dueDate={activityItem.dueDate}
              dateReturned={activityItem.dateReturned}
              numberOfCopiesBorrowed={activityItem.numberOfCopiesBorrowed}
              maxNumberOfCopies={
                !!activityItem.dateReturned && bookItemFromLibrary
                  ? bookItemFromLibrary.item.numberOfCopies
                  : availableCopies + activityItem.numberOfCopiesBorrowed
              }
              notes={activityItem.notes}
              borrowerInfoChanged={setBorrowerInfo}
              markAsStillBeingBorrowed={markAsStillBeingBorrowed}
            />
          )}
        </div>
      </IonContent>
      {!notFoundError && !!activityItem && !activityItem.dateReturned && (
        <IonFooter>
          <IonToolbar className="px-2">
            <Button
              text={
                <>
                  <span>Return</span>
                  <IonIcon icon={arrowUndoOutline} className="ml-2" />
                </>
              }
              className="w-full"
              color="success"
              onClick={() => setWantsToReturn(true)}
            />
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default ActivityDetails;
