import { IonIcon, IonItemOption, IonItemOptions, useIonAlert } from '@ionic/react';
import {
  ActivityContextType,
  GeneralContextType,
  MyLibraryContextType,
  useActivity,
  useGeneral,
  useMyLibrary,
} from '@libs/apps-shared/contexts';
import { ActivityItem, UserbaseError } from '@libs/apps-shared/custom-types';
import { trashOutline } from 'ionicons/icons';

interface Props {
  itemId: string;
  closeSlider: () => void;
}

const MyLibrarySwipeableOptionsRight = ({ itemId, closeSlider }: Props) => {
  const [presentConfirmModal] = useIonAlert();
  const { setLoading }: GeneralContextType = useGeneral();
  const { deleteActivityItems, activity }: ActivityContextType = useActivity();
  const { deleteBookInLibrary }: MyLibraryContextType = useMyLibrary();

  /**
   * Deletes the book from the user's library.
   * @param {string} itemId The item id of the book in the library.
   */
  const removeItemFromMyLibrary = async (): Promise<void> => {
    presentConfirmModal({
      header: 'Confirm Delete Book',
      message:
        'Are you sure you want to delete this book and its lending history from your ' +
        'library? This action cannot be undone.',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            closeSlider();
          },
        },
        {
          text: 'Ok',
          handler: async () => {
            closeSlider();
            setLoading(true);
            try {
              await deleteBookInLibrary(itemId);
              const activityItemsToDelete: string[] = activity
                .filter(({ item }: ActivityItem) => item.book.itemId === itemId)
                .map(({ itemId }: ActivityItem) => itemId);
              if (activityItemsToDelete.length > 0) {
                try {
                  await deleteActivityItems(activityItemsToDelete);
                } catch (error) {
                  const err: UserbaseError = error as UserbaseError;
                  alert(err.message);
                }
              }
            } catch (error) {
              const err: UserbaseError = error as UserbaseError;
              alert(err.message);
            }
            setLoading(false);
          },
        },
      ],
    });
  };

  return (
    <IonItemOptions side="end">
      <IonItemOption color="danger" onClick={removeItemFromMyLibrary}>
        <IonIcon slot="bottom" icon={trashOutline} className="mt-1" />
        Delete
      </IonItemOption>
    </IonItemOptions>
  );
};

export default MyLibrarySwipeableOptionsRight;
