import { menuController } from '@ionic/core/components';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { AuthContextType, useAuth } from '@libs/apps-shared/contexts';
import { closeOutline, cog, library, search, swapHorizontal } from 'ionicons/icons';
import { useRef } from 'react';
import { useHistory } from 'react-router';
import Routes from './Routes';

const Menu = () => {
  const history = useHistory();

  const menuRef = useRef<HTMLIonMenuElement>(null);

  const navigate = (path: string): void => {
    history.push(path);
    menuController.close();
  };

  return (
    <IonMenu
      contentId="main"
      className="border-gray-200 border-r-[1px] pointer-events-auto"
      ref={menuRef}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <div className="flex items-center justify-between">
              <div className="flex flex-row items-center space-x-2">
                <IonImg src="../../assets/logo.png" class="inline-block h-5 w-5" />
                <span>BookshelfTracker</span>
              </div>
            </div>
          </IonTitle>
          <IonButtons slot="end">
            <IonMenuToggle>
              <IonButton>
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonMenuToggle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem button lines="full" onClick={() => navigate('/app/library')}>
            <IonIcon icon={library} slot="start" />
            <IonLabel>Library</IonLabel>
          </IonItem>
          <IonItem button lines="full" onClick={() => navigate('/app/search')}>
            <IonIcon icon={search} slot="start" />
            <IonLabel>Search</IonLabel>
          </IonItem>
          <IonItem button lines="full" onClick={() => navigate('/app/lending')}>
            <IonIcon icon={swapHorizontal} slot="start" />
            <IonLabel>Lending</IonLabel>
          </IonItem>
          <IonItem button lines="full" onClick={() => navigate('/app/settings')}>
            <IonIcon icon={cog} slot="start" />
            <IonLabel>Settings</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

const SplitPane = () => {
  const { currentUser }: AuthContextType = useAuth();
  return (
    <>
      <IonSplitPane contentId="main">
        <Menu />
        <main className="ion-page" id="main">
          <IonMenuToggle slot="end" menu="sideMenu" autoHide={false} />
          <IonRouterOutlet>
            <Routes currentUser={currentUser} />
          </IonRouterOutlet>
        </main>
      </IonSplitPane>
    </>
  );
};

export default SplitPane;
