import { ShieldCheckIcon } from '@heroicons/react/24/outline';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSlide,
  IonSlides,
  IonToolbar,
} from '@ionic/react';

const Onboarding = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/app/settings" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSlides pager className="absolute inset-0">
          <IonSlide>
            <div className="flex-col max-w-md mx-auto p-8">
              <h1 className="font-bold text-center">
                <span className="text-primary">Store</span> books you own
              </h1>
              <p className="text-center mb-4">
                Scan your book's barcode or manually enter its ISBN code to add it to your library.
              </p>
              <IonImg src="../../assets/Library.png" className="w-full h-auto" />
            </div>
          </IonSlide>
          <IonSlide>
            <div className="flex-col max-w-md mx-auto p-8">
              <h1 className="font-bold text-center">
                <span className="text-secondary">Track</span> books you lend
              </h1>
              <p className="text-center mb-4">
                Keeping track of who you've lent your books to couldn't be easier.
              </p>
              <IonImg src="../../assets/Lending.png" className="w-full h-auto" />
            </div>
          </IonSlide>
          <IonSlide>
            <div className="flex-col max-w-md mx-auto p-8">
              <h1 className="font-bold text-center">
                <span className="text-tertiary">Secured</span> with End-to-End Encryption
              </h1>
              <p className="text-center mb-4">
                All of the books you own, your book's lending activity, and more, is end-to-end
                encrypted (E2EE).
              </p>
              <ShieldCheckIcon className="dark:text-white" />
            </div>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default Onboarding;
