import { PurchaserInfo, PurchasesEntitlementInfo } from '@awesome-cordova-plugins/purchases';
import { Subscription } from '@mylibrary/api-types';
import { UserProfile, UserResult } from 'userbase-js';
import {
  StripeEnvironment,
  StripeSubscription,
  SubscriptionDetailsMobile,
  SubscriptionDetailsWeb,
  SubscriptionInfo,
  SubscriptionInfoExtended,
  TrialDetails,
} from '../custom-types';

/**
 * Consolidates the web subscription information.
 * @param {StripeSubscription} stripeSubscription
 * @param {StripeEnvironment} stripeEnv
 * @param {boolean} isMobileApp
 * @returns {SubscriptionInfoExtended}
 */
export const getWebSubscriptionInfo = (
  stripeSubscription: StripeSubscription,
  stripeEnv: StripeEnvironment,
  isMobileApp: boolean
): SubscriptionInfoExtended => {
  const subscriptionDetails: SubscriptionDetailsWeb | undefined =
    !!stripeSubscription.subscriptionPlanId
      ? {
          subscriptionPlanId: stripeSubscription.subscriptionPlanId,
          frequency:
            stripeEnv[stripeSubscription.subscriptionPlanId as keyof typeof stripeEnv].FREQUENCY,
          price: stripeEnv[stripeSubscription.subscriptionPlanId as keyof typeof stripeEnv].PRICE,
        }
      : undefined;
  const cancelSubscriptionAt: UserResult['cancelSubscriptionAt'] =
    !!stripeSubscription.cancelSubscriptionAt
      ? new Date(stripeSubscription.cancelSubscriptionAt)
      : undefined;
  const setToCancel: boolean = !!cancelSubscriptionAt;
  const isActive: boolean = stripeSubscription.subscriptionStatus === 'active' && !setToCancel;
  const subscriptionStatuses: SubscriptionInfo['subscriptionStatuses'] =
    !!stripeSubscription.subscriptionStatus
      ? {
          isActive,
          setToCancel,
          cancelSubscriptionAt,
          isCancelled: stripeSubscription.subscriptionStatus === 'canceled',
          troubleWithPayment:
            stripeSubscription.subscriptionStatus === 'incomplete' ||
            stripeSubscription.subscriptionStatus === 'incomplete_expired' ||
            stripeSubscription.subscriptionStatus === 'past_due' ||
            stripeSubscription.subscriptionStatus === 'unpaid',
        }
      : undefined;
  return {
    platform: 'web',
    ...(!!subscriptionDetails && !!subscriptionStatuses
      ? {
          subscribedFromCurrentPlatform: !isMobileApp,
          disableAppUse: subscriptionStatuses.isCancelled,
          subscriptionDetails,
          subscriptionStatuses,
        }
      : {
          disableAppUse: false,
        }),
  };
};

/**
 * Reusable function to build the subscription info extended object given an entitlement.
 * @param {PurchasesEntitlementInfo} entitlement
 * @param {PurchaserInfo['managementURL']} managementURL
 * @param {boolean} isMobileApp
 * @param {boolean} isiOS
 * @param {boolean} isAndroid
 * @returns {SubscriptionInfoExtended}
 */
export const buildMobileSubscriptionInfoExtended = (
  entitlement: PurchasesEntitlementInfo,
  managementUrl: PurchaserInfo['managementURL'],
  isMobileApp: boolean,
  isiOS: boolean,
  isAndroid: boolean
): SubscriptionInfoExtended => {
  const platform: 'ios' | 'android' =
    entitlement.store.toLowerCase() === 'app_store' ? 'ios' : 'android';
  const subscriptionDetails: SubscriptionDetailsMobile = {
    subscriptionPlanId: entitlement.productIdentifier,
    frequency: entitlement.productIdentifier.includes('month') ? 'monthly' : 'annual',
  };
  const cancelSubscriptionAt: UserResult['cancelSubscriptionAt'] =
    !entitlement.willRenew && !!entitlement.expirationDate
      ? new Date(entitlement.expirationDate)
      : undefined;
  const now: Date = new Date();
  const isCancelled: boolean = cancelSubscriptionAt
    ? now.getTime() > cancelSubscriptionAt.getTime()
    : false;
  const setToCancel: boolean = isCancelled ? false : !!cancelSubscriptionAt;
  const isActive: boolean = entitlement.isActive && !setToCancel && !isCancelled;
  const subscriptionStatuses: SubscriptionInfo['subscriptionStatuses'] = {
    isActive,
    setToCancel,
    cancelSubscriptionAt,
    isCancelled,
    troubleWithPayment: !!entitlement.billingIssueDetectedAt && !isCancelled,
  };
  return {
    platform,
    subscribedFromCurrentPlatform:
      isMobileApp && ((platform === 'ios' && isiOS) || (platform === 'android' && isAndroid)),
    managementUrl: managementUrl || undefined,
    disableAppUse: isCancelled,
    subscriptionDetails,
    subscriptionStatuses,
  };
};

/**
 * Consolidates the web subscription information.
 * @param {PurchaserInfo} purchaserI
 * @param {boolean} isMobileApp
 * @param {boolean} isiOS
 * @param {boolean} isAndroid
 * @returns {SubscriptionInfoExtended}
 */
export const getMobileSubscriptionInfo = (
  purchaseI: PurchaserInfo,
  isMobileApp: boolean,
  isiOS: boolean,
  isAndroid: boolean
): SubscriptionInfoExtended => {
  const activeSubscriptions: string[] = Object.keys(purchaseI.entitlements.active);
  const inactiveSubscriptions: string[] = Object.keys(purchaseI.entitlements.all).filter(
    (subKey: string) => !activeSubscriptions.includes(subKey)
  );
  if (activeSubscriptions.length !== 0) {
    const entitlement: PurchasesEntitlementInfo =
      purchaseI.entitlements.active[activeSubscriptions[0]];
    return buildMobileSubscriptionInfoExtended(
      entitlement,
      purchaseI.managementURL,
      isMobileApp,
      isiOS,
      isAndroid
    );
  } else if (inactiveSubscriptions.length !== 0) {
    const latestActiveEntitlement: PurchasesEntitlementInfo = inactiveSubscriptions
      .map((subKey: string) => purchaseI.entitlements.all[subKey])
      .sort((a: PurchasesEntitlementInfo, b: PurchasesEntitlementInfo) => {
        if (!a.expirationDate && !b.expirationDate) {
          return 0;
        } else if (!a.expirationDate) {
          return 1;
        } else if (!b.expirationDate) {
          return -1;
        }
        const aExpirationDate: Date = new Date(a.expirationDate);
        const bExpirationDate: Date = new Date(b.expirationDate);
        return aExpirationDate.getTime() - bExpirationDate.getTime();
      })[0];
    return buildMobileSubscriptionInfoExtended(
      latestActiveEntitlement,
      purchaseI.managementURL,
      isMobileApp,
      isiOS,
      isAndroid
    );
  }
  return {
    platform: undefined,
    disableAppUse: false,
  };
};

/**
 * Consolidates the web subscription information when not on mobile.
 * @param {Subscription} subscription
 * @param {boolean} isMobileApp
 * @param {boolean} isiOS
 * @param {boolean} isAndroid
 * @returns {SubscriptionInfoExtended}
 */
export const getMobileSubscriptionInfoOnOffPlatform = (
  subscription: Subscription,
  isMobileApp: boolean,
  isiOS: boolean,
  isAndroid: boolean
): SubscriptionInfoExtended => {
  const platform: 'ios' | 'android' =
    subscription.store.toLowerCase() === 'app_store' ? 'ios' : 'android';
  const subscriptionDetails: SubscriptionDetailsMobile = {
    subscriptionPlanId: subscription.product_id,
    frequency: subscription.product_id.includes('month') ? 'monthly' : 'annual',
  };
  const cancelSubscriptionAt: UserResult['cancelSubscriptionAt'] =
    !!subscription.unsubscribe_detected_at ? new Date(subscription.expires_date) : undefined;
  const now: Date = new Date();
  const expiresDate: Date = new Date(subscription.expires_date);
  const isCancelled: boolean = cancelSubscriptionAt
    ? now.getTime() > cancelSubscriptionAt.getTime()
    : false;
  const setToCancel: boolean = isCancelled ? false : !!cancelSubscriptionAt;
  const isActive: boolean = now.getTime() < expiresDate.getTime() && !setToCancel && !isCancelled;
  const subscriptionStatuses: SubscriptionInfo['subscriptionStatuses'] = {
    isActive,
    setToCancel,
    cancelSubscriptionAt,
    isCancelled,
    troubleWithPayment: !!subscription.billing_issues_detected_at,
  };
  return {
    platform,
    subscribedFromCurrentPlatform:
      isMobileApp && ((platform === 'ios' && isiOS) || (platform === 'android' && isAndroid)),
    managementUrl: 'https://tofix.bookshelftracker.com' || undefined,
    disableAppUse: isCancelled,
    subscriptionDetails,
    subscriptionStatuses,
  };
};

/**
 *
 * @param {StripeSubscription} stripeSubscription
 * @param {UserProfile | undefined} protectedProfile
 * @param {PurchaserInfo | undefined} purchaserInfo
 * @param {StripeEnvironment} stripeEnv
 * @param {boolean} isMobileApp
 * @param {boolean} isiOS
 * @param {boolean} isAndroid
 * @returns {SubscriptionInfo}
 */
export const getSubscriptionInfo = (
  stripeSubscription: StripeSubscription,
  protectedProfile: UserProfile | undefined,
  purchaserInfo: PurchaserInfo | undefined,
  stripeEnv: StripeEnvironment,
  isMobileApp: boolean,
  isiOS: boolean,
  isAndroid: boolean
): SubscriptionInfo => {
  let timeLeft: TrialDetails['timeLeft'] = null;
  let timeType: TrialDetails['timeType'] = null;
  let timeColor: TrialDetails['timeColor'] = null;
  if (!!stripeSubscription.trialExpirationDate) {
    const trialDate: Date = new Date(stripeSubscription.trialExpirationDate);
    const now: Date = new Date();
    const diffInDays: number = (trialDate.getTime() - now.getTime()) / (1000 * 3600 * 24);
    if (diffInDays > 1) {
      timeLeft = Math.ceil(diffInDays);
      timeType = diffInDays === 1 ? 'day' : 'days';
      timeColor = diffInDays > 7 ? 'primary' : 'warning';
    } else {
      const diffInHours: number = (trialDate.getTime() - now.getTime()) / (1000 * 3600);
      timeLeft = diffInHours <= 0 ? 0 : diffInHours <= 1 ? 1 : Math.ceil(diffInHours);
      timeType = diffInHours <= 1 ? 'hour' : 'hours';
      timeColor = 'danger';
    }
  }
  const isInTrial: boolean =
    !!stripeSubscription.trialExpirationDate && timeLeft !== null && timeLeft > 0;
  const trialDetails: SubscriptionInfo['trialDetails'] = {
    isInTrial,
    timeLeft,
    timeType,
    timeColor,
  };
  const webSubscriptionInfo: SubscriptionInfoExtended = getWebSubscriptionInfo(
    stripeSubscription,
    stripeEnv,
    isMobileApp
  );
  const mobileSub = purchaserInfo
    ? purchaserInfo
    : protectedProfile?.subscription
    ? JSON.parse(protectedProfile.subscription)
    : undefined;
  const mobileSubscriptionInfo: SubscriptionInfoExtended | undefined = purchaserInfo
    ? getMobileSubscriptionInfo(mobileSub, isMobileApp, isiOS, isAndroid)
    : protectedProfile?.subscription
    ? getMobileSubscriptionInfoOnOffPlatform(
        JSON.parse(protectedProfile.subscription),
        isMobileApp,
        isiOS,
        isAndroid
      )
    : undefined;
  let subInfo: SubscriptionInfoExtended;
  if (!mobileSubscriptionInfo) {
    subInfo = webSubscriptionInfo;
    // alert('web 1: ' + JSON.stringify(subInfo));
  } else if (
    webSubscriptionInfo.subscriptionStatuses?.isActive ||
    webSubscriptionInfo.subscriptionStatuses?.setToCancel
  ) {
    subInfo = webSubscriptionInfo;
    // alert('web 2: ' + JSON.stringify(subInfo));
  } else if (
    mobileSubscriptionInfo.subscriptionStatuses?.isActive ||
    mobileSubscriptionInfo.subscriptionStatuses?.setToCancel
  ) {
    subInfo = mobileSubscriptionInfo;
    // alert('mobile 3: ' + JSON.stringify(subInfo));
  } else {
    if (
      webSubscriptionInfo.subscriptionStatuses?.cancelSubscriptionAt &&
      mobileSubscriptionInfo.subscriptionStatuses?.cancelSubscriptionAt
    ) {
      const webCancelSubscriptionAt: Date = new Date(
        webSubscriptionInfo.subscriptionStatuses.cancelSubscriptionAt
      );
      const mobileCancelSubscriptionAt: Date = new Date(
        mobileSubscriptionInfo.subscriptionStatuses.cancelSubscriptionAt
      );
      if (webCancelSubscriptionAt.getDate() > mobileCancelSubscriptionAt.getDate()) {
        subInfo = webSubscriptionInfo;
        // alert('web 4: ' + JSON.stringify(subInfo));
      } else {
        subInfo = mobileSubscriptionInfo;
        // alert('mobile 4: ' + JSON.stringify(subInfo));
      }
    } else if (webSubscriptionInfo.subscriptionStatuses?.isCancelled) {
      subInfo = webSubscriptionInfo;
      // alert('web 5: ' + JSON.stringify(subInfo));
    } else if (mobileSubscriptionInfo.subscriptionStatuses?.isCancelled) {
      subInfo = mobileSubscriptionInfo;
      // alert('mobile 5: ' + JSON.stringify(subInfo));
    } else {
      subInfo = isMobileApp ? mobileSubscriptionInfo : webSubscriptionInfo;
      // alert((isMobileApp ? 'mobile 6: ' : 'web 6: ') + JSON.stringify(subInfo));
    }
  }
  let disableAppUse: boolean = false;
  if (!!subInfo) {
    if (!!subInfo.subscriptionDetails && !!subInfo.subscriptionStatuses) {
      // First check is if the user's subscription is canceled.
      if (subInfo.subscriptionStatuses.isCancelled) {
        // Even though their subscription is canceled, make sure they don't have any free trial left.
        if (!trialDetails.isInTrial) {
          disableAppUse = true;
        }
      } else if (
        !subInfo.subscriptionStatuses.isActive &&
        !subInfo.subscriptionStatuses.setToCancel
      ) {
        disableAppUse = true;
      }
      // If the user has never bought a subscription, make sure they don't have any free trial left.
    } else if (!trialDetails.isInTrial) {
      disableAppUse = true;
    }
  }
  return {
    trialDetails,
    ...subInfo,
    disableAppUse: disableAppUse,
  };
};
