import { yupResolver } from '@hookform/resolvers/yup';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import {
  AuthContextType,
  GeneralContextType,
  useAuth,
  useGeneral,
} from '@libs/apps-shared/contexts';
import { UserbaseError } from '@libs/apps-shared/custom-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../components/core/Button';
import Link from '../../components/core/Link';
import Input from '../../components/forms/Input';
import LogoHeader from '../../components/layout/LogoHeader';

type FormValues = {
  username: string;
};

const formSchema = yup
  .object()
  .shape({
    username: yup.string().required('This field is required.'),
  })
  .required();

const ForgotPassword = () => {
  const { setLoading }: GeneralContextType = useGeneral();
  const { lastUsedUsername, forgotPassword }: AuthContextType = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    shouldFocusError: false,
    defaultValues: {
      username: lastUsedUsername || '',
    },
  });
  const [forgotPasswordError, setForgotPasswordError] = useState<string>();
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState<boolean>(false);

  /**
   * On submit, sign the user in.
   * @param {FormValues} formValues
   */
  const onSubmit = async (formValues: FormValues): Promise<void> => {
    setLoading(true);
    const response: undefined | UserbaseError = await forgotPassword(formValues);
    if (!!response) {
      setForgotPasswordError(response.message);
      setForgotPasswordSuccess(false);
    } else {
      setForgotPasswordError(undefined);
      setForgotPasswordSuccess(true);
    }
    setLoading(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="pl-2">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/sign-in" text="Sign In" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="max-w-lg mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <LogoHeader />
          <div className="sm:rounded-md sm:shadow sm:border mt-8 px-4 sm:p-8">
            <h2 className="mb-6 text-center text-2xl sm:text-3xl font-extrabold text-gray-900 dark:text-white">
              {forgotPasswordSuccess ? 'Success!' : 'Submit forgot password request'}
            </h2>
            {forgotPasswordSuccess ? (
              <p className="text-center mt-2">
                Please check your email for reset password instructions.
              </p>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Input
                  label="Username"
                  name="username"
                  placeholder="Username"
                  autocomplete="username"
                  enterkeyhint="next"
                  required={true}
                  error={errors.username?.message}
                  control={control}
                />
                {!!forgotPasswordError && (
                  <p className="text-danger text-center mt-2">{forgotPasswordError}</p>
                )}
                <Button
                  text="Submit Request"
                  expand="block"
                  className="mt-4"
                  onClick={handleSubmit(onSubmit)}
                />
              </form>
            )}
            <div className="flex justify-center items-center">
              <Link
                text="Back to Sign In"
                routerLink="/sign-in"
                routerDirection="back"
                className="mt-4"
              />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
