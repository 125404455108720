import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonPopover,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import {
  ActivityContextType,
  AuthContextType,
  BooksContextType,
  GeneralContextType,
  MyLibraryContextType,
  useActivity,
  useAuth,
  useBooks,
  useGeneral,
  useMyLibrary,
} from '@libs/apps-shared/contexts';
import { ActivityItem, MyLibraryBookItem, UserbaseError } from '@libs/apps-shared/custom-types';
import { Book } from '@mylibrary/api-types';
import classNames from 'classnames';
import { History } from 'history';
import { arrowForwardOutline, ellipsisHorizontal, trashOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActivityTab from '../../components/activity/ActivityTab';
import BookDetailsTab, { BookDetailsForm } from '../../components/books/BookDetailsTab';
import Button from '../../components/core/Button';
import NoResults from '../../components/search-filter-sort/NoResults';
import { useAvailableCopies } from '../../hooks/useAvailableCopies';

interface Props {
  history: History<{ activeTab: 'details' | 'lending' }>;
}
const BookDetails = ({ history }: Props) => {
  let { isbn } = useParams<{ isbn: string }>();
  const { currentUser }: AuthContextType = useAuth();
  const { cachedBooks, getBook }: BooksContextType = useBooks();
  const [book, setBook] = useState<Book>();
  const [getBookError, setGetBookError] = useState<Error>();
  const { setLoading }: GeneralContextType = useGeneral();
  const { deleteActivityItems, activity }: ActivityContextType = useActivity();
  const { myLibrary, addBookToLibrary, deleteBookInLibrary }: MyLibraryContextType = useMyLibrary();
  const [presentConfirmModal] = useIonAlert();
  const [optionsPopoverStatus, setOptionsPopoverStatus] = useState<{
    showPopover: boolean;
    event: any;
  }>({ showPopover: false, event: undefined });
  const [activeTab, setActiveTab] = useState<string>('details');
  const [bookItemFromLibrary, setBookItemFromLibrary] = useState<MyLibraryBookItem | null>(null);
  const [bookDetails, setBookDetails] = useState<BookDetailsForm>();
  const { availableCopies } = useAvailableCopies(bookItemFromLibrary?.itemId);

  /**
   * Hides the popover.
   */
  const hidePopover = () => setOptionsPopoverStatus({ showPopover: false, event: undefined });

  /**
   * Adds the book to the user's library.
   */
  const addBookToMyLibrary = async () => {
    if (bookDetails?.valid) {
      if (optionsPopoverStatus.showPopover) hidePopover();
      setLoading(true);
      try {
        await addBookToLibrary({
          isbn,
          title: book?.title,
          authors: book?.authors,
          numberOfCopies: bookDetails.values.numberOfCopies,
          pagesRead: bookDetails.values.pagesRead,
          notes: bookDetails.values.notes,
          customBookDetails: {
            pages: bookDetails.values.customTotalPages,
          },
        });
      } catch (error) {
        const err: UserbaseError = error as UserbaseError;
        alert(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  /**
   * Deletes the book from the user's library.
   * @param {string} itemId The item id of the book in the library.
   */
  const removeItemFromMyLibrary = async (itemId: string): Promise<void> => {
    presentConfirmModal({
      header: 'Confirm Delete Book',
      message:
        'Are you sure you want to delete this book and its lending history from your ' +
        'library? This action cannot be undone.',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            hidePopover();
          },
        },
        {
          text: 'Ok',
          handler: async () => {
            hidePopover();
            setLoading(true);
            try {
              await deleteBookInLibrary(itemId);
              const activityItemsToDelete: string[] = activity
                .filter(({ item }: ActivityItem) => item.book.itemId === itemId)
                .map(({ itemId }: ActivityItem) => itemId);
              if (activityItemsToDelete.length > 0) {
                try {
                  await deleteActivityItems(activityItemsToDelete);
                } catch (error) {
                  const err: UserbaseError = error as UserbaseError;
                  alert(err.message);
                }
              }
            } catch (error) {
              const err: UserbaseError = error as UserbaseError;
              alert(err.message);
            }
            history.goBack();
            setLoading(false);
          },
        },
      ],
    });
  };

  /**
   * Hides the popover and navigates the user to lend the book.
   */
  const lendBook = (): void => {
    if (!!bookItemFromLibrary) {
      hidePopover();
      history.push(`/app/library/lend-book/${bookItemFromLibrary.itemId}`);
    }
  };

  useEffect(() => {
    // Fetch the book from the API or cache.
    if (currentUser) {
      (async () => {
        try {
          await getBook(isbn, currentUser.authToken);
        } catch (error) {
          setGetBookError(error as Error);
        }
      })();
    }
  }, [isbn, currentUser]);

  useEffect(() => {
    // Set the book details.
    if (!book) {
      const foundBook: Book | undefined = cachedBooks[isbn];
      if (!!foundBook) {
        setBook(foundBook);
      }
    }
  }, [cachedBooks, isbn, book]);

  useEffect(() => {
    // Get the book from the user's library if it exists.
    if (myLibrary) {
      const bookItem: MyLibraryBookItem | undefined = myLibrary.find(
        (myLibraryBook: MyLibraryBookItem) => myLibraryBook.item.isbn === isbn
      );
      if (bookItem) {
        setBookItemFromLibrary(bookItem);
      }
    }
  }, [myLibrary, isbn]);

  useEffect(() => {
    if (history.location.state?.activeTab) setActiveTab(history.location.state.activeTab);
  }, [history]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="px-2">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/app/library" />
          </IonButtons>
          <IonTitle>{book?.title}</IonTitle>
          {!getBookError && !!book && (
            <IonButtons slot="end">
              <IonButton
                onClick={(event: React.MouseEvent<HTMLIonButtonElement>) =>
                  setOptionsPopoverStatus({
                    showPopover: !optionsPopoverStatus.showPopover,
                    event: event.nativeEvent,
                  })
                }
              >
                <IonIcon icon={ellipsisHorizontal} className="text-3xl text-primary" />
              </IonButton>
              <IonPopover
                event={optionsPopoverStatus.event}
                isOpen={optionsPopoverStatus.showPopover}
                onDidDismiss={() =>
                  setOptionsPopoverStatus({ showPopover: false, event: undefined })
                }
              >
                <IonList>
                  <IonItem
                    className="flex justify-between text-primary"
                    button
                    detail={false}
                    onClick={!!bookItemFromLibrary ? lendBook : addBookToMyLibrary}
                    lines={!!bookItemFromLibrary ? undefined : 'none'}
                    disabled={!!bookItemFromLibrary && availableCopies === 0}
                  >
                    {!!bookItemFromLibrary ? 'Lend Book' : 'Add to Library'}
                    <span className="flex-1" />
                    <IonIcon className="ml-4" icon={arrowForwardOutline} />
                  </IonItem>
                  {bookItemFromLibrary && (
                    <IonItem
                      className="flex justify-between text-danger"
                      button
                      detail={false}
                      onClick={() => removeItemFromMyLibrary(bookItemFromLibrary.itemId)}
                      lines="none"
                    >
                      Remove <span className="flex-1" />
                      <IonIcon className="ml-4" icon={trashOutline} />
                    </IonItem>
                  )}
                </IonList>
              </IonPopover>
            </IonButtons>
          )}
        </IonToolbar>
        {!getBookError && !!book && !!bookItemFromLibrary && (
          <IonToolbar>
            <IonSegment
              onIonChange={(event) => event.detail.value && setActiveTab(event.detail.value)}
              value={activeTab}
            >
              <IonSegmentButton value="details">
                <IonLabel>Details</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="lending">
                <IonLabel>Lending</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent>
        {!getBookError ? (
          <>
            <BookDetailsTab
              className={classNames('p-8', { hidden: activeTab !== 'details' })}
              isbn={isbn}
              bookItemFromLibrary={bookItemFromLibrary}
              book={book}
              bookLoading={!book}
              bookDetailsChanged={setBookDetails}
            />
            <ActivityTab
              className={classNames({ hidden: activeTab !== 'lending' })}
              itemId={bookItemFromLibrary?.itemId}
              isbn={isbn}
              book={book}
            />
          </>
        ) : (
          <NoResults message={getBookError.message} />
        )}
      </IonContent>
      {!getBookError && !!book && (
        <IonFooter>
          <IonToolbar className="px-2">
            <Button
              text={!!bookItemFromLibrary ? 'Lend Book' : 'Add to Library'}
              expand="block"
              onClick={!!bookItemFromLibrary ? lendBook : addBookToMyLibrary}
              disabled={!!bookItemFromLibrary && availableCopies === 0}
            />
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default BookDetails;
