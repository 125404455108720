import { CheckboxChangeEventDetail, Components } from '@ionic/core';
import { IonCheckbox, IonLabel } from '@ionic/react';
import classNames from 'classnames';
import { Control, Controller } from 'react-hook-form';

type Props = {
  label?: string;
  name: Components.IonCheckbox['name'];
  control: Control<any>;
  error?: string;
  className?: string;
  color?: Components.IonCheckbox['color'];
  disabled?: boolean;
};

const Checkbox = ({
  label,
  name,
  control,
  error,
  className,
  color = 'primary',
  disabled = false,
}: Props) => {
  return (
    <div className={classNames('pb-4 relative', className)}>
      <div className="flex items-center">
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <IonCheckbox
                class="mr-2"
                name={name}
                onIonChange={(event: CustomEvent<CheckboxChangeEventDetail>) =>
                  onChange(event.detail.checked)
                }
                onBlur={() => {
                  onBlur();
                }}
                checked={value}
                color={color}
                disabled={disabled}
              />
              {!!label && (
                <div onClick={() => onChange(!value)} className="cursor-pointer select-none">
                  <IonLabel position="fixed" color={!!error ? 'danger' : undefined}>
                    {label}
                  </IonLabel>
                </div>
              )}
            </>
          )}
        />
      </div>
      <p
        className={classNames(
          'opacity-0 text-danger text-sm transform duration-150 ease-in-out absolute',
          { 'opacity-100': !!error }
        )}
      >
        {error}
      </p>
    </div>
  );
};

export default Checkbox;
