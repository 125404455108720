import { isPlatform, RadioGroupChangeEventDetail } from '@ionic/core';
import { IonItem, IonLabel, IonNote, IonRadio, IonRadioGroup } from '@ionic/react';
import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

export type RadioGroupOption = {
  value: string;
  label: string | ReactElement;
  note?: string;
};

type Props = {
  options: RadioGroupOption[];
  label: string;
  note?: React.ReactNode;
  name: string;
  control: Control<any>;
  onIonChange?: (value: string) => void;
};

const RadioGroup = ({ options, label, note, name, control, onIonChange }: Props) => {
  const [isMobileApp] = useState<boolean>(!isPlatform('desktop') && !isPlatform('mobileweb'));

  return (
    <div>
      <div className="mb-1">
        <IonLabel>{label} *</IonLabel>
        {note && (
          <>
            <br />
            <IonNote>{note}</IonNote>
          </>
        )}
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <IonRadioGroup
            name={name}
            value={value}
            onIonChange={(event: CustomEvent<RadioGroupChangeEventDetail>) => {
              onChange(event.detail.value);
              if (onIonChange) {
                onIonChange(event.detail.value);
              }
            }}
          >
            {options.map((option: RadioGroupOption, index: number) => (
              <IonItem
                key={option.value}
                className={classNames('border-2', {
                  'rounded-t-lg': index === 0 && !isMobileApp,
                  'rounded-b-lg': index === options.length - 1 && !isMobileApp,
                  'border-t-0':
                    value !== option.value && index !== 0 && options[index - 1].value === value,
                  'border-b-0': index !== options.length - 1 && value !== option.value,
                  'border-primary': value === option.value,
                  'border-medium': value !== option.value,
                })}
                lines="none"
                color={value === option.value ? 'light' : undefined}
              >
                <IonRadio slot="start" value={option.value} />
                <IonLabel>{option.label}</IonLabel>
                {option.note && <IonNote slot="end">{option.note}</IonNote>}
              </IonItem>
            ))}
          </IonRadioGroup>
        )}
      />
    </div>
  );
};

export default RadioGroup;
