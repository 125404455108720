import { IonThumbnail } from '@ionic/react';
import classNames from 'classnames';
import { useState } from 'react';
import BookImg from './BookImg';
import BookImgSkeleton from './BookImgSkeleton';

interface Props {
  imageLink: string | undefined;
  loading: boolean;
}

const BookThumbnail = ({ imageLink, loading }: Props) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  return (
    <>
      {!loading ? (
        <IonThumbnail
          slot="start"
          className={classNames('py-2 sm:py-0', {
            'h-auto': imageLoaded,
            'h-[72px]': !imageLoaded,
          })}
        >
          <BookImg
            className="w-12"
            imageLink={imageLink}
            target="thumbnail"
            onLoad={() => setImageLoaded(true)}
          />
        </IonThumbnail>
      ) : (
        <BookImgSkeleton target="thumbnail" />
      )}
    </>
  );
};

export default BookThumbnail;
