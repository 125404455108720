import { Book } from '@mylibrary/api-types';
import { useHistory } from 'react-router';
import BookImg from '../../books/BookImg';

interface Props {
  book: Book;
}

const MyLibraryGridItem = ({ book }: Props) => {
  const history = useHistory();

  /**
   * Handles when a book has been clicked.
   */
  const onBookItemClick = () => {
    history.push(`/app/library/details/${book.isbn13 || book.isbn}`);
  };

  return (
    <div className="cursor-pointer" onClick={onBookItemClick}>
      <div className="w-full flex mb-2">
        <BookImg
          className="h-28 w-[75px] shadow-lg mx-auto"
          imageLink={book.image}
          target="thumbnail"
        />
      </div>
      <p className="truncate text-xs sm:text-base text-center">{book.title}</p>
    </div>
  );
};

export default MyLibraryGridItem;
