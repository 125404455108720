import { IonImg } from '@ionic/react';
import { GetBookCover } from '@libs/apps-shared/api';
import { AuthContextType, useAuth } from '@libs/apps-shared/contexts';
import * as Sentry from '@sentry/react';
import { Scope } from '@sentry/types';
import { useEffect, useState } from 'react';
import { BooksAPI } from '../../api/apiKit';
import BookImgSkeleton from './BookImgSkeleton';

interface Props {
  className?: string;
  imageLink: string | undefined;
  target: 'smallThumbnail' | 'thumbnail' | 'large';
  onLoad?: (event?: CustomEvent<void>) => void;
}

const BookImg = ({ className, imageLink, target = 'large', onLoad }: Props) => {
  const { currentUser }: AuthContextType = useAuth();
  const [base64, setBase64] = useState<string>();
  const [bookCoverLoading, setBookCoverLoading] = useState<boolean>(true);
  const [errorShowNotFound, setErrorShowNotFound] = useState<boolean>(false);
  const [notFoundImage] = useState<string>('../../assets/cover-not-found.jpg');

  useEffect(() => {
    setBookCoverLoading(true);
    if (!!currentUser && !!imageLink) {
      (async () => {
        try {
          const res: Blob = await GetBookCover(BooksAPI, imageLink, currentUser.authToken);
          const reader = new FileReader();
          reader.readAsDataURL(new Blob([res]));
          reader.onload = () => {
            if (typeof reader.result === 'string') {
              setBase64(reader.result);
              setBookCoverLoading(false);
            }
          };
          reader.onerror = () => {
            setBookCoverLoading(false);
          };
        } catch (error) {
          Sentry.captureException(error, (scope: Scope) => {
            scope.setTag('function', 'BookImg.usseEffect.GetBookCover');
            return scope;
          });
          console.error('Error getting book cover: ', error);
          setErrorShowNotFound(true);
          setBookCoverLoading(false);
        }
      })();
    } else {
      setBookCoverLoading(false);
    }
  }, [currentUser, imageLink, target]);

  return !bookCoverLoading ? (
    <IonImg
      src={!!imageLink && !errorShowNotFound ? (base64 ? base64 : notFoundImage) : notFoundImage}
      className={className}
      onIonError={() => {
        setErrorShowNotFound(true);
        if (onLoad) onLoad();
      }}
      onIonImgDidLoad={onLoad}
    />
  ) : (
    <BookImgSkeleton className={className} target={target} />
  );
};

export default BookImg;
