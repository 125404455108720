import { IonBadge } from '@ionic/react';
import { useMemo } from 'react';
import { useAvailableCopies } from '../../hooks/useAvailableCopies';

type AvailabilityColor = 'success' | 'primary' | 'warning' | 'danger';

interface Props {
  itemId: string;
}

const AvailableCopiesPill = ({ itemId }: Props) => {
  const { availableCopies, totalCopies } = useAvailableCopies(itemId);
  const availabilityColor: AvailabilityColor = useMemo<AvailabilityColor>(() => {
    if (availableCopies === totalCopies) return 'success';
    else if (availableCopies > totalCopies / 2) return 'primary';
    else if (availableCopies <= totalCopies / 2 && availableCopies !== 0) return 'warning';
    return 'danger';
  }, [availableCopies, totalCopies]);

  return (
    <IonBadge color={availabilityColor}>
      {availableCopies} of {totalCopies} Available
    </IonBadge>
  );
};

export default AvailableCopiesPill;
