import { Book } from '@mylibrary/api-types';
import MyLibraryGridItem from './MyLibraryGridItem';

interface Props {
  books: Book[];
}

const MyLibraryGrid = ({ books }: Props) => {
  return (
    <div className="grid grid-flow-row grid-cols-3 sm:grid-cols-5 gap-4">
      {books.map((book: Book) => (
        <MyLibraryGridItem key={`search-item-${book.isbn13 || book.isbn}`} book={book} />
      ))}
    </div>
  );
};

export default MyLibraryGrid;
