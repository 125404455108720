import { AuthContextType, useAuth } from '@libs/apps-shared/contexts';
import { CustomRouteProps } from '@libs/apps-shared/custom-types';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Route } from 'react-router-dom';

const SentryRoute = Sentry.withSentryRouting(Route);

/**
 * Component that handles public routes and redirects into the app if the user
 * is signed in.
 */
const PublicRoute = ({ ...rest }: CustomRouteProps) => {
  const { currentUser }: AuthContextType = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!!currentUser) {
      if (currentUser.changePassword || currentUser.usedTempPassword) {
        history.replace('/app/settings/change-password');
      } else {
        history.replace('/app/library');
      }
    }
  }, [currentUser]);

  return <SentryRoute {...rest} />;
};

export default PublicRoute;
