import { useState } from 'react';

type Props = {
  maxChars?: number;
  children: string;
};

const ReadMore = ({ maxChars = 100, children }: Props) => {
  const [clamped, setClamped] = useState<boolean>(true);
  return children.length < maxChars ? (
    <>{children}</>
  ) : (
    <>
      {clamped ? `${children.slice(0, maxChars)}...` : children}{' '}
      <span className="text-blue-500 cursor-pointer" onClick={() => setClamped(!clamped)}>
        {clamped ? 'Read More' : 'Read Less'}
      </span>
    </>
  );
};

export default ReadMore;
