// import { Contact, Contacts, IContactField } from '@ionic-native/contacts/ngx';
import { IonIcon } from '@ionic/react';
import { BorrowerInfoForm } from '@libs/apps-shared/custom-types';
import { idCard } from 'ionicons/icons';
import { useState } from 'react';
import Button from '../../core/Button';
import { SelectOption } from '../../forms/Select';
import BorrowerForm from './BorrowerForm';

interface Props {
  maxNumberOfCopies: number;
  borrowerInfoChanged: (borrowerInfo: BorrowerInfoForm) => void;
}

const ChooseContact = ({ maxNumberOfCopies, borrowerInfoChanged }: Props) => {
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [phoneNumberOptions, setPhoneNumberOptions] = useState<SelectOption[]>([]);
  const [email, setEmail] = useState<string>();
  const [emailOptions, setEmailOptions] = useState<SelectOption[]>([]);

  const chooseContact = async (): Promise<void> => {
    /**
    const contacts: Contacts = new Contacts();
    const contact: Contact = await contacts.pickContact();
    if (!!contact) {
      setFirstName(contact.name.givenName);
      setLastName(contact.name.familyName);
      if (contact.phoneNumbers && contact.phoneNumbers.length > 0) {
        setPhoneNumberOptions(
          contact.phoneNumbers
            .filter(({ value }: IContactField) => !!value)
            .map(({ value }: IContactField) => ({
              value: value!,
              label: value!,
            }))
            .concat([
              {
                value: '-',
                label: '-',
              },
            ])
        );
        setPhoneNumber(contact.phoneNumbers[0].value);
      } else {
        setPhoneNumberOptions([]);
      }
      if (contact.emails && contact.emails.length > 0) {
        setEmailOptions(
          contact.emails
            .filter(({ value }: IContactField) => !!value)
            .map(({ value }: IContactField) => ({
              value: value!,
              label: value!,
            }))
            .concat([
              {
                value: '-',
                label: '-',
              },
            ])
        );
        setEmail(contact.emails[0].value);
      } else {
        setEmailOptions([]);
      }
    } */
  };

  return (
    <div>
      <Button
        expand="block"
        text={
          <>
            <IonIcon icon={idCard} className="mr-2" />
            <span>Choose Contact</span>
          </>
        }
        className="mb-4"
        type="button"
        onClick={chooseContact}
      />
      <BorrowerForm
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        phoneNumberOptions={phoneNumberOptions}
        email={email}
        emailOptions={emailOptions}
        maxNumberOfCopies={maxNumberOfCopies}
        borrowerInfoChanged={borrowerInfoChanged}
        markAsStillBeingBorrowed={() => {}}
      />
    </div>
  );
};

export default ChooseContact;
