import { Redirect, Route } from 'react-router-dom';
import { UserResult } from 'userbase-js';
import ActivityDetails from '../../pages/activity/ActivityDetails';
import LendBook from '../../pages/activity/LendBook';
import BookDetails from '../../pages/books/BookDetails';
import ChangePassword from '../../pages/settings/ChangePassword';
import DangerZone from '../../pages/settings/DangerZone';
import ManageSubscription from '../../pages/settings/ManageSubscription';
import Onboarding from '../../pages/settings/Onboarding';
import Profile from '../../pages/settings/Profile';
import Activity from '../../pages/tabs/Activity';
import Library from '../../pages/tabs/Library';
import Search from '../../pages/tabs/Search';
import Settings from '../../pages/tabs/Settings';
import PrivateRoute from '../navigation/PrivateRoute';

interface RoutesProps {
  currentUser: UserResult | undefined;
}
const Routes = ({ currentUser }: RoutesProps) => {
  return (
    <>
      <PrivateRoute path="/app/library" component={Library} exact={true} />
      <PrivateRoute path="/app/search" component={Search} exact={true} />
      <PrivateRoute path="/app/lending" component={Activity} exact={true} />
      <PrivateRoute path="/app/settings" component={Settings} exact={true} />
      {currentUser?.changePassword ? (
        <PrivateRoute path="/app/settings/change-password" component={ChangePassword} exact />
      ) : (
        <>
          <PrivateRoute path="/app/library/details/:isbn" component={BookDetails} exact />
          <PrivateRoute path="/app/library/lend-book" component={LendBook} exact />
          <PrivateRoute path="/app/library/lend-book/:itemId" component={LendBook} exact />
          <PrivateRoute path="/app/lending/:itemId" component={ActivityDetails} exact />
          <PrivateRoute path="/app/settings/profile" component={Profile} exact />
          <PrivateRoute path="/app/settings/change-password" component={ChangePassword} exact />
          <PrivateRoute path="/app/settings/danger-zone" component={DangerZone} exact />
          <PrivateRoute path="/app/settings/onboarding" component={Onboarding} exact />
          <PrivateRoute
            path="/app/settings/manage-subscription"
            component={ManageSubscription}
            exact
          />
        </>
      )}
      <Route path="/app" render={() => <Redirect to="/app/library" />} exact={true} />
    </>
  );
};

export default Routes;
