import * as Sentry from '@sentry/react';
import { Scope } from '@sentry/types';
import { createContext, ReactNode, useContext, useState } from 'react';
import userbase from 'userbase-js';
import { Activity, ActivityItem, BorrowerTypes } from '../custom-types/activity';
import { UserbaseError } from '../custom-types/userbase';

export type ActivityContextType = {
  getActivity: () => void;
  addActivity: (borrowerType: BorrowerTypes, activity: Activity) => void;
  updateActivity: (itemId: string, activity: Activity) => void;
  deleteActivity: (itemId: string) => void;
  deleteActivityItems: (itemIds: string[]) => void;
  activity: ActivityItem[];
  isFetchingActivity: boolean;
};

export const ActivityContext = createContext<ActivityContextType>({
  getActivity: () => null,
  addActivity: () => null,
  updateActivity: () => null,
  deleteActivity: () => null,
  deleteActivityItems: () => null,
  activity: [],
  isFetchingActivity: true,
});

export const useActivity = () => useContext(ActivityContext);

interface Props {
  environment: {
    ACTIVITY_DATABASE_NAME: string;
  };
  children: ReactNode;
}

export const ActivityProvider = ({ environment, children }: Props) => {
  const DATABASE_NAME: string = environment.ACTIVITY_DATABASE_NAME;
  const [activity, setActivity] = useState<ActivityItem[]>([]);
  const [isFetchingActivity, setIsFetchingActivity] = useState<boolean>(true);

  /**
   * Opens the activity database and subscribes to the changes.
   * @returns {Promise<void | UserbaseError}
   */
  const getActivity: ActivityContextType['getActivity'] =
    async (): Promise<void | UserbaseError> => {
      setIsFetchingActivity(true);
      return userbase
        .openDatabase({
          databaseName: DATABASE_NAME,
          changeHandler: (items: ActivityItem[]) => {
            setActivity(items);
            setIsFetchingActivity(false);
          },
        })
        .catch((error) => {
          Sentry.captureException(error, (scope: Scope) => {
            scope.setTag('function', 'Activity.getActivity');
            return scope;
          });
          throw error;
        });
    };

  /**
   * Adds an activity to the user's database of activities.
   * @param {BorrowerTypes} borrowerType
   * @param {Activity} activity
   * @returns {Promise<void | UserbaseError}
   */
  const addActivity: ActivityContextType['addActivity'] = async (
    borrowerType: BorrowerTypes,
    activity: Activity
  ): Promise<void | UserbaseError> => {
    if (borrowerType === 'user') {
      return;
    }
    return userbase
      .insertItem({
        databaseName: DATABASE_NAME,
        item: activity,
      })
      .catch((error) => {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'Activity.addActivity');
          return scope;
        });
        throw error;
      });
  };

  /**
   * Updates an activity in the user's database of activities.
   * @param {string} itemId The id of the activity item.
   * @param {Activity} activity
   * @returns {Promise<void | UserbaseError}
   */
  const updateActivity: ActivityContextType['updateActivity'] = async (
    itemId: string,
    activity: Activity
  ): Promise<void | UserbaseError> => {
    return userbase
      .updateItem({
        databaseName: DATABASE_NAME,
        itemId,
        item: activity,
      })
      .catch((error) => {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'Activity.updateActivity');
          return scope;
        });
        throw error;
      });
  };

  /**
   * Delets an activity from the user's database of activities.
   * @param {string} itemId The id of the activity item.
   * @returns {Promise<void | UserbaseError>}
   */
  const deleteActivity: ActivityContextType['deleteActivity'] = async (
    itemId: string
  ): Promise<void | UserbaseError> => {
    return userbase
      .deleteItem({
        databaseName: DATABASE_NAME,
        itemId,
      })
      .catch((error) => {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'Activity.deleteActivity');
          return scope;
        });
        throw error;
      });
  };

  /**
   * Deletes a list of activity items.
   * @param {string[]} itemIds The list of activity item ids we want to delete.
   * @returns {Promise<void | UserbaseError>}
   */
  const deleteActivityItems: ActivityContextType['deleteActivityItems'] = async (
    itemIds: string[]
  ): Promise<void | UserbaseError> => {
    return userbase
      .putTransaction({
        databaseName: DATABASE_NAME,
        operations: itemIds.map((itemId: string) => ({ command: 'Delete', itemId: itemId })),
      })
      .catch((error) => {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'Activity.deleteActivityItems');
          return scope;
        });
        throw error;
      });
  };

  return (
    <ActivityContext.Provider
      value={{
        activity,
        isFetchingActivity,
        getActivity,
        addActivity,
        updateActivity,
        deleteActivity,
        deleteActivityItems,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};
