export interface GetCoverQuery {
  url: string;
}

export interface GetBooksQuery {
  isbns: string;
}

export type GetBookResponse = { book: Book };

export interface Books {
  [isbn: string]: Book;
}

export interface Book {
  description?: string;
  title: string;
  title_long: string;
  isbn: string;
  isbn13: string;
  dewey_decimal?: string;
  binding: string;
  publisher: string;
  language: string;
  date_published: string;
  edition: string;
  pages?: number;
  dimensions: string;
  overview?: string;
  image: string;
  msrp: number;
  excerpt?: string;
  synopsys?: string;
  synopsis?: string;
  subjects: string[];
  authors: string[];
}

export interface IndustryIdentifiers {
  identifier?: string;
  type?: string;
}

export interface GetBooksResponse {
  total: number;
  requested: number;
  data: Book[];
}

export interface GetBookError {
  errorType: string;
  errorMessage: string;
}

export enum SearchBooksBy {
  Author = '0',
  ISBN = '1',
  Title = '2',
}

export interface SearchBooksQuery {
  page: number;
  pageSize: number;
  searchTerm: string;
  searchType: SearchBooksBy;
}

export interface SearchBooksISBNDBQuery {
  page: number;
  pageSize: number;
  author?: string;
  isbn?: string;
  isbn13?: string;
  text?: string;
}

export interface SearchBooksResponse {
  total: number;
  data: Book[];
}

export type GetBookStatsResponse = BookStats;

export interface BookStats {
  books: number;
  authors: number;
  publishers: number;
}

export interface FeaturedBook {
  isbn: string;
}

export enum BestSellersListOptions {
  Fiction = 'combined-print-and-e-book-fiction',
  NonFiction = 'combined-print-and-e-book-nonfiction',
}

export interface GetBestSellersQuery {
  bestSellerType: BestSellersListOptions;
}

export type BestSellersResponse = string[];
