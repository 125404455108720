import { yupResolver } from '@hookform/resolvers/yup';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  AuthContextType,
  GeneralContextType,
  useAuth,
  useGeneral,
} from '@libs/apps-shared/contexts';
import { UserbaseError } from '@libs/apps-shared/custom-types';
import { History } from 'history';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../components/core/Button';
import Input from '../../components/forms/Input';
import UsePasswordManager from '../../components/UsePasswordManager';

type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const formSchema = yup
  .object()
  .shape({
    currentPassword: yup.string().required('This field is required.'),
    newPassword: yup.string().required('This field is required.'),
    confirmPassword: yup
      .string()
      .required('This field is required.')
      .oneOf([yup.ref('newPassword'), ''], `Passwords don't match.`),
  })
  .required();

interface Props {
  history: History;
}

const ChangePassword = ({ history }: Props) => {
  const { setLoading }: GeneralContextType = useGeneral();
  const { currentUser, changePassword }: AuthContextType = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    shouldFocusError: false,
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    mode: 'all',
  });
  const [changePasswordError, setChangePasswordError] = useState<string>();

  /**
   * On submit, change the user's password.
   * @param {FormValues} formValues
   */
  const onSubmit = async ({ confirmPassword, ...changePasswordDto }: FormValues): Promise<void> => {
    setLoading(true);
    const response: undefined | UserbaseError = await changePassword(changePasswordDto);
    if (!!response) {
      setChangePasswordError(response.message);
    } else {
      setChangePasswordError(undefined);
    }
    setLoading(false);
    history.goBack();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {currentUser && !currentUser.changePassword && (
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/settings" />
            </IonButtons>
          )}
          <IonTitle>Change Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="p-8">
          {currentUser && (currentUser.changePassword || currentUser.usedTempPassword) && (
            <IonItem
              color={
                currentUser.changePassword
                  ? 'danger'
                  : currentUser.usedTempPassword
                  ? 'warning'
                  : 'primary'
              }
              className="mb-4"
            >
              <p className="text-lg">
                {currentUser.changePassword &&
                  `You must change your password before continuing to use the app.`}
                {currentUser.usedTempPassword &&
                  `We suggest updating your password before continuing to use the app in case you forget it again.`}
              </p>
            </IonItem>
          )}
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Input
              label="Current Password"
              name="currentPassword"
              placeholder="Current Password"
              type="password"
              togglePasswordVisibility={true}
              autocomplete="current-password"
              enterkeyhint="next"
              required={true}
              error={errors.currentPassword?.message}
              control={control}
            />
            <Input
              label="New Password"
              name="newPassword"
              placeholder="New Password"
              type="password"
              togglePasswordVisibility={true}
              autocomplete="new-password"
              enterkeyhint="next"
              required={true}
              error={errors.newPassword?.message}
              control={control}
            />
            <Input
              label="Confirm Password"
              name="confirmPassword"
              placeholder="Confirm Password"
              type="password"
              togglePasswordVisibility={true}
              autocomplete="new-password"
              enterkeyhint="next"
              required={true}
              error={errors.confirmPassword?.message}
              control={control}
            />
          </form>
          <UsePasswordManager />
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar className="px-2">
          {!!changePasswordError && (
            <p className="text-danger text-center mb-2">{changePasswordError}</p>
          )}
          <Button text="Save" color="success" expand="block" onClick={handleSubmit(onSubmit)} />
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default ChangePassword;
