import { Components, TextareaChangeEventDetail } from '@ionic/core';
import { IonLabel, IonTextarea } from '@ionic/react';
import classNames from 'classnames';
import { KeyboardEvent, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = {
  label?: string;
  name: Components.IonTextarea['name'];
  placeholder: Components.IonTextarea['placeholder'];
  control: Control<any>;
  rows?: number;
  enterkeyhint?: Components.IonTextarea['enterkeyhint'];
  clearOnEdit?: Components.IonTextarea['clearOnEdit'];
  required?: boolean;
  error?: string;
  onKeyboardReturn?: () => void;
  onIonChange?: (value: string) => void;
  onIonBlur?: (value: string) => void;
};

const Textarea = ({
  label,
  name,
  placeholder,
  control,
  rows = 5,
  enterkeyhint,
  clearOnEdit,
  required = false,
  error,
  onKeyboardReturn,
  onIonChange,
  onIonBlur,
}: Props) => {
  const [focused, setFocused] = useState<boolean>(false);

  return (
    <div className="pb-4 relative">
      {!!label && (
        <div className="mb-1">
          <IonLabel position="fixed" color={!!error ? 'danger' : undefined}>
            {label}
            {required ? ' *' : ''}
          </IonLabel>
        </div>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <div
            className={classNames(
              'transform duration-150 ease-in-out rounded border-2 flex items-center pl-2',
              {
                'border-medium': !focused && !error,
                'border-black dark:border-white': focused && !error,
                'border-danger': !!error,
              }
            )}
          >
            <IonTextarea
              class="pr-2"
              name={name}
              placeholder={placeholder || undefined}
              enterkeyhint={enterkeyhint}
              clearOnEdit={clearOnEdit}
              required={required}
              rows={rows}
              onKeyUp={(event: KeyboardEvent<HTMLIonTextareaElement>) => {
                if (event.key === 'Enter' && !!onKeyboardReturn) {
                  onKeyboardReturn();
                }
              }}
              onIonChange={(event: CustomEvent<TextareaChangeEventDetail>) => {
                if (event.detail.value !== value) {
                  onChange(event.detail.value);
                  if (onIonChange) onIonChange(event.detail.value || '');
                }
              }}
              onFocus={() => setFocused(true)}
              onBlur={() => {
                if (!!onIonBlur) {
                  onIonBlur(value);
                }
                setFocused(false);
                onBlur();
              }}
              value={value}
            />
          </div>
        )}
      />
      <p
        className={classNames(
          'opacity-0 text-danger text-sm transform duration-150 ease-in-out absolute',
          { 'opacity-100': !!error }
        )}
      >
        {error}
      </p>
    </div>
  );
};

export default Textarea;
