import { IonInfiniteScroll, IonInfiniteScrollContent, IonList, isPlatform } from '@ionic/react';
import {
  AuthContextType,
  BooksContextType,
  MyLibraryContextType,
  ScanningContextType,
  useAuth,
  useBooks,
  useMyLibrary,
  useScanning,
} from '@libs/apps-shared/contexts';
import { MyLibraryBookItem } from '@libs/apps-shared/custom-types';
import * as Sentry from '@sentry/react';
import { Scope } from '@sentry/types';
import { History } from 'history';
import { ScanningResult, SupportedFormats } from 'libs/apps-shared/src/lib/custom-types/scanning';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { compareChars } from '../../utils/arrays';
import { compareDates } from '../../utils/dates';
import SearchBookByISBN from '../books/SearchBookByISBN';
import SelectableMyLibraryListItem from '../lists/library/SelectableMyLibraryListItem';
import SkeletonList from '../lists/SkeletonList';
import NoResults from '../search-filter-sort/NoResults';
import { SortOptionKeys } from '../search-filter-sort/SortPopover';

interface Props {
  searchTerm: string;
  activeSort: SortOptionKeys;
  selectedBook: MyLibraryBookItem | null;
  setSelectedBook: (book: MyLibraryBookItem | null) => void;
}

const ChooseBook = ({ searchTerm, activeSort, selectedBook, setSelectedBook }: Props) => {
  const { currentUser }: AuthContextType = useAuth();
  const { myLibrary, isFetchingMyLibrary }: MyLibraryContextType = useMyLibrary();
  const { getBooks }: BooksContextType = useBooks();
  const { scan, prepare }: ScanningContextType = useScanning();
  const history: History = useHistory();
  const [filteredMyLibrary, setFilteredMyLibrary] = useState<MyLibraryBookItem[]>([]);
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [isMobileApp] = useState<boolean>(!isPlatform('desktop') && !isPlatform('mobileweb'));
  const [getBooksError, setGetBooksError] = useState<string | null>(null);
  const [showSearchBookByISBNModal, setShowSearchBookByISBNModal] = useState<boolean>(false);

  /**
   * Opens the scanner and handles the result.
   */
  const openScanner = async () => {
    try {
      const result: ScanningResult = await scan(SupportedFormats.Barcode);
      if (!!result) {
        history.push(`/app/library/details/${result}`);
      }
    } catch (error) {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'ChooseBook.openScanner');
        return scope;
      });
      alert(JSON.stringify(error));
    }
  };

  /**
   * Loads more books into view on scroll.
   * @param {CustomEvent<Void>} event
   */
  const loadMoreBooks = (event: CustomEvent<void>): void => {
    let numberToAdd: number = 10;
    if (itemsToShow + 10 > filteredMyLibrary.length) {
      numberToAdd = filteredMyLibrary.length - itemsToShow;
    }
    setItemsToShow(itemsToShow + numberToAdd);
    // Unfortunately this type is not correct, so have to ignore.
    //@ts-ignore
    event.target.complete();
  };

  useEffect(() => {
    let myLibraryToSearchAndFilter: MyLibraryBookItem[] = [];
    if (searchTerm) {
      myLibraryToSearchAndFilter = [
        ...myLibrary.filter(({ item }: MyLibraryBookItem) =>
          item.title?.toLowerCase().includes(searchTerm.toLowerCase())
        ),
      ];
    } else {
      myLibraryToSearchAndFilter = [...myLibrary];
    }
    if (activeSort === 'date-desc') {
      myLibraryToSearchAndFilter.sort((item1: MyLibraryBookItem, item2: MyLibraryBookItem) =>
        compareDates(item2.createdBy.timestamp, item1.createdBy.timestamp)
      );
    } else if (activeSort === 'date-asc') {
      myLibraryToSearchAndFilter.sort((item1: MyLibraryBookItem, item2: MyLibraryBookItem) =>
        compareDates(item1.createdBy.timestamp, item2.createdBy.timestamp)
      );
    } else if (activeSort === 'az') {
      myLibraryToSearchAndFilter.sort((item1: MyLibraryBookItem, item2: MyLibraryBookItem) =>
        compareChars(item1.item.title || '', item2.item.title || '', 0)
      );
    } else if (activeSort === 'za') {
      // TODO: allow sort by book title.
      myLibraryToSearchAndFilter.sort((item1: MyLibraryBookItem, item2: MyLibraryBookItem) =>
        compareChars(item2.item.title || '', item1.item.title || '', 0)
      );
    }
    setFilteredMyLibrary([...myLibraryToSearchAndFilter]);
  }, [searchTerm, activeSort, myLibrary]);

  useEffect(() => {
    (async () => {
      if (!!currentUser) {
        try {
          await getBooks(
            myLibrary.slice(0, itemsToShow).map((m: MyLibraryBookItem) => {
              return m.item.isbn;
            }),
            currentUser.authToken
          );
        } catch (error) {
          setGetBooksError((error as Error).message);
        }
      }
    })();
  }, [myLibrary, itemsToShow]);

  useEffect(() => {
    if (isMobileApp) {
      prepare().then();
    }
  }, []);

  return (
    <div>
      {!getBooksError ? (
        isFetchingMyLibrary ? (
          <SkeletonList type="library-selectable" />
        ) : filteredMyLibrary.length > 0 ? (
          <>
            <IonList>
              {filteredMyLibrary
                .slice(0, itemsToShow)
                .map(({ item, itemId, ...rest }: MyLibraryBookItem) => (
                  <SelectableMyLibraryListItem
                    key={`my-library-selectable-list-item-${itemId}`}
                    myLibraryListItemProps={{
                      itemId,
                      showAvailability: true,
                      isbn: item.isbn,
                      onClick: () => {
                        if (selectedBook?.itemId !== itemId) {
                          setSelectedBook({ item, itemId, ...rest });
                        }
                      },
                    }}
                    checked={selectedBook?.itemId === itemId}
                    onCheck={(checked: boolean) => {
                      if (selectedBook?.itemId === itemId) setSelectedBook(null);
                      else if (checked) setSelectedBook({ item, itemId, ...rest });
                    }}
                  />
                ))}
            </IonList>
            <IonInfiniteScroll
              onIonInfinite={loadMoreBooks}
              threshold="100px"
              disabled={itemsToShow >= filteredMyLibrary.length}
            >
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="Loading more data…"
              ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </>
        ) : (
          <NoResults
            message={
              isMobileApp
                ? "Scan a book's barcode to add it to your Library!"
                : `Search by a book's ISBN to add it to your Library!`
            }
            buttonProps={{
              text: isMobileApp ? 'Scan Barcode' : 'Search by ISBN',
              size: 'large',
              onClick: isMobileApp ? openScanner : () => setShowSearchBookByISBNModal(true),
            }}
          />
        )
      ) : (
        <NoResults message={`There was an error loading your library: ${getBooksError}`} />
      )}
      <SearchBookByISBN
        showModal={showSearchBookByISBNModal}
        onClose={() => setShowSearchBookByISBNModal(false)}
      />
    </div>
  );
};

export default ChooseBook;
