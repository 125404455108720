import { getPlatforms } from '@ionic/core';
import { IonActionSheet } from '@ionic/react';
import {
  MyLibraryContext,
  MyLibraryContextType,
  ScanningContextType,
  useScanning,
} from '@libs/apps-shared/contexts';
import { ScanningResult, SupportedFormats } from '@libs/apps-shared/custom-types';
import { arrowRedoOutline, barcodeOutline, searchOutline } from 'ionicons/icons';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import SearchBooKByISBN from './books/SearchBookByISBN';

interface Props {
  showActionSheet: boolean;
  onDidDismiss: () => void;
}

const ActionSheet = ({ showActionSheet, onDidDismiss }: Props) => {
  const { myLibrary }: MyLibraryContextType = useContext(MyLibraryContext);
  const { scan, prepare }: ScanningContextType = useScanning();
  const history = useHistory();
  const [isMobileApp, setIsMobileApp] = useState<boolean>();
  const [showSearchBookByISBNModal, setShowSearchBookByISBNModal] = useState<boolean>(false);

  const onClose = useCallback(() => setShowSearchBookByISBNModal(false), []);

  /**
   * Reusable function to navigate to the book details given an isbn.
   * @param {string} isbn
   */
  const navigateToBookDetails = (isbn: string) => history.push(`/app/library/details/${isbn}`);

  /**
   * Opens the scanner and handles the result.
   */
  const openScanner = async () => {
    try {
      const result: ScanningResult = await scan(SupportedFormats.Barcode);
      if (!!result) {
        navigateToBookDetails(result);
      }
    } catch (error) {
      alert(`There was an issue scanning the barcode: ${error}`);
    }
  };

  useEffect(() => {
    const platforms = getPlatforms();
    setIsMobileApp(!platforms.includes('desktop') && !platforms.includes('mobileweb'));
  }, []);

  useEffect(() => {
    if (isMobileApp && showActionSheet) {
      prepare().then();
    }
  }, [isMobileApp, showActionSheet]);

  return (
    <>
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={onDidDismiss}
        buttons={[
          ...(isMobileApp
            ? [
                {
                  text: 'Scan Barcode for ISBN',
                  icon: barcodeOutline,
                  handler: () => {
                    openScanner();
                  },
                },
              ]
            : []),
          {
            text: 'Search by ISBN',
            icon: searchOutline,
            handler: () => setShowSearchBookByISBNModal(true),
          },
          {
            text: 'Lend Book',
            icon: arrowRedoOutline,
            handler: () =>
              myLibrary.length > 0
                ? history.push('/app/library/lend-book')
                : alert('Add a book to your library to lend a book!'),
          },
          // {
          //   text: 'Mark Book Returned',
          //   icon: arrowUndoOutline,
          //   handler: () => {
          //     console.log('receive clicked');
          //   },
          // },
          {
            text: 'Cancel',
            role: 'cancel',
          },
        ]}
      />
      <SearchBooKByISBN showModal={showSearchBookByISBNModal} onClose={onClose} />
    </>
  );
};

export default ActionSheet;
