import { IonButton, IonIcon, IonItem, IonList, IonListHeader, IonPopover } from '@ionic/react';
import { useAuth } from '@libs/apps-shared/contexts';
import classNames from 'classnames';
import { checkmarkOutline, funnelOutline } from 'ionicons/icons';
import React, { useMemo, useState } from 'react';

export type SortOptionKeys = 'date-asc' | 'date-desc' | 'az' | 'za';

interface SortOption {
  key: SortOptionKeys;
  label: string;
}

const masterSortOptions: SortOption[] = [
  { key: 'date-desc', label: 'Newest' },
  { key: 'date-asc', label: 'Oldest' },
  { key: 'az', label: 'A - Z' },
  { key: 'za', label: 'Z - A' },
];

type Props = {
  sortOptions: SortOptionKeys[];
  activeSort: SortOptionKeys;
  setActiveSort: (sortOption: SortOptionKeys) => void;
};

const SortPopover = ({ sortOptions, activeSort, setActiveSort }: Props) => {
  const { subscriptionInfo } = useAuth();
  const [sortPopoverStatus, setSortPopoverStatus] = useState<{ showPopover: boolean; event: any }>({
    showPopover: false,
    event: undefined,
  });
  const availableSortOptions = useMemo<SortOption[]>(() => {
    return masterSortOptions.filter((sortOption: SortOption) =>
      sortOptions.includes(sortOption.key)
    );
  }, [sortOptions]);

  /**
   * Sets the current sortBy option and closes the sort popover.
   * @param {SortOptionKeys} selectSortOption
   */
  const sortBySelected = (selectSortOption: SortOptionKeys): void => {
    setActiveSort(selectSortOption);
    setSortPopoverStatus({ showPopover: false, event: undefined });
  };

  return (
    <>
      <IonButton
        onClick={(event: React.MouseEvent<HTMLIonButtonElement>) =>
          setSortPopoverStatus({
            showPopover: !sortPopoverStatus.showPopover,
            event: event.nativeEvent,
          })
        }
        disabled={subscriptionInfo?.disableAppUse}
      >
        <IonIcon icon={funnelOutline} />
      </IonButton>
      <IonPopover
        event={sortPopoverStatus.event}
        isOpen={sortPopoverStatus.showPopover}
        onDidDismiss={() => setSortPopoverStatus({ showPopover: false, event: undefined })}
      >
        <IonList>
          <IonListHeader>Sort Options</IonListHeader>
          {availableSortOptions.map((sortOption: SortOption, index: number) => {
            const selected: boolean = activeSort === sortOption.key;
            return (
              <IonItem
                className={classNames('flex justify-between', { 'text-primary': selected })}
                key={sortOption.key}
                button
                detail={false}
                onClick={() => sortBySelected(sortOption.key)}
                lines={index === availableSortOptions.length - 1 ? 'none' : undefined}
              >
                {sortOption.label}
                <span className="flex-1" />
                {selected && <IonIcon className="ml-4" icon={checkmarkOutline} />}
              </IonItem>
            );
          })}
        </IonList>
      </IonPopover>
    </>
  );
};

export default SortPopover;
