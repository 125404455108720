import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, isPlatform } from '@ionic/react';
import {
  AuthContextType,
  GeneralContextType,
  useAuth,
  useGeneral,
} from '@libs/apps-shared/contexts';
import { UpdateUserDto, UserbaseError } from '@libs/apps-shared/custom-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { environment } from '../environments/environment';
import Button from './core/Button';
import Checkbox from './forms/Checkbox';

const APPLE_STORE_URL: string = environment.production
  ? 'https://apps.apple.com/app/apple-store/id1604493302?pt=123958570&ct=web-app-install-app-modal&mt=8'
  : 'https://apps.apple.com/app/1604493302';
const GOOGLE_STORE_URL: string = environment.production
  ? 'https://play.google.com/store/apps/details?id=com.bookshelftracker.app&referrer=utm_source%3Dweb-app%26utm_medium%3Dinstall-app-modal'
  : 'https://play.google.com/store/apps/details?id=com.bookshelftracker.app';

const InstallAppModal = () => {
  const { setLoading }: GeneralContextType = useGeneral();
  const { currentUser, updateUser }: AuthContextType = useAuth();
  const isMobile: boolean = !isPlatform('desktop') && !isPlatform('mobileweb');
  const { control, watch } = useForm({
    shouldFocusError: false,
    defaultValues: {
      dontShowAgain: false,
    },
  });
  const [dontShowAgain, setDontShowAgain] = useState<boolean>(false);
  const [closeModal, setCloseModal] = useState<boolean>(false);

  const onSubmit = async () => {
    if (dontShowAgain && currentUser && currentUser.email) {
      setCloseModal(true);
      setLoading(true);

      const newUser: UpdateUserDto = Object.assign(
        {},
        {
          email: currentUser.email,
          username: currentUser.username,
          profile: {
            ...currentUser.profile,
            showInstallApp: 'false' as 'true' | 'false',
          },
        }
      );
      const response: undefined | UserbaseError = await updateUser(newUser);
      if (!!response) {
        console.error("Error updating the user's profile: ", response);
      }
      setLoading(false);
    }
    setCloseModal(true);
  };

  const onOpenUrl = (url: string) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    const sub = watch(({ dontShowAgain }: Partial<{ dontShowAgain: boolean }>) => {
      setDontShowAgain(!!dontShowAgain);
    });
    return () => sub.unsubscribe();
  }, [watch]);

  if (
    isMobile ||
    closeModal ||
    !currentUser ||
    (!!currentUser.profile?.showInstallApp && currentUser.profile?.showInstallApp === 'false')
  ) {
    return <></>;
  }
  return (
    <div className="fixed inset-0 top-28 flex flex-col justify-center items-center z-50">
      <div className="absolute inset-0 bg-black opacity-50" />
      <IonCard className="max-w-md">
        <IonCardHeader>
          <IonCardTitle color="primary">Install our mobile app!</IonCardTitle>
        </IonCardHeader>
        <IonCardContent color="primary">
          <p className="mb-4">
            Did you know we have a mobile app with all these same features and more? Install it for
            an even better experience!
          </p>
          <div className="flex flex-wrap items-center mb-4">
            <a href={APPLE_STORE_URL} target="_blank">
              <img
                src="../assets/apple-badge.png"
                width="154"
                height="75"
                className="object-scale-down mr-4"
              />
            </a>
            <a href={GOOGLE_STORE_URL} target="_blank">
              <img
                src="../assets/google-play-badge.png"
                placeholder="blur"
                width="170"
                height="75"
                className="object-scale-down"
              />
            </a>
          </div>
          <Checkbox
            className="mb-2"
            label="Don't show me again."
            name="dontShowAgain"
            control={control}
          />
          <div className="flex justify-end">
            <Button text="OKAY" color="primary" onClick={onSubmit} />
          </div>
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default InstallAppModal;
