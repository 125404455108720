import { CheckboxChangeEventDetail } from '@ionic/core';
import {
  IonCheckbox,
  IonItem,
  IonItemSliding,
  IonLabel,
  IonProgressBar,
  IonSkeletonText,
} from '@ionic/react';
import { useBooks } from '@libs/apps-shared/contexts';
import { Book } from '@mylibrary/api-types';
import { useEffect, useRef, useState } from 'react';
import AvailableCopiesPill from '../../../components/pills/AvailableBooksPill';
import { getReadableList } from '../../../utils/arrays';
import BookThumbnail from '../../books/BookThumbnail';
import MyLibrarySwipeableOptionsLeft from './MyLibrarySwipeableOptionsLeft';
import MyLibrarySwipeableOptionsRight from './MyLibrarySwipeableOptionsRight';

export type MyLibraryListItemProps = {
  isbn: string;
  pagesRead?: number;
  customTotalPages?: number;
  lines?: HTMLIonItemElement['lines'];
  onClick?: (isbn?: string) => void;
} & (
  | {
      checked?: never;
      disabled?: never;
      onCheck?: never;
    }
  | {
      checked: boolean;
      disabled: boolean;
      onCheck: (checked: boolean) => void;
    }
) &
  (
    | {
        showAvailability?: never;
        showSwipeableActions?: never;
        itemId?: never;
      }
    | {
        showAvailability?: never;
        showSwipeableActions?: never;
        itemId?: never;
      }
    | {
        showAvailability: boolean;
        showSwipeableActions?: never;
        itemId: string;
      }
    | {
        showAvailability?: never;
        showSwipeableActions: boolean;
        itemId: string;
      }
  );

const MyLibraryListItem = ({
  pagesRead,
  customTotalPages,
  showAvailability = false,
  showSwipeableActions = false,
  itemId,
  isbn,
  checked = false,
  disabled = false,
  lines = 'full',
  onCheck,
  onClick,
}: MyLibraryListItemProps) => {
  const { cachedBooks } = useBooks();
  const [book, setBook] = useState<Book>();
  const slidingItemRef = useRef<HTMLIonItemSlidingElement>(null);

  const closeSlider = () => slidingItemRef.current?.closeOpened();

  const handleClick = () => {
    if (onClick) {
      onClick(isbn);
    }
  };

  useEffect(() => {
    if (isbn && !book) {
      setBook(cachedBooks[isbn]);
    }
  }, [cachedBooks, isbn, book]);

  return (
    <IonItemSliding ref={slidingItemRef}>
      {showSwipeableActions && itemId && (
        <MyLibrarySwipeableOptionsLeft itemId={itemId} closeSlider={closeSlider} />
      )}
      <IonItem button={!!onClick} onClick={disabled ? undefined : handleClick} lines={lines}>
        <BookThumbnail imageLink={book?.image} loading={!book} />
        <IonLabel className="m-0">
          {!!book ? (
            <>
              <h2 className="text-xl m-0">{book.title}</h2>
              {book.authors && book.authors.length > 0 && (
                <p className="m-0 dark:text-white">By: {getReadableList(book.authors)}</p>
              )}
              {pagesRead !== undefined && (customTotalPages || book.pages) && (
                <div className="flex items-center">
                  <IonProgressBar
                    className="max-w-xs"
                    value={pagesRead / (customTotalPages || book.pages)!}
                  />
                  <span className="ml-2 text-sm">
                    {Math.round((pagesRead / (customTotalPages || book.pages)!) * 100)}%
                  </span>
                </div>
              )}
              {showAvailability && itemId && (
                <div className="mt-2">
                  <AvailableCopiesPill itemId={itemId} />
                </div>
              )}
            </>
          ) : (
            <>
              <IonSkeletonText animated className="w-2/5 sm:w-1/5" />
              <IonSkeletonText animated className="w-3/4 sm:w-1/3" />
            </>
          )}
        </IonLabel>
        {onCheck &&
          (!!book ? (
            <IonCheckbox
              checked={checked}
              disabled={disabled}
              onIonChange={(event: CustomEvent<CheckboxChangeEventDetail>) =>
                onCheck(event.detail.checked)
              }
            />
          ) : (
            <IonSkeletonText animated className="w-4 h-4" />
          ))}
      </IonItem>
      {showSwipeableActions && itemId && (
        <MyLibrarySwipeableOptionsRight itemId={itemId} closeSlider={closeSlider} />
      )}
    </IonItemSliding>
  );
};

export default MyLibraryListItem;
