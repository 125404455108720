import { IonToolbar } from '@ionic/react';
import { useAuth } from '@libs/apps-shared/contexts';
import { useHistory } from 'react-router';

const TrialToolbar = () => {
  const history = useHistory();
  const { subscriptionInfo } = useAuth();

  if (
    !!subscriptionInfo &&
    (!subscriptionInfo.subscriptionStatuses ||
      (!subscriptionInfo.subscriptionStatuses.isActive &&
        !subscriptionInfo.subscriptionStatuses.setToCancel))
  ) {
    return subscriptionInfo.trialDetails.isInTrial &&
      subscriptionInfo.trialDetails.timeColor !== null ? (
      <IonToolbar className="px-2" color={subscriptionInfo.trialDetails.timeColor}>
        <p id="free-trial-banner-txt" className="text-center font-medium">
          Free trial expires in {subscriptionInfo.trialDetails.timeLeft}{' '}
          {subscriptionInfo.trialDetails.timeType}
          {' - '}
          <span
            className="font-bold underline cursor-pointer"
            onClick={() => history.push('/app/settings/manage-subscription')}
          >
            manage subscription
          </span>
        </p>
      </IonToolbar>
    ) : (
      <></>
    );
  }
  return <></>;
};

export default TrialToolbar;
