import { Components } from '@ionic/core';
import { IonButton } from '@ionic/react';
import classNames from 'classnames';
import React, { ReactElement } from 'react';

export type ButtonProps = {
  text: ReactElement | string;
  color?: Components.IonButton['color'];
  disabled?: boolean;
  expand?: Components.IonButton['expand'];
  fill?: Components.IonButton['fill'];
  shape?: Components.IonButton['shape'];
  size?: Components.IonButton['size'];
  type?: Components.IonButton['type'];
  className?: string;
  onClick?: React.MouseEventHandler<HTMLIonButtonElement>;
  id?: string;
} & (
  | {
      href?: never;
      routerDirection?: never;
      target?: never;
    }
  | {
      href: string;
      routerDirection: Components.IonButton['routerDirection'];
      target?: Components.IonButton['target'];
    }
);

const Button = ({
  text,
  color = 'primary',
  disabled = false,
  expand,
  fill = 'solid',
  shape,
  size = 'default',
  type,
  className,
  href,
  routerDirection,
  target,
  onClick,
  id,
}: ButtonProps) => {
  return (
    <IonButton
      color={color}
      disabled={disabled}
      expand={expand}
      fill={fill}
      shape={shape}
      size={size}
      type={type}
      href={href}
      routerDirection={routerDirection}
      target={target}
      onClick={onClick}
      className={classNames(className)}
      id={id}
    >
      {text}
    </IonButton>
  );
};

export default Button;
