import { IonImg } from '@ionic/react';

const LogoHeader = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="flex justify-center items-center px-2 py-2 rounded-lg">
        <IonImg src="../../assets/logo.png" className="border-0 overflow-hidden rounded-xl w-16" />
        <h1 className="text-3xl sm:text-4xl bg-clip-text bg-gradient-to-r from-primary via-secondary to-tertiary text-transparent py-2 ml-2 my-0">
          BookshelfTracker
        </h1>
      </div>
    </div>
  );
};
export default LogoHeader;
