import * as Sentry from '@sentry/react';
import { Scope } from '@sentry/types';
import { createContext, ReactNode, useContext, useState } from 'react';
import userbase from 'userbase-js';
import { MyLibraryBook, MyLibraryBookItem } from '../custom-types/my-library';
import { UserbaseError } from '../custom-types/userbase';

export type MyLibraryContextType = {
  getMyLibrary: () => void;
  addBookToLibrary: (myLibraryBook: MyLibraryBook) => void;
  updateBookInLibrary: (itemId: string, myLibraryBook: MyLibraryBook) => void;
  deleteBookInLibrary: (itemId: string) => void;
  myLibrary: MyLibraryBookItem[];
  isFetchingMyLibrary: boolean;
};

export const MyLibraryContext = createContext<MyLibraryContextType>({
  getMyLibrary: () => null,
  addBookToLibrary: () => null,
  updateBookInLibrary: () => null,
  deleteBookInLibrary: () => null,
  myLibrary: [],
  isFetchingMyLibrary: true,
});

export const useMyLibrary = () => useContext(MyLibraryContext);

interface Props {
  environment: {
    MY_LIBRARY_DATABASE_NAME: string;
  };
  children: ReactNode;
}

export const MyLibraryProvider = ({ children, environment }: Props) => {
  const DATABASE_NAME: string = environment.MY_LIBRARY_DATABASE_NAME || '';
  const [myLibrary, setMyLibrary] = useState<MyLibraryBookItem[]>([]);
  const [isFetchingMyLibrary, setIsFetchingMyLibrary] = useState<boolean>(true);

  /**
   * Opens the my library database and subscribes to the changes.
   * @returns {Promise<void | UserbaseError}
   */
  const getMyLibrary: MyLibraryContextType['getMyLibrary'] =
    async (): Promise<void | UserbaseError> => {
      setIsFetchingMyLibrary(true);
      return userbase
        .openDatabase({
          databaseName: DATABASE_NAME,
          changeHandler: (items: MyLibraryBookItem[]) => {
            setMyLibrary(items);
            setIsFetchingMyLibrary(false);
          },
        })
        .catch((error) => {
          Sentry.captureException(error, (scope: Scope) => {
            scope.setTag('function', 'MyLibrary.getMyLibrary');
            return scope;
          });
          throw error;
        });
    };

  /**
   * Adds a book to the user's library.
   * @param {MyLibraryBook} myLibraryBook
   * @returns {Promise<void | UserbaseError}
   */
  const addBookToLibrary = async (myLibraryBook: MyLibraryBook): Promise<void | UserbaseError> => {
    return userbase
      .insertItem({
        databaseName: DATABASE_NAME,
        item: myLibraryBook,
      })
      .catch((error) => {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'MyLibrary.addBookToLibrary');
          return scope;
        });
        throw error;
      });
  };

  /**
   * Updates a book from the user's library.
   * @param {string} itemId The id of the library book item.
   * @param {MyLibraryBook} myLibraryBook
   * @returns {Promise<void | UserbaseError}
   */
  const updateBookInLibrary = async (
    itemId: string,
    myLibraryBook: MyLibraryBook
  ): Promise<void | UserbaseError> => {
    return userbase
      .updateItem({
        databaseName: DATABASE_NAME,
        itemId,
        item: myLibraryBook,
      })
      .catch((error) => {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'MyLibrary.updateBookInLibrary');
          return scope;
        });
        throw error;
      });
  };

  /**
   * Delets a book from the user's library.
   * @param {string} itemId The id of the library book item.
   * @returns {Promise<void | UserbaseError>}
   */
  const deleteBookInLibrary = async (itemId: string): Promise<void | UserbaseError> => {
    return userbase
      .deleteItem({
        databaseName: DATABASE_NAME,
        itemId,
      })
      .catch((error) => {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'MyLibrary.deleteBookInLibrary');
          return scope;
        });
        throw error;
      });
  };

  return (
    <MyLibraryContext.Provider
      value={{
        myLibrary,
        isFetchingMyLibrary,
        getMyLibrary,
        addBookToLibrary,
        updateBookInLibrary,
        deleteBookInLibrary,
      }}
    >
      {children}
    </MyLibraryContext.Provider>
  );
};
