import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import {
  AuthContextType,
  GeneralContextType,
  useAuth,
  useGeneral,
} from '@libs/apps-shared/contexts';
import { UserbaseError } from '@libs/apps-shared/custom-types';
import { History } from 'history';
import { useState } from 'react';
import Button from '../../components/core/Button';

interface Props {
  history: History;
}

const DangerZone = ({ history }: Props) => {
  const { setLoading }: GeneralContextType = useGeneral();
  const { deleteUser }: AuthContextType = useAuth();
  const [presentConfirmModal] = useIonAlert();
  const [deleteAccountError, setDeleteAccountError] = useState<string>();

  /**
   * Confirms the user wants to delete their account, and deletes it.
   */
  const confirmDelete = (): void => {
    presentConfirmModal({
      id: 'confirm-delete-account-modal',
      header: 'Confirm Delete Account',
      message:
        'Are you sure you want to delete your account and all its information?' +
        ' ' +
        'This action cannot be undone.',
      buttons: [
        {
          text: 'Cancel',
          handler: () => history.goBack(),
        },
        {
          text: 'Delete',
          role: 'destructive',
          id: 'confirm-delete-account-btn',
          handler: async () => {
            setLoading(true);
            const response: undefined | UserbaseError = await deleteUser();
            if (!!response) {
              setDeleteAccountError(response.message);
            } else {
              setDeleteAccountError(undefined);
            }
            setLoading(false);
          },
        },
      ],
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/app/settings" />
          </IonButtons>
          <IonTitle id="danger-zone-title">Danger Zone</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="p-8">
          <p>
            Deleting your account will delete all the books in your library, your lending history,
            and your account information.{' '}
            <span className="font-bold">This action cannot be undone.</span>
          </p>
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar className="px-2">
          {!!deleteAccountError && (
            <p className="text-danger text-center mb-2">{deleteAccountError}</p>
          )}
          <Button
            id="delete-account-btn"
            text="Delete Account"
            color="danger"
            expand="block"
            onClick={confirmDelete}
          />
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default DangerZone;
