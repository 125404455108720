import { Book, Books } from '@mylibrary/api-types';
import * as Sentry from '@sentry/react';
import { Scope } from '@sentry/types';
import { AxiosInstance } from 'axios';
import { createContext, ReactNode, useContext, useState } from 'react';
import { GetBookByISBN, GetBooksByISBN } from '../api/booksService';

export type BooksContextType = {
  cachedBooks: Books;
  getBook: (isbn: string, authToken: string) => Promise<Book>;
  getBooks: (isbn: string[], authToken: string) => Promise<Books>;
};

export const BooksContext = createContext<BooksContextType>({
  cachedBooks: {},
  getBook: async () => ({
    description: '',
    title: '',
    title_long: '',
    isbn: '',
    isbn13: '',
    dewey_decimal: '',
    binding: '',
    publisher: '',
    language: '',
    date_published: '',
    edition: '',
    pages: 0,
    dimensions: '',
    overview: '',
    image: '',
    msrp: 0,
    excerpt: '',
    synopsys: '',
    synopsis: '',
    subjects: [],
    authors: [],
  }),
  getBooks: async () => ({}),
});

export const useBooks = () => useContext(BooksContext);

interface Props {
  BooksAPI: AxiosInstance;
  children: ReactNode;
}

export const BooksProvider = ({ children, BooksAPI }: Props) => {
  const [cachedBooks, setCachedBooks] = useState<{
    [key: string]: Book;
  }>({});

  /**
   * Gets a book from in-memory cache, if not makes an API request to get the book.
   * @param {string} isbn
   * @param {string} authToken
   * @returns {Promise<Book>}
   */
  const getBook: BooksContextType['getBook'] = async (
    isbn: string,
    authToken: string
  ): Promise<Book> => {
    const bookFromCache: Book | undefined = cachedBooks[isbn];
    if (!!bookFromCache) {
      return bookFromCache;
    }
    try {
      const book: Book | undefined = await GetBookByISBN(BooksAPI, isbn, authToken);
      if (!book) {
        // If you change this message, please update the login in the catch below.
        throw new Error(
          `Book with isbn ${isbn} not found. Try again, or search for a different ISBN code.`
        );
      }
      setCachedBooks(
        Object.assign(
          {},
          {
            ...cachedBooks,
            [isbn]: book,
          }
        )
      );
      return book;
    } catch (error) {
      if ((error as Error).message.startsWith('Book with isbn')) {
        const newError: Error = new Error('Book not found.');
        newError.name = 'BookNotFound';
        Sentry.captureException(newError, (scope: Scope) => {
          scope.setTag('function', 'Books.getBook');
          return scope;
        });
      } else {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'Books.getBook');
          return scope;
        });
      }
      throw error;
    }
  };

  /**
   * Given a list of ISBNs, tries to retrieve them from cache, otherwise
   * makes an API request for them.
   * @param {string[]} isbns
   * @param {string} authToken
   * @returns {Books}
   */
  const getBooks: BooksContextType['getBooks'] = async (
    isbns: string[],
    authToken: string
  ): Promise<Books> => {
    const booksToReturn: Books = {};
    let booksNotInCache: string[] = [];
    for (const isbn of isbns) {
      const bookFromCache: Book | undefined = cachedBooks[isbn];
      if (bookFromCache) {
        booksToReturn[isbn] = bookFromCache;
      } else if (!booksNotInCache.includes(isbn)) {
        booksNotInCache.push(isbn);
      }
    }
    if (booksNotInCache.length > 0) {
      try {
        const res: Books = await GetBooksByISBN(BooksAPI, booksNotInCache, authToken);
        let booksToAddToCache: Books = {};
        for (const isbn of booksNotInCache) {
          const bookToAdd: Book = res[isbn];
          booksToReturn[isbn] = bookToAdd;
          booksToAddToCache[isbn] = bookToAdd;
        }
        setCachedBooks(
          Object.assign(
            {},
            {
              ...cachedBooks,
              ...booksToAddToCache,
            }
          )
        );
      } catch (error) {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'Books.getBooks');
          return scope;
        });
        throw error;
      }
    }
    return booksToReturn;
  };

  return (
    <BooksContext.Provider value={{ cachedBooks, getBook, getBooks }}>
      {children}
    </BooksContext.Provider>
  );
};
