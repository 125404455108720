import { Components, InputChangeEventDetail } from '@ionic/core';
import { IonIcon, IonInput, IonLabel } from '@ionic/react';
import classNames from 'classnames';
import { caretDownOutline, caretUpOutline, eye, eyeOff } from 'ionicons/icons';
import { KeyboardEvent, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = {
  label?: string;
  name: Components.IonInput['name'];
  placeholder: Components.IonInput['placeholder'];
  control: Control<any>;
  autocomplete?: Components.IonInput['autocomplete'];
  enterkeyhint?: Components.IonInput['enterkeyhint'];
  clearOnEdit?: Components.IonInput['clearOnEdit'];
  required?: boolean;
  error?: string;
  togglePasswordVisibility?: boolean;
  onKeyboardReturn?: () => void;
  onIonChange?: (value: string | number) => void;
  onIonBlur?: (value: string) => void;
} & (
  | {
      type?: Components.IonInput['type'];
      min?: never;
      max?: never;
    }
  | {
      type: 'number';
      min?: number;
      max?: number;
    }
);

const Input = ({
  label,
  name,
  placeholder,
  control,
  type,
  min,
  max,
  autocomplete,
  enterkeyhint,
  clearOnEdit,
  required = false,
  error,
  togglePasswordVisibility,
  onKeyboardReturn,
  onIonChange,
  onIonBlur,
}: Props) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  return (
    <div className="relative">
      {!!label && (
        <div className="mb-1">
          <IonLabel position="fixed" color={!!error ? 'danger' : undefined}>
            {label}
            {required ? ' *' : ''}
          </IonLabel>
        </div>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <div
            className={classNames(
              'transform duration-150 ease-in-out rounded border-2 flex items-center pl-2',
              {
                'border-medium': !focused && !error,
                'border-black dark:border-white': focused && !error,
                'border-danger': !!error,
              }
            )}
          >
            <IonInput
              class="pr-2"
              name={name}
              placeholder={placeholder || undefined}
              type={type === 'password' ? (passwordVisible ? 'text' : 'password') : type}
              min={min ? min.toString() : undefined}
              max={max ? max.toString() : undefined}
              autocomplete={autocomplete}
              enterkeyhint={enterkeyhint}
              clearOnEdit={clearOnEdit}
              required={required}
              onKeyUp={(event: KeyboardEvent<HTMLIonInputElement>) => {
                if (event.key === 'Enter' && !!onKeyboardReturn) {
                  onKeyboardReturn();
                }
              }}
              onIonChange={(event: CustomEvent<InputChangeEventDetail>) => {
                const newVal: string | null | undefined = event.detail.value;
                if (newVal !== value) {
                  onChange(type === 'number' ? parseInt(newVal || '0') : newVal);
                  if (onIonChange)
                    onIonChange(type === 'number' ? parseInt(newVal || '0') : newVal || '');
                }
              }}
              onFocus={() => setFocused(true)}
              onBlur={() => {
                if (!!onIonBlur) onIonBlur(value);
                setFocused(false);
                onBlur();
              }}
              value={value}
            />
            {togglePasswordVisibility && (
              <IonIcon
                class="cursor-pointer text-primary text-xl mx-2"
                onClick={() => setPasswordVisible(!passwordVisible)}
                icon={passwordVisible ? eyeOff : eye}
                slot="start"
              />
            )}
            {type === 'number' && (
              <div className="mx-2 flex flex-row items-center">
                <div
                  className={classNames('p-2 flex justify-center items-center', {
                    'cursor-pointer': min === undefined || value > min,
                    'opacity-50': min !== undefined && value <= min,
                  })}
                >
                  <IonIcon
                    class="text-primary text-xl"
                    onClick={() =>
                      (min === undefined || value > min) && onChange((parseInt(value, 10) || 0) - 1)
                    }
                    icon={caretDownOutline}
                  />
                </div>
                <div
                  className={classNames('p-2 flex justify-center items-center', {
                    'cursor-pointer': max === undefined || value < max,
                    'opacity-50': max !== undefined && value >= max,
                  })}
                >
                  <IonIcon
                    class="text-primary text-xl"
                    onClick={() =>
                      (max === undefined || value < max) && onChange((parseInt(value, 10) || 0) + 1)
                    }
                    icon={caretUpOutline}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      />
      <p
        className={classNames(
          'opacity-0 text-danger text-sm transform duration-150 ease-in-out inline-block m-0',
          {
            'opacity-100': !!error,
          }
        )}
      >
        {error}
      </p>
    </div>
  );
};

export default Input;
