import {
  IonBadge,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonSkeletonText,
  useIonAlert,
} from '@ionic/react';
import { ActivityContextType, useActivity } from '@libs/apps-shared/contexts';
import { Activity, UserbaseError } from '@libs/apps-shared/custom-types';
import { Book } from '@mylibrary/api-types';
import { arrowUndoOutline, bookOutline, trashOutline } from 'ionicons/icons';
import { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import MarkReturnedDatePicker from '../../../components/forms/MarkReturnedDatePicker';
import { getISODate, getReadableDate } from '../../../utils/dates';
import BookThumbnail from '../../books/BookThumbnail';

type DueBackSoonSeverity = {
  label: string;
  severityColor: 'success' | 'primary' | 'warning' | 'danger';
};

interface Props {
  itemId: string;
  activity: Activity;
  book: Book | undefined;
  showViewBookSliderOption?: boolean;
  onClick: () => void;
}

const ActivityListItem = ({
  itemId,
  activity,
  book,
  showViewBookSliderOption = true,
  onClick,
}: Props) => {
  const { deleteActivity }: ActivityContextType = useActivity();
  const history = useHistory();
  const [presentConfirmModal] = useIonAlert();
  const dueBackSoonSeverity: DueBackSoonSeverity = useMemo(() => {
    if (!!activity.dateReturned) {
      const readableDateReturned: string = getReadableDate(activity.dateReturned);
      return {
        label: `Returned: ${readableDateReturned}`,
        severityColor: 'success',
      };
    }
    const nowISO: string = getISODate(new Date());
    const dueDateISO: string = getISODate(activity.dueDate);
    const readableDueDate: string = getReadableDate(activity.dueDate);
    if (nowISO > dueDateISO) {
      return {
        label: `Overdue: ${readableDueDate}`,
        severityColor: 'danger',
      };
    }
    const weekBeforeDueDate: Date = new Date(activity.dueDate);
    weekBeforeDueDate.setDate(weekBeforeDueDate.getDate() - 7);
    const weekBeforeDueDateISO: string = getISODate(weekBeforeDueDate);
    if (nowISO >= weekBeforeDueDateISO) {
      return {
        label: `Due soon: ${readableDueDate}`,
        severityColor: 'warning',
      };
    }
    return {
      label: `Due: ${readableDueDate}`,
      severityColor: 'primary',
    };
  }, [activity]);
  const borrowerName = useMemo(() => {
    const { username, firstName, lastName } = activity.borrower;
    let baseBorrowername: string =
      (!!firstName ? `${firstName + ' '}` : '') + (!!lastName ? `${lastName + ' '}` : '');
    if (activity.borrower.borrowerType === 'user') {
      return baseBorrowername + (!!firstName || !!lastName) ? `(${username})` : username;
    }
    return baseBorrowername;
  }, [activity]);
  const slidingItemRef = useRef<HTMLIonItemSlidingElement>(null);
  const [wantsToReturn, setWantsToReturn] = useState<boolean>(false);

  /**
   * Navigate to the book's detail screen and close any opened sliding items.
   */
  const navToBookDetails = (): void => {
    history.push(`/app/library/details/${activity.book.isbn}`);
    slidingItemRef.current?.closeOpened();
  };

  /**
   * Confirms the user wants to delete the activity from their records.
   */
  const confirmDeleteActivity = async (): Promise<void> => {
    presentConfirmModal({
      header: 'Confirm Delete',
      message:
        'Are you sure you want to delete this from your ' +
        'records? This action cannot be undone.',
      buttons: [
        {
          text: 'Cancel',
          handler: async () => {
            slidingItemRef.current?.closeOpened();
          },
        },
        {
          text: 'Ok',
          handler: async () => {
            try {
              await deleteActivity(itemId);
            } catch (error) {
              const err: UserbaseError = error as UserbaseError;
              alert(err.message);
            } finally {
              slidingItemRef.current?.closeOpened();
            }
          },
        },
      ],
    });
  };

  return (
    <IonItemSliding ref={slidingItemRef}>
      {(showViewBookSliderOption || !activity.dateReturned) && (
        <IonItemOptions side="start">
          {showViewBookSliderOption && (
            <IonItemOption color="tertiary" onClick={navToBookDetails}>
              View Book
              <IonIcon slot="bottom" icon={bookOutline} className="mt-1" />
            </IonItemOption>
          )}
          {!activity.dateReturned && (
            <IonItemOption color="success" onClick={() => setWantsToReturn(true)}>
              Return
              <IonIcon slot="bottom" icon={arrowUndoOutline} className="mt-1" />
            </IonItemOption>
          )}
        </IonItemOptions>
      )}
      <MarkReturnedDatePicker
        itemId={itemId}
        activity={activity}
        wantsToReturn={wantsToReturn}
        onCancel={() => {
          setWantsToReturn(false);
          slidingItemRef.current?.closeOpened();
        }}
        onMarkReturned={() => {
          setWantsToReturn(false);
          slidingItemRef.current?.closeOpened();
        }}
      />
      <IonItem button onClick={onClick} lines="full">
        <BookThumbnail imageLink={book?.image} loading={!book} />
        <IonLabel className="m-0">
          {!!book ? (
            <>
              <h2 className="text-xl m-0">{book.title}</h2>
              <p className="mt-0 mb-1 dark:text-white">Borrowed By: {borrowerName}</p>
              <div>
                <IonBadge color={dueBackSoonSeverity.severityColor}>
                  {dueBackSoonSeverity.label}
                </IonBadge>
              </div>
            </>
          ) : (
            <>
              <IonSkeletonText animated className="w-2/5 sm:w-1/5" />
              <IonSkeletonText animated className="w-3/4 sm:w-1/3" />
              <IonSkeletonText animated className="w-1/2" />
            </>
          )}
        </IonLabel>
      </IonItem>
      <IonItemOptions side="end">
        <IonItemOption color="danger" onClick={confirmDeleteActivity}>
          <IonIcon slot="bottom" icon={trashOutline} className="mt-1" />
          Delete
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
};

export default ActivityListItem;
