import { ActivityContextType, useActivity } from '@libs/apps-shared/contexts';
import { ActivityItem } from '@libs/apps-shared/custom-types';
import { Book } from '@mylibrary/api-types';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import ActivityList from '../lists/activity/ActivityList';
import SkeletonList from '../lists/SkeletonList';

interface Props {
  className: React.HTMLAttributes<HTMLDivElement>['className'];
  itemId: string | undefined;
  isbn: string;
  book: Book | undefined;
}

const ActivityTab = ({ className, itemId, isbn, book }: Props) => {
  const { activity, isFetchingActivity }: ActivityContextType = useActivity();
  const [filteredActivity, setFilteredActivity] = useState<ActivityItem[]>([]);
  const [itemsToShow, setItemsToShow] = useState<number>(10);

  /**
   * Loads more activity into view on scroll.
   * @param {CustomEvent<Void>} event
   */
  const loadMoreActivity = (event: CustomEvent<void>): void => {
    let numberToAdd: number = 10;
    if (itemsToShow + 10 > filteredActivity.length) {
      numberToAdd = filteredActivity.length - itemsToShow;
    }
    setItemsToShow(itemsToShow + numberToAdd);
    // Unfortunately this type is not correct, so have to ignore.
    //@ts-ignore
    event.target.complete();
  };

  useEffect(() => {
    if (itemId) {
      setFilteredActivity(
        activity.filter(({ item: { book } }: ActivityItem) => {
          return book.itemId === itemId;
        })
      );
    }
  }, [itemId, activity]);

  return (
    <div className={classNames(className)}>
      {!!book ? (
        <ActivityList
          books={{
            [isbn]: book,
          }}
          getBooksError={undefined}
          activity={filteredActivity.slice(0, itemsToShow)}
          isFetchingActivity={isFetchingActivity}
          totalActivity={activity.length}
          noResultsMessage="Lend this book to see some lending activity!"
          showViewBookSliderOption={false}
          loadMoreActivity={loadMoreActivity}
        />
      ) : (
        <SkeletonList type="activity" />
      )}
    </div>
  );
};

export default ActivityTab;
