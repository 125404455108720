import { AuthContextType, useAuth } from '@libs/apps-shared/contexts';
import { CustomRouteProps } from '@libs/apps-shared/custom-types';
import * as Sentry from '@sentry/react';
import { Redirect, Route } from 'react-router-dom';

const SentryRoute = Sentry.withSentryRouting(Route);

/**
 * Component that handles private routes and redirects the user out of the app
 * if the user is not signed in.
 */
const PrivateRoute = ({ component, ...rest }: CustomRouteProps) => {
  const { currentUser }: AuthContextType = useAuth();
  return (
    <SentryRoute
      {...rest}
      {...(!!currentUser
        ? {
            component: component,
          }
        : {
            render: () => (
              <Redirect
                to={{
                  pathname: '/sign-in',
                }}
              />
            ),
          })}
    />
  );
};

export default PrivateRoute;
