import {
  PurchaserInfo,
  Purchases,
  PurchasesOfferings,
  PurchasesPackage,
} from '@awesome-cordova-plugins/purchases';
import { isPlatform } from '@ionic/react';
import * as Sentry from '@sentry/react';
import { Scope } from '@sentry/types';
import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import userbase, { CancelSubscriptionResult, Session, UserResult } from 'userbase-js';
import { StripeEnvironment } from '../custom-types';
import {
  ChangePasswordDto,
  ForgotPasswordDto,
  SignInDto,
  SignUpDto,
  UpdateUserDto,
} from '../custom-types/auth';
import { SubscriptionInfo } from '../custom-types/payments';
import { UserbaseError } from '../custom-types/userbase';
import { getSubscriptionInfo } from '../utils';
import { useGeneral } from './General';

const DEFAULT_SESSION_LENGTH: number = 365 * 24;

interface RadioGroupOption {
  value: string;
  label: string | ReactElement;
  note?: string;
}

export type AuthContextType = {
  signIn: (signInDto: SignInDto) => Promise<undefined | UserbaseError>;
  signOut: () => Promise<undefined | UserbaseError>;
  signUp: (signUpDto: SignUpDto) => Promise<undefined | UserbaseError>;
  forgotPassword: (forgotPasswordDto: ForgotPasswordDto) => Promise<undefined | UserbaseError>;
  updateUser: (updateUserDto: UpdateUserDto) => Promise<undefined | UserbaseError>;
  changePassword: (changePasswordDto: ChangePasswordDto) => Promise<undefined | UserbaseError>;
  deleteUser: () => Promise<undefined | UserbaseError>;
  purchaseSubscription: (priceId: string, successUrl?: string, cancelUrl?: string) => Promise<void>;
  updatePaymentMethod: (successUrl?: string, cancelUrl?: string) => Promise<void>;
  cancelSubscription: () => Promise<CancelSubscriptionResult>;
  resumeSubscription: () => Promise<void>;
  purchaseMobileSubscription: (productIdentifier: string) => Promise<void>;
  restoreMobileSubscription: () => Promise<void>;
  initialized: boolean;
  currentUser?: UserResult;
  lastUsedUsername?: string;
  subscriptionInfo?: SubscriptionInfo;
  subscriptionOptions: RadioGroupOption[];
};

export const AuthContext = createContext<AuthContextType>({
  signIn: (signInDto: SignInDto) => Promise.resolve(undefined),
  signOut: () => Promise.resolve(undefined),
  signUp: (signUpDto: SignUpDto) => Promise.resolve(undefined),
  forgotPassword: (forgotPasswordDto: ForgotPasswordDto) => Promise.resolve(undefined),
  updateUser: (updateUserDto: UpdateUserDto) => Promise.resolve(undefined),
  changePassword: (changePasswordDto: ChangePasswordDto) => Promise.resolve(undefined),
  deleteUser: () => Promise.resolve(undefined),
  purchaseSubscription: () => Promise.resolve(undefined),
  updatePaymentMethod: () => Promise.resolve(undefined),
  cancelSubscription: () => Promise.resolve({ cancelSubscriptionAt: new Date() }),
  resumeSubscription: () => Promise.resolve(undefined),
  purchaseMobileSubscription: (productIdentifier: string) => Promise.resolve(undefined),
  restoreMobileSubscription: () => Promise.resolve(undefined),
  initialized: false,
  currentUser: undefined,
  lastUsedUsername: undefined,
  subscriptionInfo: undefined,
  subscriptionOptions: [],
});

export const useAuth = () => useContext(AuthContext);

interface Props {
  environment: {
    production: boolean;
    PUBLIC_APP_URL: string;
    USERBASE_APP_ID: string;
    REVENUE_CAT_SECRET_APPLE: string;
    REVENUE_CAT_SECRET_GOOGLE: string;
  };
  stripe: StripeEnvironment;
  children: ReactNode;
}

export const AuthProvider = ({ environment, stripe, children }: Props) => {
  const DEFAULT_SUCCESS_URL: string = `${environment.PUBLIC_APP_URL}/app/settings/manage-subscription`;
  const DEFAULT_CANCEL_URL: string = `${environment.PUBLIC_APP_URL}/app/settings/manage-subscription`;
  const { setLoading } = useGeneral();
  const [isMobileApp] = useState<boolean>(!isPlatform('desktop') && !isPlatform('mobileweb'));
  const [initialized, setInitialized] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<UserResult>();
  const [lastUsedUsername, setLastUsedUsername] = useState<string>();
  const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfo>();
  const [subscriptionOptions, setSubscriptionOptions] = useState<RadioGroupOption[]>([]);
  const [purchaserInfo, setPurchaserInfo] = useState<PurchaserInfo>();

  /**
   * Signs the user into the app.
   * @param {SignInDto} signInDto
   * @returns {Promise<undefined | UserbaseError}
   */
  const signIn: AuthContextType['signIn'] = async (
    signInDto: SignInDto
  ): Promise<undefined | UserbaseError> => {
    try {
      const response: UserResult = await userbase.signIn({
        ...signInDto,
        ...(signInDto.rememberMe
          ? {
              sessionLength: DEFAULT_SESSION_LENGTH,
            }
          : null),
      });
      setLastUsedUsername(response.username);
      setCurrentUser(response);
      Sentry.setUser({ id: response.userId });
      await purchaseLogIn(response.userId);
      return;
    } catch (error) {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'Auth.signIn');
        return scope;
      });
      return error as UserbaseError;
    }
  };

  /**
   * Signs the user out.
   * @returns {Promise<undefined | UserbaseError}
   */
  const signOut: AuthContextType['signOut'] = async (): Promise<undefined | UserbaseError> => {
    try {
      const response: void = await userbase.signOut();
      setCurrentUser(undefined);
      Sentry.configureScope((scope) => scope.setUser(null));
      await purchaseLogOut();
      return;
    } catch (error) {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'Auth.signOut');
        return scope;
      });
      setCurrentUser(undefined);
      return error as UserbaseError;
    }
  };

  /**
   * Signs the user out.
   * @param {SignUpDto} signUpDto
   * @returns {Promise<undefined | UserbaseError}
   */
  const signUp: AuthContextType['signUp'] = async (
    signUpDto: SignUpDto
  ): Promise<undefined | UserbaseError> => {
    try {
      const response: UserResult = await userbase.signUp({
        ...signUpDto,
        rememberMe: 'local',
        sessionLength: DEFAULT_SESSION_LENGTH,
      });
      setCurrentUser(response);
      Sentry.setUser({ id: response.userId });
      await purchaseLogIn(response.userId);
      return;
    } catch (error) {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'Auth.signUp');
        return scope;
      });
      setCurrentUser(undefined);
      return error as UserbaseError;
    }
  };

  /**
   * Submits request to forgot password.
   * @param {ForgotPasswordDto} forgotPasswordDto
   * @returns {Promise<undefined | UserbaseError}
   */
  const forgotPassword: AuthContextType['forgotPassword'] = async (
    forgotPasswordDto: ForgotPasswordDto
  ): Promise<undefined | UserbaseError> => {
    try {
      const response: void = await userbase.forgotPassword(forgotPasswordDto);
      return;
    } catch (error) {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'Auth.forgotPassword');
        return scope;
      });
      return error as UserbaseError;
    }
  };

  /**
   * Updates the user.
   * @param {UpdateUserDto} updateUserDto
   * @returns {Promise<undefined | UserbaseError}
   */
  const updateUser: AuthContextType['updateUser'] = async (
    updateUserDto: UpdateUserDto
  ): Promise<undefined | UserbaseError> => {
    try {
      await userbase.updateUser(updateUserDto);
      return;
    } catch (error) {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'Auth.updateUser');
        return scope;
      });
      return error as UserbaseError;
    }
  };

  /**
   * Changes the user's password.
   * @param {ChangePasswordDto} changePasswordDto
   * @returns {Promise<undefined | UserbaseError}
   */
  const changePassword: AuthContextType['changePassword'] = async (
    changePasswordDto: ChangePasswordDto
  ): Promise<undefined | UserbaseError> => {
    try {
      await userbase.updateUser(changePasswordDto);
      return;
    } catch (error) {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'Auth.changePassword');
        return scope;
      });
      return error as UserbaseError;
    }
  };

  /**
   * Deletes the user.
   * @returns {Promise<undefined | UserbaseError}
   */
  const deleteUser: AuthContextType['deleteUser'] = async (): Promise<
    undefined | UserbaseError
  > => {
    try {
      await userbase.deleteUser();
      setCurrentUser(undefined);
      return;
    } catch (error) {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'Auth.deleteUser');
        return scope;
      });
      return error as UserbaseError;
    }
  };

  /**
   * Purchases a subscription.
   * @param {string} priceId
   * @param {string} [successUrl=DEFAULT_SUCCESS_URL]
   * @param {string} [cancelUrl=DEFAULT_CANCEL_URL]
   * @returns {Promise<void>}
   */
  const purchaseSubscription: AuthContextType['purchaseSubscription'] = async (
    priceId: string,
    successUrl: string = DEFAULT_SUCCESS_URL,
    cancelUrl: string = DEFAULT_CANCEL_URL
  ): Promise<void> => {
    return userbase
      .purchaseSubscription({
        successUrl,
        cancelUrl,
        priceId,
      })
      .catch((error) => {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'Auth.purchaseSubscription');
          return scope;
        });
        throw error;
      });
  };

  /**
   * Updates the user's payment method.
   * @param {string} [successUrl=DEFAULT_SUCCESS_URL]
   * @param {string} [cancelUrl=DEFAULT_CANCEL_URL]
   * @returns {Promise<void>}
   */
  const updatePaymentMethod: AuthContextType['updatePaymentMethod'] = async (
    successUrl: string = DEFAULT_SUCCESS_URL,
    cancelUrl: string = DEFAULT_CANCEL_URL
  ): Promise<void> => {
    return userbase
      .updatePaymentMethod({
        successUrl,
        cancelUrl,
      })
      .catch((error) => {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'Auth.updatePaymentMethod');
          return scope;
        });
        throw error;
      });
  };

  /**
   * Cancels the user's subscription.
   * @returns {Promise<CancelSubscriptionResult>}
   */
  const cancelSubscription: AuthContextType['cancelSubscription'] =
    async (): Promise<CancelSubscriptionResult> => {
      return userbase.cancelSubscription().catch((error) => {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'Auth.cancelSubscription');
          return scope;
        });
        throw error;
      });
    };

  /**
   * Resumes the user's subscription.
   * @returns {Promise<void>}
   */
  const resumeSubscription: AuthContextType['resumeSubscription'] = async (): Promise<void> => {
    return userbase.resumeSubscription().catch((error) => {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'Auth.resumeSubscription');
        return scope;
      });
      throw error;
    });
  };

  /**
   * Purchases a subscription on the current mobile platform.
   * @param {string} productIdentifier
   * @returns {Promise<void>}
   */
  const purchaseMobileSubscription: AuthContextType['purchaseMobileSubscription'] = async (
    productIdentifier: string
  ): Promise<void> => {
    return new Promise((resolve) => {
      Purchases.purchaseProduct(productIdentifier).then(
        ({ purchaserInfo }) => {
          setPurchaserInfo(purchaserInfo);
          resolve();
        },
        ({ error, userCancelled }) => {
          resolve();
          if (!userCancelled) {
            Sentry.captureException(error, (scope: Scope) => {
              scope.setTag('function', 'Auth.purchaseMobileSubscription');
              scope.setTag('productIdentifier', productIdentifier);
              return scope;
            });
            alert(error.message || 'There was an unknown error making your purchase.');
          }
        }
      );
    });
  };

  /**
   * Restores a subscription on the current mobile platform.
   * @returns {Promise<void>}
   */
  const restoreMobileSubscription: AuthContextType['restoreMobileSubscription'] =
    async (): Promise<void> => {
      return new Promise((resolve) => {
        Purchases.restoreTransactions()
          .then((purchaserInfo: PurchaserInfo) => {
            setPurchaserInfo(purchaserInfo);
            resolve();
          })
          .catch((error) => {
            Sentry.captureException(error, (scope: Scope) => {
              scope.setTag('function', 'Auth.restoreMobileSubscription');
              return scope;
            });
            resolve();
            alert(error.message || 'There was an unknown error restoring your purchase.');
          });
      });
    };

  /**
   * Logs the user into the purchase service.
   * @param {string} userId
   * @returns {Promise<void>}
   */
  const purchaseLogIn = async (userId: string): Promise<void> => {
    if (isMobileApp) {
      try {
        const res = await Purchases.logIn(userId);
        setPurchaserInfo(res.purchaserInfo);
        // alert('logged in: ' + JSON.stringify(res.purchaserInfo));
      } catch (error) {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'Auth.purchaseLogIn');
          return scope;
        });
        console.error('Error logging the user into purchases: ', error);
      }
    } else {
      console.warn('purchaseLogIn is not supported in the browser.');
    }
  };

  /**
   * Logs the user out of the purchase service.
   * @returns {Promise<void>}
   */
  const purchaseLogOut = async (): Promise<void> => {
    if (isMobileApp) {
      try {
        await Purchases.logOut();
      } catch (error) {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'Auth.purchaseLogOut');
          return scope;
        });
        console.error('Error logging the user out of purchases: ', error);
      }
    } else {
      console.warn('purchaseLogOut is not supported in the browser.');
    }
  };

  useEffect(() => {
    if (currentUser) {
      setSubscriptionInfo(
        getSubscriptionInfo(
          {
            trialExpirationDate: currentUser.trialExpirationDate?.toString(),
            subscriptionStatus: currentUser.subscriptionStatus,
            subscriptionPlanId: currentUser.subscriptionPlanId,
            cancelSubscriptionAt: currentUser.cancelSubscriptionAt?.toString(),
          },
          currentUser.protectedProfile,
          purchaserInfo,
          stripe,
          isMobileApp,
          isPlatform('ios'),
          isPlatform('android')
        )
      );
      return;
    }
    setSubscriptionInfo(undefined);
  }, [currentUser, purchaserInfo]);

  useEffect(() => {
    const freeTrial = {
      value: 'free_trial',
      label: '14 Day Free Trial',
      note: 'No CC Required',
    };
    if (isMobileApp) {
      if (!environment.production) {
        Purchases.setDebugLogsEnabled(true);
      }
      if (isPlatform('ios')) {
        Purchases.setup(environment.REVENUE_CAT_SECRET_APPLE);
      } else {
        Purchases.setup(environment.REVENUE_CAT_SECRET_GOOGLE);
      }
      Purchases.getOfferings().then(
        (offerings: PurchasesOfferings) => {
          if (offerings.current !== null && offerings.current.availablePackages.length !== 0) {
            setSubscriptionOptions([
              ...offerings.current.availablePackages
                .sort((a: PurchasesPackage, b: PurchasesPackage) => {
                  if (a.product.price < b.product.price) return 1;
                  if (a.product.price > b.product.price) return -1;
                  return 0;
                })
                .map((p: PurchasesPackage) => ({
                  value: p.product.identifier,
                  label: `${p.product.price_string} / ${
                    p.packageType === 'MONTHLY' ? 'month' : 'year'
                  }`,
                  // note: 'All features',
                })),
              freeTrial,
            ]);
          }
        },
        (error) => {
          Sentry.captureException(error, (scope: Scope) => {
            scope.setTag('function', 'useEffect.Auth.Purchases.getOfferings');
            return scope;
          });
          alert(error.message || 'Unknown error fetching available subscriptions.');
          setSubscriptionOptions([freeTrial]);
        }
      );
      Purchases.onPurchaserInfoUpdated().subscribe({
        next: (purchaserInfo: PurchaserInfo) => {
          setPurchaserInfo(purchaserInfo);
        },
        error: (error) => {
          Sentry.captureException(error, (scope: Scope) => {
            scope.setTag('function', 'useEffect.Auth.Purchases.onPurchaserInfoUpdated');
            return scope;
          });
          alert(error.message || 'Unknown error updating purchaser info.');
        },
      });
    } else {
      setSubscriptionOptions([
        ...(Object.keys(stripe) as (keyof typeof stripe)[])
          .filter((productId) => stripe[productId].ACTIVE)
          .map((productId: string | number) => ({
            value: productId.toString(),
            label: `${stripe[productId].PRICE} / ${
              stripe[productId].FREQUENCY === 'monthly' ? 'month' : 'year'
            }`,
            // note: 'All features',
          })),
        freeTrial,
      ]);
    }
  }, []);

  /**
   * Initializes userbase and establishes the user's state.
   */
  const initUserbase = async (): Promise<void> => {
    setLoading(true);
    try {
      const APP_ID: string | undefined = environment.USERBASE_APP_ID;
      if (!APP_ID) throw { message: 'No app id provided.' };
      const response: Session = await userbase.init({
        appId: APP_ID,
        sessionLength: DEFAULT_SESSION_LENGTH,
        updateUserHandler: (updatedUser: { user: UserResult }) => setCurrentUser(updatedUser.user),
      });
      if (response.lastUsedUsername) {
        setLastUsedUsername(response.lastUsedUsername);
      }
      if (response.user) {
        setCurrentUser(response.user);
        Sentry.setUser({ id: response.user.userId });
        await purchaseLogIn(response.user.userId);
      }
    } catch (e) {
      Sentry.captureException(e, (scope: Scope) => {
        scope.setTag('function', 'Auth.initUserbase');
        return scope;
      });
      const error: UserbaseError = e as UserbaseError;
      console.log('error initializing userbase: ', error.message);
    } finally {
      setInitialized(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    initUserbase();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        signUp,
        forgotPassword,
        updateUser,
        changePassword,
        deleteUser,
        purchaseSubscription,
        updatePaymentMethod,
        cancelSubscription,
        resumeSubscription,
        purchaseMobileSubscription,
        restoreMobileSubscription,
        initialized,
        currentUser,
        lastUsedUsername,
        subscriptionInfo,
        subscriptionOptions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
