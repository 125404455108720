import {
  ActivityProvider,
  AuthProvider,
  BooksProvider,
  GeneralProvider,
  MyLibraryProvider,
  ScanningProvider,
} from '@libs/apps-shared/contexts';
import { stripe } from '@mylibrary/stripe';
import * as Sentry from '@sentry/react';
import { environment } from './environments/environment';
import Navigation from './Navigation';

/* Tailwind styles */
import 'tailwindcss/tailwind.css';
import './styles/global.css';
import './styles/tailwind.css';
import './styles/variables.css';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import { BooksAPI } from './api/apiKit';

const App = () => {
  return (
    <GeneralProvider>
      <AuthProvider environment={environment} stripe={stripe}>
        <BooksProvider BooksAPI={BooksAPI}>
          <MyLibraryProvider environment={environment}>
            <ActivityProvider environment={environment}>
              <ScanningProvider>
                <Navigation />
              </ScanningProvider>
            </ActivityProvider>
          </MyLibraryProvider>
        </BooksProvider>
      </AuthProvider>
    </GeneralProvider>
  );
};

export default Sentry.withProfiler(App);
