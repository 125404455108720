export const environment = {
  production: false,
  environment: 'develop',
  SENTRY_ENABLED: true,
  PUBLIC_APP_URL: 'https://develop.app.bookshelftracker.com',
  PUBLIC_WEBSITE_URL: 'https://develop.bookshelftracker.com',
  ACTIVITY_DATABASE_NAME: 'activity_75245265',
  MY_LIBRARY_DATABASE_NAME: 'my_library_62662384',
  USERBASE_APP_ID: 'f4e74a6c-ec5b-46eb-b7ca-d9b5f4933b70',
  BOOKS_BASE_URL: 'https://dev-api.bookshelftracker.com/api',
  REVENUE_CAT_SECRET_APPLE: 'appl_mRztpobkCuSSkifKZqJOAmuzupt',
  REVENUE_CAT_SECRET_GOOGLE: 'goog_bBPHxxkZJrSblvaBKaPlcdvKfjt',
};
