import { useMemo } from 'react';
import { useAvailableCopies } from '../../../hooks/useAvailableCopies';
import MyLibraryListItem, { MyLibraryListItemProps } from './MyLibraryListItem';

interface Props {
  checked: boolean;
  onCheck: (checked: boolean) => void;
  myLibraryListItemProps: MyLibraryListItemProps;
}

const SelectableMyLibraryListItem = ({ checked, onCheck, myLibraryListItemProps }: Props) => {
  const { availableCopies } = useAvailableCopies(myLibraryListItemProps.itemId);
  const disabled: boolean = useMemo(() => availableCopies === 0, [availableCopies]);

  return (
    <MyLibraryListItem
      {...myLibraryListItemProps}
      disabled={disabled}
      checked={checked}
      onCheck={onCheck}
    />
  );
};

export default SelectableMyLibraryListItem;
