import { IonInfiniteScroll, IonInfiniteScrollContent, IonList } from '@ionic/react';
import { ActivityItem } from '@libs/apps-shared/custom-types';
import { Books } from '@mylibrary/api-types';
import { useHistory } from 'react-router';
import NoResults from '../../search-filter-sort/NoResults';
import SkeletonList from '../SkeletonList';
import ActivityListItem from './ActivityListItem';

interface Props {
  books: Books;
  getBooksError: Error | undefined;
  activity: ActivityItem[];
  isFetchingActivity: boolean;
  totalActivity: number;
  noResultsMessage?: string;
  showViewBookSliderOption?: boolean;
  loadMoreActivity: (event: CustomEvent<void>) => void;
}

const ActivityList = ({
  books,
  getBooksError,
  activity = [],
  isFetchingActivity,
  totalActivity = 0,
  noResultsMessage,
  showViewBookSliderOption,
  loadMoreActivity,
}: Props) => {
  const history = useHistory();

  return !getBooksError ? (
    isFetchingActivity ? (
      <SkeletonList type="activity" />
    ) : activity.length > 0 ? (
      <>
        <IonList>
          {activity.map(({ item, itemId }: ActivityItem) => {
            return (
              <ActivityListItem
                key={`activity-list-item-${itemId}`}
                itemId={itemId}
                activity={item}
                book={books[item.book.isbn]}
                showViewBookSliderOption={showViewBookSliderOption}
                onClick={() => history.push(`/app/lending/${itemId}`)}
              />
            );
          })}
        </IonList>
        <IonInfiniteScroll
          onIonInfinite={loadMoreActivity}
          threshold="100px"
          disabled={totalActivity === activity.length}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data…"
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </>
    ) : (
      <NoResults
        message={noResultsMessage ? noResultsMessage : 'Lend a book to see some lending activity!'}
      />
    )
  ) : (
    <NoResults
      message={`There was an error loading your lending activity: ${getBooksError.message}`}
    />
  );
};

export default ActivityList;
