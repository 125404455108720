import { DatetimeChangeEventDetail } from '@ionic/core';
import { IonDatetime, IonModal } from '@ionic/react';
import { ActivityContextType, useActivity } from '@libs/apps-shared/contexts';
import { Activity, UserbaseError } from '@libs/apps-shared/custom-types';
import { useEffect, useMemo, useRef } from 'react';
import { getISODate } from '../../utils/dates';

interface Props {
  itemId: string;
  activity: Activity;
  wantsToReturn: boolean;
  onCancel?: () => void;
  onMarkReturned?: () => void;
}

const MarkReturnedDatePicker = ({
  itemId,
  activity,
  wantsToReturn,
  onCancel,
  onMarkReturned,
}: Props) => {
  const modalRef = useRef<HTMLIonModalElement>(null);
  const { updateActivity }: ActivityContextType = useActivity();
  const minDueDate: string = useMemo(() => {
    return getISODate(activity.borrowDate);
  }, [activity]);

  /**
   * Marks the activity as returned.
   * @param {string} dateReturned
   */
  const markReturned = async (dateReturned: string): Promise<void> => {
    try {
      await updateActivity(itemId, {
        ...activity,
        dateReturned,
      });
    } catch (error) {
      const err: UserbaseError = error as UserbaseError;
      alert(err.message);
    } finally {
      if (onMarkReturned) onMarkReturned();
    }
  };

  useEffect(() => {
    if (wantsToReturn) modalRef.current?.present();
  }, [wantsToReturn]);

  return (
    <IonModal className="datetime-modal" ref={modalRef} onIonModalDidDismiss={onCancel}>
      <IonDatetime
        presentation="date"
        onIonCancel={onCancel}
        onIonChange={(event: CustomEvent<DatetimeChangeEventDetail>) => {
          if (event.detail.value) {
            const valueOnChange: string =
              typeof event.detail.value === 'string'
                ? event.detail.value
                : event.detail.value.length > 0
                ? event.detail.value[0]
                : '';
            markReturned(valueOnChange);
          }
          modalRef.current?.dismiss();
        }}
        min={minDueDate}
        showDefaultButtons={true}
      />
    </IonModal>
  );
};

export default MarkReturnedDatePicker;
