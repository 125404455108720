import { useActivity, useMyLibrary } from '@libs/apps-shared/contexts';
import { ActivityItem, MyLibraryBookItem } from '@libs/apps-shared/custom-types';
import { useEffect, useState } from 'react';

export interface AvailableCopies {
  availableCopies: number;
  totalCopies: number;
}

/**
 * Reusable hook to calculate the number of available copies for a given book.
 * @param {string | undefined} itemId
 * @returns {AvailableCopies}
 */
export const useAvailableCopies = (itemId: string | undefined): AvailableCopies => {
  const { myLibrary } = useMyLibrary();
  const { activity } = useActivity();
  const [availableCopies, setAvailableCopies] = useState<number>(1);
  const [totalCopies, setTotalCopies] = useState<number>(1);

  useEffect(() => {
    if (!itemId) return;
    const foundBook: MyLibraryBookItem | undefined = myLibrary.find((item: MyLibraryBookItem) => {
      return itemId === item.itemId;
    });
    if (!!foundBook) {
      let borrowedCount: number = 0;
      activity.forEach(({ item }: ActivityItem) => {
        if (item.book.itemId === itemId) {
          if (!!item.dateReturned) {
            return false;
          }
          borrowedCount += item.numberOfCopiesBorrowed;
        }
        return false;
      });
      const numberOfCopies: number = foundBook.item.numberOfCopies;
      setAvailableCopies(numberOfCopies - borrowedCount);
      setTotalCopies(numberOfCopies);
    }
  }, [itemId, myLibrary, activity]);

  return {
    availableCopies,
    totalCopies,
  };
};
