import { RadioGroupChangeEventDetail } from '@ionic/core';
import { IonIcon, IonItem, IonLabel, IonPopover, IonRadio, IonRadioGroup } from '@ionic/react';
import classNames from 'classnames';
import { chevronDown } from 'ionicons/icons';
import React, { ReactElement, useRef, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

export type SelectOption = {
  value: string;
  label: string | ReactElement;
};

type Props = {
  options: SelectOption[];
  label?: string;
  name: string;
  placeholder: string;
  control: Control<any>;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  hint?: React.ReactNode;
  postfix?: ReactElement;
  onIonChange?: (value: string) => void;
  onIonBlur?: (value: string) => void;
};

const Select = ({
  options,
  label,
  name,
  placeholder,
  control,
  required = false,
  disabled = false,
  error,
  hint,
  postfix,
  onIonChange,
  onIonBlur,
}: Props) => {
  const [optionsPopoverStatus, setOptionsPopoverStatus] = useState<{
    showPopover: boolean;
    event: any;
  }>({ showPopover: false, event: undefined });
  const selectRef = useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<any>): void => {
    if (disabled) return;
    event.stopPropagation();
    selectRef.current?.click();
  };

  const reusableSetOptionsPopoverStatus = (
    showPopover: boolean,
    event: React.MouseEvent<HTMLDivElement>['nativeEvent'] | undefined = undefined,
    callback: (() => void) | undefined = undefined
  ) => {
    if (disabled) return;
    setOptionsPopoverStatus({ showPopover, event });
    if (callback) callback();
  };

  return (
    <div className={classNames('relative', { 'cursor-pointer': !disabled })}>
      {!!label && (
        <div className="mb-1">
          <IonLabel position="fixed" color={!!error ? 'danger' : undefined}>
            {label}
            {required ? ' *' : ''}
          </IonLabel>
        </div>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <div
            ref={selectRef}
            className={classNames(
              'transform duration-150 ease-in-out rounded border-2 flex justify-between items-center pl-2 py-[6px]',
              {
                'border-medium': !optionsPopoverStatus.showPopover && !error,
                'border-black dark:border-white': optionsPopoverStatus.showPopover && !error,
                'border-danger': !!error,
              }
            )}
            onClick={(event: React.MouseEvent<HTMLDivElement>) =>
              reusableSetOptionsPopoverStatus(true, event.nativeEvent)
            }
          >
            <span onClick={handleClick} className={classNames({ 'text-medium': !value })}>
              {value
                ? options.find((option: SelectOption) => option.value === value)?.label
                : placeholder}
            </span>
            <IonPopover
              className="custom-select"
              event={optionsPopoverStatus.event}
              isOpen={optionsPopoverStatus.showPopover}
              onDidDismiss={() => {
                reusableSetOptionsPopoverStatus(false, undefined, () => {
                  onBlur();
                  if (onIonBlur) onIonBlur(value);
                });
              }}
            >
              <IonRadioGroup
                name={name}
                value={value}
                onIonChange={(event: CustomEvent<RadioGroupChangeEventDetail>) => {
                  const newValue: string = event.detail.value;
                  if (newValue !== value) {
                    onChange(newValue);
                    if (onIonChange) onIonChange(newValue);
                    setTimeout(() => reusableSetOptionsPopoverStatus(false));
                  }
                }}
              >
                {options?.map((option: SelectOption, index: number) => (
                  <IonItem
                    key={`${option?.value}-${index}`}
                    lines={index === options.length - 1 ? 'none' : undefined}
                  >
                    <IonLabel>{option?.label}</IonLabel>
                    <IonRadio slot="start" value={option?.value} />
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonPopover>
            <div
              onClick={handleClick}
              className={classNames(
                'mx-2 flex flex-row items-center transform duration-300 ease-in-out',
                {
                  'rotate-180': optionsPopoverStatus.showPopover,
                }
              )}
            >
              <IonIcon
                onClick={handleClick}
                class={classNames('text-primary text-xl p-2', { 'cursor-pointer': !disabled })}
                icon={chevronDown}
              />
              {!!postfix && postfix}
            </div>
          </div>
        )}
      />
      <p
        className={classNames(
          'opacity-0 text-sm transform duration-150 ease-in-out inline-block m-0',
          { 'opacity-100': !!error || !!hint },
          { 'text-danger': !!error },
          { 'text-gray-600 dark:text-gray-400': !!hint && !error }
        )}
      >
        {!!error ? error : !!hint ? hint : ''}
      </p>
    </div>
  );
};

export default Select;
