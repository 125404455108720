import { yupResolver } from '@hookform/resolvers/yup';
import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import Button from '../core/Button';
import Input from '../forms/Input';

type FormValues = {
  isbn: string;
};

const formSchema = yup
  .object()
  .shape({
    isbn: yup.string().required(`Please provide an ISBN (found around book's barcode).`),
  })
  .required();

interface Props {
  showModal: boolean;
  onClose: () => void;
}

const SearchBooKByISBN = ({ showModal, onClose }: Props) => {
  const {
    control,
    getValues,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    shouldFocusError: false,
    defaultValues: {
      isbn: '',
    },
    mode: 'all',
  });
  const history = useHistory();

  /**
   * Resets the form and closes the modal.
   */
  const close = (): void => {
    reset();
    onClose();
  };

  /**
   * Submit event from the form.
   * @param {FormValues} event
   */
  const onSubmit = (event: FormValues): void => {
    onClose();
    const { isbn }: FormValues = getValues();
    if (isValid && isbn) {
      history.push(`/app/library/details/${isbn}`);
      reset();
    }
  };

  return (
    <Modal
      classNames={{ modal: 'max-w-xs w-full' }}
      open={showModal}
      center
      onClose={close}
      closeIcon={
        <div>
          <IonIcon icon={closeOutline} className="text-2xl" />
        </div>
      }
    >
      <div className="pt-4 px-4">
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="ISBN"
            name="isbn"
            placeholder="ISBN"
            enterkeyhint="search"
            required
            error={errors.isbn?.message}
            control={control}
          />
          <div className="flex justify-end items-center">
            <Button text="Search" type="button" onClick={handleSubmit(onSubmit)} />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default React.memo(SearchBooKByISBN);
