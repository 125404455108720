import { IonIcon, IonItemOption, IonItemOptions } from '@ionic/react';
import { arrowRedoOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { useAvailableCopies } from '../../../hooks/useAvailableCopies';

interface Props {
  itemId: string;
  closeSlider: () => void;
}

const MyLibrarySwipeableOptionsLeft = ({ itemId, closeSlider }: Props) => {
  const { availableCopies } = useAvailableCopies(itemId);
  const history = useHistory();

  /**
   * Sends the user to the lend book screen if they have available copies to lend.
   */
  const lendBook = (): void => {
    if (availableCopies === 0) {
      alert('There are no books available to lend.');
      closeSlider();
      return;
    }
    closeSlider();
    history.push(`/app/library/lend-book/${itemId}`);
  };

  return (
    <IonItemOptions side="start">
      <IonItemOption color="tertiary" onClick={lendBook}>
        Lend
        <IonIcon slot="bottom" icon={arrowRedoOutline} className="mt-1" />
      </IonItemOption>
    </IonItemOptions>
  );
};

export default MyLibrarySwipeableOptionsLeft;
