import { yupResolver } from '@hookform/resolvers/yup';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { USERNAME_YUP } from '@libs/apps-shared/constants';
import {
  AuthContextType,
  GeneralContextType,
  useAuth,
  useGeneral,
} from '@libs/apps-shared/contexts';
import { UpdateUserDto, UserbaseError } from '@libs/apps-shared/custom-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../components/core/Button';
import Input from '../../components/forms/Input';

type FormValues = {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
};

const formSchema = yup
  .object()
  .shape({
    username: USERNAME_YUP,
    email: yup
      .string()
      .email('Please provide a valid email address.')
      .required('This field is required.'),
    firstName: yup.string(),
    lastName: yup.string(),
  })
  .required();

const Profile = () => {
  const { setLoading }: GeneralContextType = useGeneral();
  const { currentUser, updateUser }: AuthContextType = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    shouldFocusError: false,
    defaultValues: {
      username: currentUser?.username || '',
      email: currentUser?.email || '',
      firstName: currentUser?.profile?.firstName || '',
      lastName: currentUser?.profile?.lastName || '',
    },
    mode: 'all',
  });
  const [updateProfileError, setUpdateProfileError] = useState<string>();

  /**
   * On submit, updates the users profile.
   * @param {FormValues} formValues
   */
  const onSubmit = async ({ firstName, lastName, ...formValues }: FormValues): Promise<void> => {
    setLoading(true);
    const newUser: UpdateUserDto = Object.assign(
      {
        ...(firstName || lastName
          ? {
              profile: {
                showInstallApp:
                  (currentUser?.profile?.showInstallApp as 'true' | 'false' | undefined) || 'true',
                ...(firstName ? { firstName } : {}),
                ...(lastName ? { lastName } : {}),
              },
            }
          : {
              profile: {
                showInstallApp:
                  (currentUser?.profile?.showInstallApp as 'true' | 'false' | undefined) || 'true',
              },
            }),
      },
      formValues
    );
    const response: undefined | UserbaseError = await updateUser(newUser);
    if (!!response) {
      setUpdateProfileError(response.message);
    } else {
      setUpdateProfileError(undefined);
    }
    setLoading(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/app/settings" />
          </IonButtons>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="p-8">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Input
              label="Username"
              name="username"
              placeholder="Username"
              autocomplete="username"
              enterkeyhint="next"
              required={true}
              error={errors.username?.message}
              control={control}
            />
            <Input
              label="Email"
              name="email"
              placeholder="Email"
              type="email"
              autocomplete="email"
              enterkeyhint="next"
              required={true}
              error={errors.email?.message}
              control={control}
            />
            <Input
              label="First Name"
              name="firstName"
              placeholder="First Name"
              autocomplete="name"
              enterkeyhint="next"
              error={errors.firstName?.message}
              control={control}
            />
            <Input
              label="Last Name"
              name="lastName"
              placeholder="Last Name"
              autocomplete="name"
              enterkeyhint="enter"
              error={errors.lastName?.message}
              control={control}
            />
          </form>
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar className="px-2">
          {!!updateProfileError && (
            <p className="text-danger text-center mb-2">{updateProfileError}</p>
          )}
          <Button text="Save" color="success" expand="block" onClick={handleSubmit(onSubmit)} />
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Profile;
