import { Browser } from '@capacitor/browser';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import {
  AuthContextType,
  GeneralContextType,
  useAuth,
  useGeneral,
} from '@libs/apps-shared/contexts';
import { SignUpDto, UserbaseError } from '@libs/apps-shared/custom-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { USERNAME_YUP } from '../../../../../libs/apps-shared/src/lib/constants';
import Button from '../../components/core/Button';
import Link from '../../components/core/Link';
import Input from '../../components/forms/Input';
import RadioGroup from '../../components/forms/RadioGroup';
import LogoHeader from '../../components/layout/LogoHeader';
import UsePasswordManager from '../../components/UsePasswordManager';
import { environment } from '../../environments/environment';
import { useQueryParams } from '../../hooks/useQueryParams';

const MONTHLY_PLAN_ID = environment.production
  ? 'price_1LUgkOCoLr3qMb0HoX4HjUza'
  : 'price_1LUdx6CoLr3qMb0HD8TUvVIJ';
const ANNUAL_PLAN_ID = environment.production
  ? 'price_1LUgkBCoLr3qMb0HFdAUbdbl'
  : 'price_1LUdweCoLr3qMb0HqPRSax7q';

type FormValues = {
  username: string;
  password: string;
  confirmPassword: string;
  email: string;
  subscriptionPlanId: string;
};

const formSchema = yup
  .object()
  .shape({
    username: USERNAME_YUP,
    password: yup.string().required('This field is required.'),
    confirmPassword: yup
      .string()
      .required('This field is required.')
      .oneOf([yup.ref('password'), ''], `Passwords don't match.`),
    email: yup
      .string()
      .email('Please provide a valid email address.')
      .required('This field is required.'),
    subscriptionPlanId: yup.string().required('This field is required.'),
  })
  .required();

const SignUp = () => {
  const { setLoading }: GeneralContextType = useGeneral();
  const {
    signUp,
    purchaseSubscription,
    purchaseMobileSubscription,
    subscriptionOptions,
  }: AuthContextType = useAuth();
  const query = useQueryParams();
  const [isMobileApp] = useState<boolean>(!isPlatform('desktop') && !isPlatform('mobileweb'));
  const plan = query.get('plan');
  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    shouldFocusError: false,
    defaultValues: {
      username: '',
      password: '',
      confirmPassword: '',
      email: '',
      subscriptionPlanId: !isMobileApp
        ? plan === 'trial'
          ? 'free_trial'
          : plan === 'monthly'
          ? MONTHLY_PLAN_ID
          : ANNUAL_PLAN_ID
        : '',
    },
  });
  const [signUpError, setSignUpError] = useState<string>();

  const openUrl = (url: string) => {
    Browser.open({
      url,
      windowName: '_blank',
    });
  };

  /**
   * On submit, sign the user in.
   * @param {FormValues} formValues
   */
  const onSubmit = async (
    { confirmPassword, subscriptionPlanId, ...formValues }: FormValues,
    event?: React.BaseSyntheticEvent
  ) => {
    event?.preventDefault();
    setLoading(true);
    let newUser: SignUpDto = Object.assign(
      {
        profile: {
          showInstallApp: (isMobileApp ? 'false' : 'true') as 'true' | 'false',
        },
      },
      formValues
    );
    const response: undefined | UserbaseError = await signUp(newUser);
    // Means there was an error... don't like this.
    if (!!response) {
      setLoading(false);
      setSignUpError(response.message);
      return;
    }
    if (subscriptionPlanId !== 'free_trial') {
      if (isMobileApp) {
        await purchaseMobileSubscription(subscriptionPlanId);
        setLoading(false);
      } else {
        try {
          await purchaseSubscription(
            subscriptionPlanId,
            `${environment.PUBLIC_APP_URL}/app/library`,
            `${environment.PUBLIC_APP_URL}/app/library`
          );
        } catch (error) {
          const err: UserbaseError = error as UserbaseError;
          alert(err.message);
        } finally {
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { subscriptionPlanId } = getValues();
    if (!plan && !subscriptionPlanId && subscriptionOptions.length > 0) {
      setValue('subscriptionPlanId', subscriptionOptions[0].value);
    }
  }, [plan, subscriptionOptions]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="pl-2">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/sign-in" text="Sign In" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-lg w-full mx-auto py-4 sm:py-12 px-4 sm:px-6 lg:px-8 safe-area">
          <LogoHeader />
          <div className="sm:rounded-md sm:shadow sm:border mt-8 px-4 sm:p-8">
            <h2 className="mb-6 text-center text-2xl sm:text-3xl font-extrabold text-gray-900 dark:text-white">
              Sign up for an account
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                label="Username"
                name="username"
                placeholder="Username"
                autocomplete="username"
                enterkeyhint="next"
                required={true}
                error={errors.username?.message}
                control={control}
              />
              <Input
                label="Password"
                name="password"
                placeholder="Password"
                type="password"
                togglePasswordVisibility={true}
                autocomplete="current-password"
                enterkeyhint="next"
                required={true}
                error={errors.password?.message}
                control={control}
              />
              <Input
                label="Confirm Password"
                name="confirmPassword"
                placeholder="Confirm Password"
                type="password"
                togglePasswordVisibility={true}
                autocomplete="current-password"
                enterkeyhint="next"
                required={true}
                error={errors.confirmPassword?.message}
                control={control}
              />
              <Input
                label="Email"
                name="email"
                placeholder="Email"
                type="email"
                autocomplete="email"
                enterkeyhint="next"
                required={true}
                error={errors.email?.message}
                control={control}
              />
              <RadioGroup
                options={subscriptionOptions}
                label="Subscription"
                note={
                  isMobileApp ? undefined : (
                    <>
                      Have a <span className="font-extrabold">promo code</span>? Claim it at
                      checkout once you click "Sign Up" below!
                    </>
                  )
                }
                name="subscriptionPlanId"
                control={control}
              />
              <p className="my-4">
                By signing up, you agree to our{' '}
                <a
                  className="text-primary cursor-pointer"
                  onClick={() => openUrl(`${environment.PUBLIC_WEBSITE_URL}/privacy-policy`)}
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  className="text-primary cursor-pointer"
                  onClick={() => openUrl(`${environment.PUBLIC_WEBSITE_URL}/terms-and-conditions`)}
                >
                  Terms and Conditions
                </a>
                .
              </p>
              <UsePasswordManager />
              {!!signUpError && <p className="text-danger text-center mt-2">{signUpError}</p>}
              <Button
                id="sign-up-btn"
                text="Sign Up"
                expand="block"
                className="mt-4"
                type="submit"
              />
              <div className="flex justify-center items-center">
                <Link
                  text="Back to Sign In"
                  routerLink="/sign-in"
                  routerDirection="back"
                  className="mt-4"
                />
              </div>
            </form>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignUp;
