export enum ScanningState {
  Closed = 0,
  Scanning = 1,
}

export enum SupportedFormats {
  QRCode = 0,
  Barcode = 1,
}

export type ScanningResult = string | undefined;
