import { IonIcon } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';

const UsePasswordManager = () => {
  return (
    <div className="bg-gray-200 dark:bg-gray-400 p-4 flex align-top text-gray-900">
      <IonIcon icon={informationCircleOutline} className="mt-1 mr-2 w-1/12" />{' '}
      <p className="w-11/12 text-gray-900">
        Save your password in a password manager or other secure location. With end-to-end
        encryption, if you forget your password and lose the device you signed up with, your account
        may not be recoverable.
      </p>
    </div>
  );
};

export default UsePasswordManager;
