import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonAlert,
} from '@ionic/react';
import { useAuth, useGeneral } from '@libs/apps-shared/contexts';
import { UserbaseError } from '@libs/apps-shared/custom-types';
import classNames from 'classnames';
import { cardOutline } from 'ionicons/icons';
import { ReactNode, useEffect, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import Button from '../../components/core/Button';
import RadioGroup, { RadioGroupOption } from '../../components/forms/RadioGroup';
import { environment } from '../../environments/environment';
import { getReadableDate } from '../../utils/dates';

interface MainTextProps {
  children: ReactNode;
  id?: string;
}
const MainText = ({ children, id }: MainTextProps) => (
  <p id={id} className="text-lg font-medium mb-2">
    {children}
  </p>
);

interface SubTextProps {
  className?: string;
  children: ReactNode;
}
const SubText = ({ children, className }: SubTextProps) => (
  <p
    className={classNames('text-base font-medium text-gray-600 dark:text-gray-400 mb-2', {
      className,
    })}
  >
    {children}
  </p>
);

interface UpdatePaymentMethodButtonProps {
  updatePayment: () => void;
}
const UpdatePaymentMethodButton = ({ updatePayment }: UpdatePaymentMethodButtonProps) => (
  <Button
    id="update-payment-method-btn"
    text="Update Payment Method"
    color="primary"
    onClick={updatePayment}
  />
);

interface RestorePaymentSectionProps {
  isMobile: boolean;
  onRestorePurchase: () => void;
}
const RestorePaymentSection = ({ isMobile, onRestorePurchase }: RestorePaymentSectionProps) => {
  if (!isMobile) {
    return <></>;
  }
  return (
    <>
      <MainText>Restore Purchase</MainText>
      <SubText>
        Do you think you have an active subscription that isn't being reflected in the app? If so,
        you can attempt to restore your purchase.
      </SubText>
      <Button text="Restore Purchase" color="warning" onClick={onRestorePurchase} />
    </>
  );
};

interface Props {
  purchaseSub: () => void;
  subscriptionOptions: RadioGroupOption[];
  control: Control<FieldValues>;
}
const PurchaseSubscription = ({ purchaseSub, subscriptionOptions, control }: Props) => (
  <>
    {/* @ts-ignore */}
    <RadioGroup
      options={subscriptionOptions.filter(
        (option: RadioGroupOption) => option.value !== 'free_trial'
      )}
      label="Subscription"
      name="subscriptionPlanId"
      control={control}
    />
    <Button
      id="purchase-sub-btn"
      text="Purchase Subscription"
      color="success"
      onClick={purchaseSub}
      className="mt-4"
    />
  </>
);

interface FieldValues {
  subscriptionPlanId: string;
}

const ManageSubscription = () => {
  const {
    currentUser,
    subscriptionInfo,
    purchaseSubscription,
    cancelSubscription,
    resumeSubscription,
    updatePaymentMethod,
    purchaseMobileSubscription,
    restoreMobileSubscription,
    subscriptionOptions,
  } = useAuth();
  const { setLoading } = useGeneral();
  const [presentConfirmModal] = useIonAlert();
  const [isMobileApp] = useState<boolean>(!isPlatform('desktop') && !isPlatform('mobileweb'));
  const [error, setError] = useState<string | undefined>();
  const { control, getValues, setValue } = useForm<FieldValues>({
    defaultValues: {
      subscriptionPlanId:
        subscriptionInfo?.subscriptionStatuses?.isActive && subscriptionInfo.subscriptionDetails
          ? subscriptionInfo.subscriptionDetails.subscriptionPlanId
          : '',
    },
  });

  /**
   * Purchases a subscription.
   */
  const purchaseSub = async () => {
    const subscriptionPlanId: string = getValues().subscriptionPlanId;
    setLoading(true);
    if (isMobileApp) {
      purchaseMobileSubscription(subscriptionPlanId).finally(() => setLoading(false));
    } else {
      purchaseSubscription(subscriptionPlanId)
        .then(() => setError(undefined))
        .catch((err: UserbaseError) => {
          setError(err.message);
        })
        .finally(() => setLoading(false));
    }
  };

  /**
   * Cancels a subscription.
   */
  const cancelSub = () => {
    presentConfirmModal({
      header: 'Confirm Cancel Subscription',
      message:
        'Are you sure you want to cancel your subscription? This is not a refund and you will lose access to the app at the end of this billing period.',
      buttons: [
        {
          text: 'No',
          handler: () => {},
        },
        {
          text: 'Yes, Cancel',
          handler: () => {
            setLoading(true);
            cancelSubscription()
              .then(() => setError(undefined))
              .catch((err: UserbaseError) => {
                setError(err.message);
              })
              .finally(() => setLoading(false));
          },
        },
      ],
    });
  };

  /**
   * Resumes a subscription.
   */
  const resumeSub = () => {
    if (subscriptionInfo?.subscribedFromCurrentPlatform) {
      setLoading(true);
      if (isMobileApp) {
        purchaseMobileSubscription(subscriptionInfo.subscriptionDetails.subscriptionPlanId).finally(
          () => setLoading(false)
        );
      } else {
        resumeSubscription()
          .then(() => setError(undefined))
          .catch((err: UserbaseError) => {
            setError(err.message);
          })
          .finally(() => setLoading(false));
      }
    }
  };

  /**
   * Restores any purchased subscriptions. This can happen if a user logs out and their subscription renews.
   */
  const restoreSub = () => {
    if (!isMobileApp) console.warn('Restore purchase is only available on mobile.');
    else {
      setLoading(true);
      restoreMobileSubscription().finally(() => setLoading(false));
    }
  };

  /**
   * Updates the payment method.
   */
  const updatePayment = () => {
    if (subscriptionInfo) {
      if (subscriptionInfo.subscribedFromCurrentPlatform) {
        if (subscriptionInfo.platform === 'web') {
          setLoading(true);
          updatePaymentMethod()
            .then(() => setError(undefined))
            .catch((err: UserbaseError) => {
              setError(err.message);
            })
            .finally(() => setLoading(false));
        } else {
          window.open(subscriptionInfo.managementUrl, '_system', 'location=yes');
        }
      } else {
        console.error("Can't update subscription info from a device you didn't subscribe from.");
      }
    }
  };

  useEffect(() => {
    const { subscriptionPlanId } = getValues();
    if (!subscriptionPlanId && subscriptionOptions.length > 0) {
      setValue('subscriptionPlanId', subscriptionOptions[0].value);
    }
  }, [subscriptionOptions]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/app/settings" />
          </IonButtons>
          <IonTitle id="manage-subscription-title">Manage Subscription</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="p-8">
          <h1 className="mt-0">
            <span className="flex items-center">
              <IonIcon icon={cardOutline} className="mr-2" />
              Current Subscription
            </span>
          </h1>
          {subscriptionInfo && (
            <>
              {!!subscriptionInfo.subscriptionDetails && !!subscriptionInfo.subscriptionStatuses ? (
                <>
                  {/* START - IS ACTIVE SECTION */}
                  {subscriptionInfo.subscriptionStatuses.isActive && (
                    <>
                      <MainText id="main-text-active-sub">
                        You are currently subscribed to the{' '}
                        <b>{subscriptionInfo.subscriptionDetails.frequency}</b> plan.
                      </MainText>
                      {/* START - WEB - OFFER CANCEL */}
                      {!isMobileApp && subscriptionInfo.platform === 'web' && (
                        <>
                          <UpdatePaymentMethodButton updatePayment={updatePayment} />
                          <div className="mt-4">
                            <MainText>Cancel Subscription</MainText>
                            <SubText>
                              You will be able to access the app and resume your subscription until
                              the end of the current billing period.
                            </SubText>
                            <Button
                              id="cancel-sub-button"
                              text="Cancel Subscription"
                              color="danger"
                              onClick={cancelSub}
                            />
                          </div>
                        </>
                      )}
                      {/* END - WEB - OFFER CANCEL */}

                      {isMobileApp && (
                        <div className="mt-4">
                          {/* START - MOBILE IOS - OFFER CHANGE AND CANCEL */}
                          {subscriptionInfo.platform === 'ios' && isPlatform('ios') && (
                            <>
                              <UpdatePaymentMethodButton updatePayment={updatePayment} />
                              <SubText className="mt-4">
                                You can change or cancel your subscription from your Apple iTunes
                                account.
                              </SubText>
                              {subscriptionInfo.managementUrl && (
                                <Button
                                  text="Manage Subscription"
                                  color="primary"
                                  onClick={() =>
                                    window.open(
                                      subscriptionInfo.managementUrl,
                                      '_system',
                                      'location=yes'
                                    )
                                  }
                                  id="manage-sub-btn"
                                />
                              )}
                            </>
                          )}
                          {/* END - MOBILE IOS - OFFER CHANGE AND CANCEL */}

                          {/* TODO - ANDROID - ADD CHANGE SUBSCRIPTION */}
                          {/* START - MOBILE ANDROID - OFFER CANCEL */}
                          {subscriptionInfo.platform === 'android' && isPlatform('android') && (
                            <>
                              <UpdatePaymentMethodButton updatePayment={updatePayment} />
                              <SubText className="mt-4">
                                You can cancel your subscription from your Google Play account.
                              </SubText>
                              {subscriptionInfo.managementUrl && (
                                <Button
                                  text="Manage Subscription"
                                  color="primary"
                                  onClick={() =>
                                    window.open(
                                      subscriptionInfo.managementUrl,
                                      '_system',
                                      'location=yes'
                                    )
                                  }
                                  id="manage-sub-btn"
                                />
                              )}
                            </>
                          )}
                          {/* END - MOBILE ANDROID - OFFER CANCEL */}
                        </div>
                      )}
                    </>
                  )}
                  {/* END - IS ACTIVE SECTION */}

                  {/* START - SET TO CANCEL */}
                  {subscriptionInfo.subscriptionStatuses.setToCancel &&
                    subscriptionInfo.subscriptionStatuses.cancelSubscriptionAt &&
                    !subscriptionInfo.subscriptionStatuses.troubleWithPayment && (
                      <>
                        <MainText id="main-text-will-expire-sub">
                          Your <b>{subscriptionInfo.subscriptionDetails.frequency}</b> subscription
                          will expire on{' '}
                          <b>
                            {getReadableDate(
                              subscriptionInfo.subscriptionStatuses.cancelSubscriptionAt
                            )}
                            .
                          </b>
                        </MainText>
                        {subscriptionInfo.subscribedFromCurrentPlatform ? (
                          <>
                            <SubText>
                              You can resume your subscription at any time until then.
                            </SubText>
                            <Button
                              id="resume-sub-btn"
                              text="Resume Subscription"
                              color="success"
                              onClick={resumeSub}
                            />
                          </>
                        ) : (
                          <SubText>
                            You can resume your subscription on the{' '}
                            {subscriptionInfo.platform === 'web'
                              ? 'web'
                              : subscriptionInfo.platform === 'ios'
                              ? 'iOS device'
                              : 'Android device'}{' '}
                            where you purchased your subscription.
                          </SubText>
                        )}
                      </>
                    )}
                  {/* END - SET TO CANCEL */}

                  {/* START - TROUBLE WITH PAYMENT */}
                  {subscriptionInfo.subscriptionStatuses.troubleWithPayment && (
                    <>
                      <MainText>
                        There was an issue processing the payment for your{' '}
                        <b>{subscriptionInfo.subscriptionDetails.frequency}</b> subscription.
                      </MainText>
                      {subscriptionInfo.subscribedFromCurrentPlatform ? (
                        <>
                          <SubText>Update your payment method to fix the issue.</SubText>
                          <UpdatePaymentMethodButton updatePayment={updatePayment} />
                        </>
                      ) : (
                        <SubText>
                          Update your payment method to fix the issue. You purchased your
                          subscription on{' '}
                          <strong>
                            {subscriptionInfo.platform === 'web'
                              ? 'the web'
                              : subscriptionInfo.platform === 'ios'
                              ? 'an iOS device'
                              : 'an Android device'}
                          </strong>{' '}
                          and can only manage your subscription from there.
                        </SubText>
                      )}
                    </>
                  )}

                  {/* END - TROUBLE WITH PAYMENT */}

                  {/* START - IS CANCELLED */}
                  {subscriptionInfo.subscriptionStatuses.isCancelled && (
                    <>
                      <MainText id="main-text-sub-cancelled">
                        Your subscription to the{' '}
                        <b>{subscriptionInfo.subscriptionDetails.frequency}</b> plan was cancelled.
                      </MainText>
                      <SubText>
                        To resume having full access to the app, purchase a new subscription.
                      </SubText>
                      <PurchaseSubscription
                        purchaseSub={purchaseSub}
                        subscriptionOptions={subscriptionOptions}
                        control={control}
                      />
                    </>
                  )}
                  {/* END - IS CANCELLED */}
                </>
              ) : subscriptionInfo.trialDetails.isInTrial ? (
                <>
                  <MainText id="main-text-free-trial">
                    You currently have{' '}
                    <b>
                      {subscriptionInfo.trialDetails.timeLeft}{' '}
                      {subscriptionInfo.trialDetails.timeType}
                    </b>{' '}
                    of your <b>free trial</b> left. Once your free trial expires, you will have
                    limited access to the app.
                  </MainText>
                  <SubText>
                    To continue having full access, purchase a monthly or yearly subscription.
                  </SubText>
                  <PurchaseSubscription
                    purchaseSub={purchaseSub}
                    subscriptionOptions={subscriptionOptions}
                    control={control}
                  />
                  <RestorePaymentSection isMobile={isMobileApp} onRestorePurchase={restoreSub} />
                </>
              ) : (
                <>
                  <MainText id="main-text-free-trial-ended">
                    Your <b>free trial</b> has <b>ended</b>.
                  </MainText>
                  <SubText>Purchase a subscription to continue using the app.</SubText>
                  <PurchaseSubscription
                    purchaseSub={purchaseSub}
                    subscriptionOptions={subscriptionOptions}
                    control={control}
                  />
                  <RestorePaymentSection isMobile={isMobileApp} onRestorePurchase={restoreSub} />
                </>
              )}
              {/* Subscription was purchase on a different device. */}
              {subscriptionInfo.subscriptionStatuses?.isActive &&
                !subscriptionInfo.subscriptionStatuses?.setToCancel &&
                !subscriptionInfo.subscribedFromCurrentPlatform &&
                !subscriptionInfo.subscriptionStatuses.troubleWithPayment && (
                  <div className="mt-4">
                    <MainText>Managing Your Subscription</MainText>
                    <SubText>
                      You purchased your subscription on{' '}
                      <strong>
                        {subscriptionInfo.platform === 'web'
                          ? 'the web'
                          : subscriptionInfo.platform === 'ios'
                          ? 'an iOS device'
                          : 'an Android device'}
                      </strong>{' '}
                      and can only manage your subscription from there.
                    </SubText>
                    {/* Subscription is web but they're on mobile, direct them to web to manage. */}
                    {isMobileApp &&
                      subscriptionInfo.platform !== 'ios' &&
                      subscriptionInfo.platform !== 'android' && (
                        <Button
                          text="Manage Subscription"
                          color="primary"
                          onClick={() =>
                            window.open(
                              `${environment.PUBLIC_APP_URL}/sign-in?manageSubscription=true${
                                !!currentUser ? `&username=${currentUser!.username}` : ''
                              }`,
                              '_system',
                              'location=yes'
                            )
                          }
                          id="manage-sub-btn"
                        />
                      )}
                  </div>
                )}
            </>
          )}
          <p className="text-danger mt-4">{error}</p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ManageSubscription;
