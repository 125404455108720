import { format, formatISO, parseISO } from 'date-fns';

/**
 * Used to sort dates, returns a number indicating which is larger.
 * @param {string | Date} date1
 * @param {stirng | Date} date2
 * @return {number}
 */
export const compareDates = (date1: string | Date, date2: string | Date): number => {
  const d1: string = getISODate(date1);
  const d2: string = getISODate(date2);
  return d1 < d2 ? -1 : d1 > d2 ? 1 : 0;
};

/**
 * Given a date string, returns it as MM DD, YYYY.
 * @param {string | Date} date
 * @returns {string}
 */
export const getReadableDate = (date: string | Date): string => {
  return format(parseISO(getISODate(date)), 'MMMM d, yyyy');
};

/**
 * Given a date string, returns it as an ISO date.
 * @param {string | Date} date
 * @returns string
 */
export const getISODate = (date: string | Date): string => {
  if (typeof date === 'string') {
    // If the date isn't in ISO, then we need to convert it.
    if (!date.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}?)((-(\d{2}):(\d{2})|Z)?)$/)) {
      return formatISO(new Date(date));
    }
  } else if (typeof date === 'object') {
    return formatISO(date);
  }
  return date;
};
