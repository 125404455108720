import { yupResolver } from '@hookform/resolvers/yup';
import { IonContent, IonIcon, IonPage } from '@ionic/react';
import {
  AuthContextType,
  GeneralContextType,
  useAuth,
  useGeneral,
} from '@libs/apps-shared/contexts';
import { UserbaseError } from '@libs/apps-shared/custom-types';
import { cardOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../components/core/Button';
import Link from '../../components/core/Link';
import Checkbox from '../../components/forms/Checkbox';
import Input from '../../components/forms/Input';
import LogoHeader from '../../components/layout/LogoHeader';
import { useQueryParams } from '../../hooks/useQueryParams';

type FormValues = {
  username: string;
  password: string;
  rememberMe: boolean;
};

const formSchema = yup
  .object()
  .shape({
    username: yup.string().required('This field is required.'),
    password: yup.string().required('This field is required.'),
    rememberMe: yup.boolean(),
  })
  .required();

const SignIn = () => {
  const { setLoading, setRedirectUrl }: GeneralContextType = useGeneral();
  const { lastUsedUsername, signIn }: AuthContextType = useAuth();
  const query = useQueryParams();
  const username: string | null = query.get('username');
  const manageSubscription: string | null = query.get('manageSubscription');
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    shouldFocusError: false,
    defaultValues: {
      username: username ? username : lastUsedUsername || '',
      password: '',
      rememberMe: false,
    },
  });
  const [signInError, setSignInError] = useState<string>();

  /**
   * On submit, sign the user in.
   * @param {FormValues} formValues
   */
  const onSubmit = async ({ rememberMe, ...rest }: FormValues): Promise<void> => {
    setLoading(true);
    const response: undefined | UserbaseError = await signIn({
      rememberMe: rememberMe ? 'local' : 'session',
      ...rest,
    });
    if (!!response) {
      setSignInError(response.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    const sub = watch((values: Partial<FormValues>) => {
      if (lastUsedUsername && !values.username && !dirtyFields.username) {
        setValue('username', lastUsedUsername);
      }
    });
    return () => sub.unsubscribe();
  }, [lastUsedUsername, dirtyFields, watch]);

  useEffect(() => {
    if (manageSubscription === 'true') {
      setRedirectUrl('/app/settings/manage-subscription');
    }
  }, [manageSubscription]);

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="max-w-lg mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <LogoHeader />
          <div className="sm:rounded-md sm:shadow sm:border mt-8 px-4 sm:p-8">
            <h2
              id="sign-in-header"
              className="text-center text-2xl sm:text-3xl font-extrabold text-gray-900 dark:text-white"
            >
              {manageSubscription ? <IonIcon icon={cardOutline} className="mr-2" /> : ''}
              Sign in to {manageSubscription ? 'manage your subscription' : 'your account'}
            </h2>
            {!manageSubscription && (
              <p className="mt-2 mb-6 font-normal text-center text-sm text-gray-600 dark:text-white">
                Or{' '}
                <Link
                  text="sign up for an account"
                  routerLink="/sign-up"
                  routerDirection="forward"
                  className="font-medium text-primary hover:text-primary"
                />
              </p>
            )}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Input
                label="Username"
                name="username"
                placeholder="Username"
                autocomplete="username"
                enterkeyhint="next"
                required={true}
                error={errors.username?.message}
                control={control}
              />
              <Input
                label="Password"
                name="password"
                placeholder="Password"
                type="password"
                togglePasswordVisibility={true}
                autocomplete="current-password"
                enterkeyhint="enter"
                required={true}
                error={errors.password?.message}
                control={control}
              />
              <Checkbox
                label="Remember me?"
                name="rememberMe"
                error={errors.rememberMe?.message}
                control={control}
                className="mt-4"
              />
              {!!signInError && <p className="text-danger text-center mt-4">{signInError}</p>}
              <Button
                id="sign-in-btn"
                type="button"
                text="Sign In"
                expand="block"
                className="mt-2"
                onClick={handleSubmit(onSubmit)}
              />
              <div className="flex justify-center items-center">
                <Link
                  text="Forgot Password?"
                  routerLink="/forgot-password"
                  routerDirection="forward"
                  className="mt-4"
                />
              </div>
            </form>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignIn;
