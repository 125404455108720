import { Components } from '@ionic/core';
import { IonRouterLink } from '@ionic/react';
import classNames from 'classnames';
import React from 'react';

type Props = {
  text: string;
  color?: Components.IonRouterLink['color'];
  routerDirection?: Components.IonRouterLink['routerDirection'];
  className?: string;
} & (
  | {
      routerLink: string;
      href?: never;
      target?: never;
    }
  | {
      routerLink?: never;
      href: Components.IonRouterLink['href'];
      target: Components.IonRouterLink['target'];
    }
);

const Link = ({
  text,
  color = 'secondary',
  routerLink,
  href,
  routerDirection,
  target,
  className,
}: Props) => {
  return (
    <IonRouterLink
      color={color}
      routerLink={routerLink}
      href={href}
      routerDirection={routerDirection}
      target={target}
      className={classNames(className, 'cursor-pointer inline-block font-bold')}
    >
      {text}
    </IonRouterLink>
  );
};

export default Link;
