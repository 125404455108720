import { Components } from '@ionic/core';
import { IonButton, IonIcon, IonItem, IonList, IonListHeader, IonPopover } from '@ionic/react';
import { useAuth } from '@libs/apps-shared/contexts';
import { filterCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Checkbox from '../forms/Checkbox';

interface FormValues {
  [key: string]: boolean;
}

const useUpdateDefaultValues = (filterOptions: FilterOption[]) => {
  let defaultValues: FormValues = {};
  for (const filterOption of filterOptions) {
    defaultValues[filterOption.key] = filterOption.initiallySelected || false;
  }
  return defaultValues;
};

export interface FilterOption {
  key: string;
  label: string;
  initiallySelected?: boolean;
  color?: Components.IonCheckbox['color'];
}

type Props = {
  filterOptions: FilterOption[];
  activeFilters: string[];
  setActiveFilters: (filterOptions: string[]) => void;
};

const FilterPopover = ({ filterOptions, activeFilters, setActiveFilters }: Props) => {
  const { subscriptionInfo } = useAuth();
  const [filterPopoverStatus, setFilterPopoverStatus] = useState<{
    showPopover: boolean;
    event: any;
  }>({ showPopover: false, event: undefined });
  const defaultValues = useUpdateDefaultValues(filterOptions);
  const { control, watch } = useForm({
    shouldFocusError: false,
    defaultValues: defaultValues,
  });

  useEffect(() => {
    const sub = watch((values: Partial<FormValues>) => {
      setActiveFilters(Object.keys(values).filter((key: string) => !!values[key]));
    });
    return () => sub.unsubscribe();
  }, [watch]);

  return (
    <>
      <IonButton
        onClick={(event: React.MouseEvent<HTMLIonButtonElement>) =>
          setFilterPopoverStatus({
            showPopover: !filterPopoverStatus.showPopover,
            event: event.nativeEvent,
          })
        }
        disabled={subscriptionInfo?.disableAppUse}
      >
        <IonIcon icon={filterCircleOutline} />
      </IonButton>
      <IonPopover
        event={filterPopoverStatus.event}
        isOpen={filterPopoverStatus.showPopover}
        onDidDismiss={() => setFilterPopoverStatus({ showPopover: false, event: undefined })}
      >
        <IonList>
          <IonListHeader>Filter Options</IonListHeader>
          {filterOptions.map(({ key, label, color }: FilterOption, index: number) => (
            <IonItem
              key={key}
              detail={false}
              lines={index === filterOptions.length - 1 ? 'none' : undefined}
            >
              <Checkbox
                name={key}
                label={label}
                color={color}
                disabled={activeFilters.length === 1 && activeFilters[0] === key}
                control={control}
              />
            </IonItem>
          ))}
        </IonList>
      </IonPopover>
    </>
  );
};

export default FilterPopover;
