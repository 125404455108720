import * as yup from 'yup';

export const USERNAME_REGEX: RegExp = /^[a-zA-Z0-9\_\.\-]{3,25}$/;
export const USERNAME_YUP = yup
  .string()
  .test(
    'username-min-3',
    'Username must be at least 3 characters.',
    (username: string | undefined, context: yup.TestContext<Record<string, any>>) => {
      return !!username && username.length >= 3;
    }
  )
  .test(
    'username-max-25',
    'Username must be shorter than 25 characters.',
    (username: string | undefined, context: yup.TestContext<Record<string, any>>) => {
      return !!username && username.length <= 25;
    }
  )
  .test(
    'username-regex',
    'Only letters, numbers, ".", "-", and "_" are allowed.',
    (username: string | undefined, context: yup.TestContext<Record<string, any>>) => {
      return !!username && !!username.match(USERNAME_REGEX);
    }
  )
  .required('This field is required.');
