import { IonItem, IonLabel, IonList, IonSkeletonText } from '@ionic/react';
import BookImgSkeleton from '../books/BookImgSkeleton';

interface Props {
  type: 'activity' | 'library' | 'library-selectable';
}

const SkeletonList = ({ type }: Props) => {
  const arr: number[] = Array.from({ length: 10 }, (_, i) => i + 1);
  return (
    <IonList>
      {arr.map((item: number) => (
        <IonItem key={`skeleton-list-item-${item}`} lines="full">
          <BookImgSkeleton target="thumbnail" />
          <IonLabel className="py-2">
            <IonSkeletonText animated className="w-2/5 sm:w-1/5" />
            <IonSkeletonText animated className="w-3/4 sm:w-1/3" />
            {type === 'activity' && <IonSkeletonText animated className="w-1/2" />}
          </IonLabel>
          {type === 'library-selectable' && <IonSkeletonText animated className="w-4 h-4" />}
        </IonItem>
      ))}
    </IonList>
  );
};

export default SkeletonList;
