import { IonIcon, isPlatform } from '@ionic/react';
import { ScanningContextType, useScanning } from '@libs/apps-shared/contexts';
import { BorrowerInfoForm, ScanningResult, SupportedFormats } from '@libs/apps-shared/custom-types';
import { qrCode } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import Button from '../../core/Button';
import BorrowerForm from './BorrowerForm';

interface Props {
  maxNumberOfCopies: number;
  borrowerInfoChanged: (borrowerInfo: BorrowerInfoForm) => void;
}

const ChooseUser = ({ maxNumberOfCopies, borrowerInfoChanged }: Props) => {
  const { scan, prepare }: ScanningContextType = useScanning();
  const [username, setUsername] = useState<string>();
  const [isMobileApp] = useState<boolean>(!isPlatform('desktop') && !isPlatform('mobileweb'));

  const handleScan = async (): Promise<void> => {
    try {
      const result: ScanningResult = await scan(SupportedFormats.QRCode);
      if (!!result) setUsername(result);
    } catch (error) {
      const err: { name: string } = error as { name: string };
      if (err.name === 'CAMERA_ACCESS_DENIED') {
        alert('access denied to camera');
      }
    }
  };

  useEffect(() => {
    if (isMobileApp) {
      prepare().then();
    }
  }, []);

  return (
    <div>
      <Button
        expand="block"
        className="mb-4"
        text={
          <div className="flex items-center">
            <IonIcon icon={qrCode} className="mr-2" />
            <span>Scan User's QRCode</span>
          </div>
        }
        type="button"
        onClick={handleScan}
      />
      <BorrowerForm
        username={username}
        showUsername
        maxNumberOfCopies={maxNumberOfCopies}
        borrowerInfoChanged={borrowerInfoChanged}
        markAsStillBeingBorrowed={() => {}}
      />
    </div>
  );
};

export default ChooseUser;
