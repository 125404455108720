import { IonSkeletonText, IonThumbnail } from '@ionic/react';
import classNames from 'classnames';

interface Props {
  className?: string;
  target: 'smallThumbnail' | 'thumbnail' | 'large';
}

export const BookImgSkeleton = ({ className, target }: Props) => {
  return (
    <IonThumbnail
      slot="start"
      className={classNames(className, {
        'w-12 h-18': target === 'smallThumbnail' || target === 'thumbnail',
        'w-[200px] h-[307px]': target !== 'smallThumbnail' && target !== 'thumbnail',
      })}
    >
      <IonSkeletonText
        animated
        className={classNames(className, {
          'w-12 h-18': target === 'smallThumbnail' || target === 'thumbnail',
          'w-[200px] h-[307px]': target !== 'smallThumbnail' && target !== 'thumbnail',
        })}
      />
    </IonThumbnail>
  );
};

export default BookImgSkeleton;
