import { yupResolver } from '@hookform/resolvers/yup';
import {
  IonIcon,
  IonLabel,
  IonRange,
  IonSkeletonText,
  PickerColumnOption,
  useIonAlert,
  useIonPicker,
} from '@ionic/react';
import {
  GeneralContextType,
  MyLibraryContextType,
  useGeneral,
  useMyLibrary,
} from '@libs/apps-shared/contexts';
import { MyLibraryBookItem } from '@libs/apps-shared/custom-types';
import { Book } from '@mylibrary/api-types';
import classNames from 'classnames';
import { addCircleOutline, pencilOutline } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import StripTags from 'striptags';
import * as yup from 'yup';
import ReadMore from '../../components/core/ReadMore';
import Input from '../../components/forms/Input';
import Textarea from '../../components/forms/Textarea';
import AvailableCopiesPill from '../../components/pills/AvailableBooksPill';
import { useAvailableCopies } from '../../hooks/useAvailableCopies';
import { getReadableList } from '../../utils/arrays';
import { getReadableDate } from '../../utils/dates';
import Button from '../core/Button';
import BookImg from './BookImg';

type FormValues = {
  pagesRead: number;
  customTotalPages?: number;
  numberOfCopies: number;
  notes: string;
};

const formSchema = yup
  .object()
  .shape({
    pagesRead: yup.number(),
    customTotalPages: yup.number(),
    numberOfCopies: yup
      .number()
      .typeError('Please provide a number.')
      .min(1, 'Cannot have less than 1 copy of a book.')
      .required('This field is required.'),
    notes: yup.string().notRequired(),
  })
  .required();

export interface BookDetailsForm {
  valid: boolean;
  values: FormValues;
}

const DEFAULT_FORM_VALUES: FormValues = {
  pagesRead: 0,
  customTotalPages: undefined,
  numberOfCopies: 1,
  notes: '',
};

interface Props {
  className: React.HTMLAttributes<HTMLDivElement>['className'];
  isbn: string;
  bookItemFromLibrary: MyLibraryBookItem | null;
  book: Book | undefined;
  bookLoading: boolean;
  bookDetailsChanged?: (bookDetails: BookDetailsForm) => void;
}

const BookDetailsTab = ({
  className,
  isbn,
  bookItemFromLibrary,
  book,
  bookLoading,
  bookDetailsChanged,
}: Props) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    shouldFocusError: false,
    defaultValues: DEFAULT_FORM_VALUES,
    mode: 'onChange',
  });
  const [presentPicker] = useIonPicker();
  const [presentAlert] = useIonAlert();
  const { updateBookInLibrary }: MyLibraryContextType = useMyLibrary();
  const { setLoading }: GeneralContextType = useGeneral();
  const { availableCopies } = useAvailableCopies(bookItemFromLibrary?.itemId);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [bookDetailsForm, setBookDetailsForm] = useState<BookDetailsForm>();

  /**
   * Opens the picker to choose the number of pages read.
   * @param {number} selectedIndex
   */
  const openPicker = useCallback(
    (selectedIndex?: number): void => {
      const totalPages: number | undefined =
        book?.pages || bookDetailsForm?.values.customTotalPages;
      if (totalPages) {
        let options: PickerColumnOption[] = [];
        for (let pageNum = 0; pageNum <= totalPages; pageNum++) {
          options.push({
            value: pageNum,
            text: pageNum.toString(),
          });
        }
        presentPicker({
          columns: [
            {
              name: 'pages',
              options,
              selectedIndex,
            },
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
            },
            {
              text: 'Confirm',
              handler: (value) => setValue('pagesRead', value.pages.value),
            },
          ],
        });
      }
    },
    [book, bookDetailsForm?.values.customTotalPages]
  );

  /**
   * Opens an alert to set the total number of pages.
   * @param {(...event: any[]) => void} onChange
   * @param {number} value
   */
  const openAlert = (onChange: (...event: any[]) => void, value?: number): void => {
    presentAlert({
      header: 'Please enter your info',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: (result) => onChange(result[0]),
        },
      ],
      inputs: [
        {
          type: 'number',
          placeholder: 'Pages',
          min: 1,
          value,
        },
      ],
    });
  };

  /**
   * Updates the book in the user's library.
   * @param {string} itemId The item id of the book in the library.
   * @param {boolean} [showLoading=true] Whether to show the loading spinner.
   */
  const updateBookInMyLibrary = async (
    itemId: string,
    showLoading: boolean = true
  ): Promise<void> => {
    const { numberOfCopies, pagesRead, customTotalPages, notes } = getValues();
    if (isbn && book && isValid) {
      if (showLoading) setLoading(true);
      try {
        await updateBookInLibrary(itemId, {
          ...(bookItemFromLibrary ? bookItemFromLibrary.item : {}),
          isbn,
          title: book.title,
          authors: book.authors,
          numberOfCopies,
          pagesRead,
          notes,
          customBookDetails: {
            pages: customTotalPages,
          },
        });
      } catch (error) {
        alert(JSON.stringify(error));
      } finally {
        if (showLoading) setLoading(false);
      }
    }
  };

  /**
   * On submit, saves the book details.
   */
  const onSubmit = async (): Promise<void> => {
    if (bookItemFromLibrary) {
      updateBookInMyLibrary(bookItemFromLibrary?.itemId);
    }
  };

  useEffect(() => {
    if (!!bookItemFromLibrary) {
      const { numberOfCopies, pagesRead, customTotalPages, notes } = getValues();
      if (bookItemFromLibrary.item.numberOfCopies !== numberOfCopies) {
        setValue('numberOfCopies', bookItemFromLibrary.item.numberOfCopies);
      }
      if (bookItemFromLibrary.item.pagesRead && bookItemFromLibrary.item.pagesRead !== pagesRead) {
        setValue('pagesRead', bookItemFromLibrary.item.pagesRead);
      }
      if (
        bookItemFromLibrary.item.customBookDetails?.pages &&
        bookItemFromLibrary.item.customBookDetails.pages !== customTotalPages
      ) {
        setValue('customTotalPages', bookItemFromLibrary.item.customBookDetails.pages);
      }
      if (bookItemFromLibrary.item.notes !== notes) {
        setValue('notes', bookItemFromLibrary.item.notes);
      }
    }
  }, [bookItemFromLibrary]);

  useEffect(() => {
    const sub = watch((values: Partial<FormValues>) => {
      setBookDetailsForm((prev: BookDetailsForm | undefined) => ({
        valid: prev?.valid || false,
        values: {
          pagesRead: values.pagesRead || DEFAULT_FORM_VALUES.pagesRead,
          customTotalPages: values.customTotalPages || DEFAULT_FORM_VALUES.customTotalPages,
          numberOfCopies: values.numberOfCopies || DEFAULT_FORM_VALUES.numberOfCopies,
          notes: values.notes || DEFAULT_FORM_VALUES.notes,
        },
      }));
    });
    return () => sub.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const values: FormValues = getValues();
    setBookDetailsForm({
      valid: isValid,
      values,
    });
  }, [isValid]);

  useEffect(() => {
    if (bookDetailsForm && bookDetailsChanged) bookDetailsChanged(bookDetailsForm);
  }, [bookDetailsForm]);

  useEffect(() => {
    // If any library book details don't match the book details from OpenLibrary,
    // update the book in the library.
    if (bookItemFromLibrary && book) {
      if (
        bookItemFromLibrary.item.title !== book.title ||
        bookItemFromLibrary.item.authors?.join(', ') !== book.authors?.join(', ')
      ) {
        updateBookInMyLibrary(bookItemFromLibrary.itemId);
      }
    }
  }, [bookItemFromLibrary, book]);

  return (
    <div className={classNames('relative', className)}>
      <div className="mb-6 flex flex-col justify-center items-center">
        {bookLoading ? (
          <IonSkeletonText animated className="w-[200px] h-[307px]" />
        ) : (
          book && (
            <BookImg
              className={classNames(
                'max-w-[200px] overflow-hidden rounded-md border dark:border-0 border-gray-100 shadow-xl',
                { 'w-[200px] h-[307px]': !imageLoaded }
              )}
              imageLink={book.image}
              target="large"
              onLoad={() => setImageLoaded(true)}
            />
          )
        )}
        {bookLoading ? (
          <div className="mt-8">
            <IonSkeletonText animated className="h-6 mb-4 w-32" />
          </div>
        ) : (
          !!bookItemFromLibrary && (
            <div className="mt-8">
              <AvailableCopiesPill itemId={bookItemFromLibrary?.itemId} />
            </div>
          )
        )}
      </div>
      {bookLoading ? (
        <>
          <IonSkeletonText animated className="h-12 mb-4" />
          <IonSkeletonText animated className="h-24 mb-4" />
        </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Input
            label="Number of Copies"
            name="numberOfCopies"
            placeholder="Number of Copies"
            enterkeyhint="enter"
            min={
              !!bookItemFromLibrary && bookItemFromLibrary.item.numberOfCopies !== availableCopies
                ? bookItemFromLibrary.item.numberOfCopies - availableCopies
                : 1
            }
            required={true}
            type="number"
            control={control}
            error={errors.numberOfCopies?.message}
          />
          <div>
            <IonLabel position="fixed">Pages Read</IonLabel>
            <div className="flex items-center">
              <Controller
                name="pagesRead"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const max: number | undefined = book?.pages
                    ? book.pages
                    : bookDetailsForm?.values.customTotalPages;
                  const disabled: boolean =
                    !book?.pages && !bookDetailsForm?.values.customTotalPages;
                  return (
                    <>
                      <div className="block flex-1">
                        <IonRange
                          min={0}
                          max={max}
                          disabled={disabled}
                          onIonChange={({ detail }) => onChange(detail.value)}
                          value={value}
                        />
                      </div>
                      <span
                        className={classNames(
                          'text-lg font-medium dark:text-text bg-gray-100 px-2 py-1 rounded',
                          {
                            'cursor-pointer': !disabled,
                          }
                        )}
                        onClick={() => (disabled ? undefined : openPicker(value))}
                      >
                        {value.toString()}
                      </span>
                    </>
                  );
                }}
              />
              <span className="text-xl font-medium mx-2">/</span>
              <Controller
                name="customTotalPages"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <span
                    className={classNames(
                      'text-lg font-medium dark:text-text bg-gray-100 px-2 py-1 rounded flex items-center',
                      {
                        'cursor-pointer': !book?.pages,
                      }
                    )}
                    onClick={() => (book?.pages ? undefined : openAlert(onChange, value))}
                  >
                    {book?.pages ? (
                      book.pages
                    ) : !!value ? (
                      <>
                        {value}
                        <IonIcon icon={pencilOutline} color="primary" className="ml-1" />
                      </>
                    ) : (
                      <>
                        <span className="ml-1">Pages</span>
                        <IonIcon icon={addCircleOutline} color="primary" className="ml-1" />
                      </>
                    )}
                  </span>
                )}
              />
            </div>
          </div>
          <Textarea
            label="Notes"
            name="notes"
            placeholder="Notes"
            enterkeyhint="enter"
            control={control}
            rows={3}
            error={errors.notes?.message}
          />
          {!!bookItemFromLibrary && (
            <Button text="Save Book Details" expand="block" color="success" disabled={!isDirty} />
          )}
        </form>
      )}
      {bookLoading ? (
        <>
          <IonSkeletonText animated className="h-6 mb-4" />
          <IonSkeletonText animated className="h-6 mb-4" />
          <IonSkeletonText animated className="h-6 mb-4" />
          <IonSkeletonText animated className="h-6 mb-4" />
          <IonSkeletonText animated className="h-6 mb-4" />
          <IonSkeletonText animated className="h-6 mb-4" />
          <IonSkeletonText animated className="h-6 mb-4" />
          <IonSkeletonText animated className="h-6 mb-4" />
          <IonSkeletonText animated className="h-6 mb-4" />
        </>
      ) : (
        <>
          {book && book.authors && book.authors.length > 0 && (
            <p className="text-lg mb-2">
              <span className="font-bold">By:</span>{' '}
              <ReadMore>{getReadableList(book.authors)}</ReadMore>
            </p>
          )}
          {book &&
            (book.synopsis ? (
              <p className="text-lg mb-2">
                <span className="font-bold">Synopsis:</span>{' '}
                <ReadMore>{StripTags(book.synopsis)}</ReadMore>
              </p>
            ) : book.overview ? (
              <p className="text-lg mb-2">
                <span className="font-bold">Overview:</span>{' '}
                <ReadMore>{StripTags(book.overview)}</ReadMore>
              </p>
            ) : book.synopsys ? (
              <p className="text-lg mb-2">
                <span className="font-bold">Synopsis:</span>{' '}
                <ReadMore>{StripTags(book.synopsys)}</ReadMore>
              </p>
            ) : (
              <></>
            ))}
          {book && book.subjects && book.subjects.length > 0 && (
            <p className="text-lg mb-2">
              <span className="font-bold">Categories:</span>{' '}
              <ReadMore>{getReadableList(book.subjects)}</ReadMore>
            </p>
          )}
          {book && book.pages && (
            <p className="text-lg mb-2">
              <span className="font-bold">Pages:</span> {book.pages}
            </p>
          )}
          {book && book.publisher && (
            <p className="text-lg mb-2">
              <span className="font-bold">Publisher:</span> {book.publisher}
            </p>
          )}
          {book && book.date_published && (
            <p className="text-lg mb-2">
              <span className="font-bold">Published Date:</span>{' '}
              {book.date_published.toString().length === 4
                ? book.date_published
                : getReadableDate(new Date(book.date_published))}
            </p>
          )}
          {book && book.isbn13 && (
            <p className="text-lg mb-2">
              <span className="font-bold">ISBN 13:</span> {book.isbn13}
            </p>
          )}
          {book && book.isbn && (
            <p className="text-lg mb-2">
              <span className="font-bold">ISBN:</span> {book.isbn}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default BookDetailsTab;
