/**
 * A function you can use to recursively check, when sorting, if one string should be
 * sorted before another.
 * @param {string} item1
 * @param {string} item2
 * @param {number} charAt
 * @returns {number}
 */
export const compareChars = (item1: string, item2: string, charAt: number): number => {
  // If the character index is larger than both items, at this point they're equal.
  if (item1.length < charAt + 1 && item2.length < charAt + 1) return 0;
  // If the character index is larger than the first item, it's greater.
  else if (item1.length === charAt + 1) return -1;
  // If the character index is larger than the second item, it's greater.
  else if (item2.length === charAt + 1) return 1;
  const item1CharAt: number = item1.toLowerCase().charCodeAt(charAt);
  const item2CharAt: number = item2.toLowerCase().charCodeAt(charAt);
  // If the characters are the same, we'll compare the next character.
  if (item1CharAt === item2CharAt) return compareChars(item1, item2, charAt + 1);
  // If the first character is greater than the second, the first is greater.
  return item1CharAt - item2CharAt;
};

/**
 * Given a list of strings, returns them in a human readable format.
 * @param {string[]} list
 * @returns {string}
 */
export const getReadableList = (list: string[]): string => {
  if (list.length === 1) return list[0];
  else if (list.length === 2) return `${list[0]} and ${list[1]}`;
  else if (list.length > 2)
    return list
      .map((listItem: string, index: number) => {
        if (list.length - 1 === index) return ` and ${listItem}`;
        else return ` ${listItem}`;
      })
      .join();
  return '';
};
