import { yupResolver } from '@hookform/resolvers/yup';
import { IonButton, IonIcon, useIonAlert } from '@ionic/react';
import { BorrowerInfoForm } from '@libs/apps-shared/custom-types';
import { getISODate } from 'apps/mylibrary/src/utils/dates';
import { closeCircleOutline } from 'ionicons/icons';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import DatePicker from '../../forms/DatePicker';
import Input from '../../forms/Input';
import Select, { SelectOption } from '../../forms/Select';
import Textarea from '../../forms/Textarea';

const today: Date = new Date();
const todayAsDate: Date = new Date(today);
const nextMonthAsDate: Date = new Date();
nextMonthAsDate.setMonth(todayAsDate.getMonth() + 1);
const DEFAULT_BORROW_DATE: string = getISODate(today);
const DEFAULT_DUE_DATE: string = getISODate(nextMonthAsDate);
// Calculate the default minimum due date.
const tomorrowAsDate: Date = new Date();
tomorrowAsDate.setDate(todayAsDate.getDate() + 1);
const DEFAULT_MIN_DUE_DATE: Date = new Date(tomorrowAsDate);
const DEFAULT_MIN_RETURN_DATE: Date = new Date(today);

const useYupValidationResolver = (
  showUsername: boolean,
  dateReturned: string | null,
  maxNumberOfCopies: number
) => {
  const validationSchema = yup
    .object()
    .shape({
      ...(showUsername
        ? {
            username: yup.string().required('This field is required.'),
            firstName: yup.string(),
          }
        : {
            firstName: yup.string().required('This field is required.'),
          }),
      lastName: yup.string(),
      phoneNumber: yup.string(),
      email: yup.string().email('Please provide a valid email address.'),
      borrowDate: yup.string().required('This field is required.'),
      dueDate: yup
        .string()
        .required('This field is required.')
        .test(
          'greater-than-borrow-date',
          'Due date must be 1 day past the borrow date.',
          (dueDate: string | undefined, context: yup.TestContext<Record<string, any>>) => {
            const borrowDateISO: string = getISODate(context.parent.borrowDate);
            if (!borrowDateISO || !dueDate) return true;
            const dueDateISO: string = getISODate(dueDate);
            const shouldBeGreaterThanDate: Date = new Date(borrowDateISO);
            shouldBeGreaterThanDate.setDate(shouldBeGreaterThanDate.getDate() + 1);
            return getISODate(shouldBeGreaterThanDate) <= dueDateISO;
          }
        ),
      ...(!!dateReturned
        ? {
            dateReturned: yup
              .string()
              .required('This field is required.')
              .test(
                'greater-than-or-equal-to-borrow-date',
                'Date returned cannot be before the book was borrowed.',
                (
                  dateReturned: string | undefined,
                  context: yup.TestContext<Record<string, any>>
                ) => {
                  const borrowDateISO: string = getISODate(context.parent.borrowDate);
                  if (!borrowDateISO || !dateReturned) return true;
                  const dateReturnedISO: string = getISODate(dateReturned);
                  return borrowDateISO <= dateReturnedISO;
                }
              ),
          }
        : {}),
      numberOfCopiesBorrowed: yup
        .number()
        .min(1, 'Must borrow at least 1 copy.')
        .max(maxNumberOfCopies, `Can't borrow more copies than are available.`)
        .required('This field is required.'),
      notes: yup.string(),
    })
    .required();
  return useCallback(yupResolver(validationSchema), [
    showUsername,
    dateReturned,
    maxNumberOfCopies,
  ]);
};

const DEFAULT_FORM_VALUES: FormValues = {
  username: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  borrowDate: DEFAULT_BORROW_DATE,
  dueDate: DEFAULT_DUE_DATE,
  numberOfCopiesBorrowed: 1,
  dateReturned: null,
  notes: '',
};

interface FormValues {
  username: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  borrowDate: string;
  dueDate: string;
  dateReturned: string | null;
  numberOfCopiesBorrowed: number;
  notes: string;
}

interface Props {
  username?: string;
  showUsername?: boolean;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  phoneNumberOptions?: SelectOption[];
  email?: string | null;
  emailOptions?: SelectOption[];
  borrowDate?: string;
  dueDate?: string;
  dateReturned?: string | null;
  numberOfCopiesBorrowed?: number;
  notes?: string | null;
  maxNumberOfCopies?: number;
  borrowerInfoChanged: (borrowerInfo: BorrowerInfoForm) => void;
  markAsStillBeingBorrowed: () => void;
}

const BorrowerForm = ({
  username,
  showUsername,
  firstName,
  lastName,
  phoneNumber,
  phoneNumberOptions = [],
  email,
  emailOptions = [],
  borrowDate,
  dueDate,
  dateReturned = null,
  numberOfCopiesBorrowed,
  notes,
  maxNumberOfCopies = 1,
  borrowerInfoChanged,
  markAsStillBeingBorrowed,
}: Props) => {
  const [presentConfirmModal] = useIonAlert();
  const resolver = useYupValidationResolver(
    !!showUsername,
    dateReturned || DEFAULT_FORM_VALUES.dateReturned,
    maxNumberOfCopies || DEFAULT_FORM_VALUES.numberOfCopiesBorrowed
  );
  // Set the borrow date to today and the due date to 1 month from today.
  const {
    control,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { errors, isValid },
    // @ts-ignore
  } = useForm<FormValues>({
    resolver,
    shouldFocusError: false,
    defaultValues: {
      username: username || DEFAULT_FORM_VALUES.username,
      firstName: firstName || DEFAULT_FORM_VALUES.firstName,
      lastName: lastName || DEFAULT_FORM_VALUES.lastName,
      phoneNumber: phoneNumber || DEFAULT_FORM_VALUES.phoneNumber,
      email: email || DEFAULT_FORM_VALUES.email,
      borrowDate: borrowDate || DEFAULT_FORM_VALUES.borrowDate,
      dueDate: dueDate || DEFAULT_FORM_VALUES.dueDate,
      dateReturned: dateReturned || DEFAULT_FORM_VALUES.dateReturned,
      numberOfCopiesBorrowed: numberOfCopiesBorrowed || DEFAULT_FORM_VALUES.numberOfCopiesBorrowed,
      notes: notes || DEFAULT_FORM_VALUES.notes,
    },
    mode: 'all',
  });
  // Always keeping track of the minimum due date, one day after the borrow date.
  const [minDueDate, setMinDueDate] = useState<Date>(() => {
    const minDueDateAsDate: Date = new Date(borrowDate ? borrowDate : DEFAULT_MIN_DUE_DATE);
    if (borrowDate) {
      minDueDateAsDate.setDate(minDueDateAsDate.getDate() + 1);
    }
    return minDueDateAsDate;
  });
  const [minDateReturned, setMinDateReturned] = useState<Date>(
    new Date(borrowDate ? borrowDate : DEFAULT_MIN_RETURN_DATE)
  );
  const [borrowerInfo, setBorrowerInfo] = useState<BorrowerInfoForm>();

  const onReturnUndo = (event: MouseEvent<HTMLIonButtonElement>) => {
    event?.stopPropagation();
    presentConfirmModal({
      header: 'Still lending your book?',
      message: `Your book's borrowing status will no longer be marked as returned.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: () => markAsStillBeingBorrowed(),
        },
      ],
    });
  };

  useEffect(() => {
    setValue('dateReturned', dateReturned ? dateReturned : null);
  }, [dateReturned]);

  useEffect(() => {
    const sub = watch((values: Partial<FormValues>) => {
      setBorrowerInfo((prev: BorrowerInfoForm | undefined) => ({
        valid: prev?.valid || false,
        values: {
          ...(showUsername
            ? {
                username: values.username || DEFAULT_FORM_VALUES.username,
                firstName: values.firstName || DEFAULT_FORM_VALUES.firstName,
              }
            : {
                firstName: values.firstName || DEFAULT_FORM_VALUES.firstName,
              }),
          lastName: values.lastName || DEFAULT_FORM_VALUES.lastName,
          phoneNumber: values.phoneNumber || DEFAULT_FORM_VALUES.phoneNumber,
          email: values.email || DEFAULT_FORM_VALUES.email,
          borrowDate: values.borrowDate || DEFAULT_FORM_VALUES.borrowDate,
          dueDate: values.dueDate || DEFAULT_FORM_VALUES.dueDate,
          dateReturned: values.dateReturned || DEFAULT_FORM_VALUES.dateReturned,
          numberOfCopiesBorrowed: DEFAULT_FORM_VALUES.numberOfCopiesBorrowed,
          notes: values.notes || DEFAULT_FORM_VALUES.notes,
        },
      }));
      // Set the minimum due date to one day later than the borrow date every
      // time the borrow date changes.
      if (values.borrowDate) {
        const borrowDate: Date = new Date(values.borrowDate);
        const newMinDueDate: Date = new Date(borrowDate);
        newMinDueDate.setDate(newMinDueDate.getDate() + 1);
        setMinDueDate(newMinDueDate);
        // Set the minimum date returned to the due date.
        setMinDateReturned(borrowDate);
      } else {
        setMinDueDate(DEFAULT_MIN_DUE_DATE);
        setMinDateReturned(DEFAULT_MIN_RETURN_DATE);
      }
    });
    return () => sub.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const values: FormValues = getValues();
    setBorrowerInfo({
      valid: isValid,
      values: {
        ...values,
        borrowDate: values.borrowDate,
        dueDate: values.dueDate,
        dateReturned: values.dateReturned || null,
      },
    });
  }, [isValid]);

  useEffect(() => {
    if (borrowerInfo) borrowerInfoChanged(borrowerInfo);
  }, [borrowerInfo]);

  return (
    <div>
      <form onSubmit={() => {}} noValidate>
        {showUsername && (
          <Input
            label="Username"
            name="username"
            placeholder="Username"
            autocomplete="username"
            enterkeyhint="next"
            required
            error={errors.username?.message}
            control={control}
          />
        )}
        <Input
          label="First Name"
          name="firstName"
          placeholder="First Name"
          autocomplete="given-name"
          enterkeyhint="next"
          required={!showUsername}
          error={errors.firstName?.message}
          control={control}
        />
        <Input
          label="Last Name"
          name="lastName"
          placeholder="Last Name"
          autocomplete="family-name"
          enterkeyhint="next"
          error={errors.lastName?.message}
          control={control}
        />
        {phoneNumberOptions.length > 1 ? (
          <Select
            options={phoneNumberOptions}
            label="Phone Number"
            name="phoneNumber"
            placeholder="Select"
            control={control}
            error={errors.phoneNumber?.message}
          />
        ) : (
          <Input
            label="Phone Number"
            name="phoneNumber"
            placeholder="Phone Number"
            autocomplete="tel"
            enterkeyhint="next"
            type="tel"
            error={errors.phoneNumber?.message}
            control={control}
          />
        )}
        {emailOptions.length > 1 ? (
          <Select
            options={emailOptions}
            label="Email"
            name="email"
            placeholder="Select"
            control={control}
            error={errors.email?.message}
          />
        ) : (
          <Input
            label="Email"
            name="email"
            placeholder="Email"
            type="email"
            autocomplete="email"
            enterkeyhint="next"
            error={errors.email?.message}
            control={control}
          />
        )}
        <hr className="bg-black dark:bg-white my-8" />
        <h2>Borrow Details</h2>
        <DatePicker
          label="Borrow Date"
          name="borrowDate"
          placeholder="Borrow Date"
          required
          error={errors.borrowDate?.message}
          control={control}
          onIonChange={() => trigger(['dueDate', 'dateReturned'])}
        />
        <DatePicker
          label="Due Date"
          name="dueDate"
          placeholder="Due Date"
          required
          min={minDueDate}
          error={errors.dueDate?.message}
          control={control}
        />
        {!!dateReturned && (
          <DatePicker
            label="Date Returned"
            name="dateReturned"
            placeholder="Date Returned"
            required
            min={minDateReturned}
            iconRight={
              <IonButton className="ml-2" color="warning" onClick={onReturnUndo}>
                <IonIcon icon={closeCircleOutline} className="text-xl" />
              </IonButton>
            }
            error={errors.dateReturned?.message}
            control={control}
          />
        )}
        <Input
          label="Number of Copies Borrowed"
          name="numberOfCopiesBorrowed"
          placeholder="Number of Copies"
          enterkeyhint="enter"
          required={true}
          type="number"
          min={1}
          max={maxNumberOfCopies}
          control={control}
          error={errors.numberOfCopiesBorrowed?.message}
        />
        <Textarea
          label="Notes"
          name="notes"
          placeholder="Notes"
          enterkeyhint="enter"
          control={control}
          rows={3}
          error={errors.notes?.message}
        />
      </form>
    </div>
  );
};

export default BorrowerForm;
