import { BorrowerInfoForm, BorrowerTypes } from '@libs/apps-shared/custom-types';
import React, { useEffect, useState } from 'react';
import BorrowerForm from './choose-borrower/BorrowerForm';
import ChooseContact from './choose-borrower/ChooseContact';
import ChooseUser from './choose-borrower/ChooseUser';

interface Props {
  borrowerType: BorrowerTypes;
  maxNumberOfCopies: number;
  borrowerInfoChanged: (borrowerInfo: BorrowerInfoForm) => void;
}

const ChooseBorrower = ({ borrowerType, maxNumberOfCopies, borrowerInfoChanged }: Props) => {
  const [borrowerInfo, setBorrowerInfo] = useState<React.ReactNode>();

  useEffect(() => {
    switch (borrowerType) {
      case 'user':
        setBorrowerInfo(
          <ChooseUser
            maxNumberOfCopies={maxNumberOfCopies}
            borrowerInfoChanged={borrowerInfoChanged}
          />
        );
        break;
      case 'contact':
        setBorrowerInfo(
          <ChooseContact
            maxNumberOfCopies={maxNumberOfCopies}
            borrowerInfoChanged={borrowerInfoChanged}
          />
        );
        break;
      case 'form':
        setBorrowerInfo(
          <BorrowerForm
            maxNumberOfCopies={maxNumberOfCopies}
            borrowerInfoChanged={borrowerInfoChanged}
            markAsStillBeingBorrowed={() => {}}
          />
        );
        break;
      default:
        setBorrowerInfo(<></>);
        break;
    }
  }, [borrowerType, maxNumberOfCopies]);

  return (
    <div className="p-4">
      <h2 className="mt-0">Borrower Information</h2>
      {borrowerInfo}
    </div>
  );
};

export default ChooseBorrower;
