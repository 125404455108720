import { createContext, ReactNode, useContext, useState } from 'react';

export type GeneralContextType = {
  setLoading: (loading: boolean) => void;
  setRedirectUrl: (redirectUrl: string | undefined) => void;
  loading: boolean;
  redirectUrl: string | undefined;
};

export const GeneralContext = createContext<GeneralContextType>({
  setLoading: (loading: boolean) => null,
  setRedirectUrl: (redirectUrl: string | undefined) => null,
  redirectUrl: undefined,
  loading: false,
});

export const useGeneral = () => useContext(GeneralContext);

interface Props {
  children: ReactNode;
}

export const GeneralProvider = ({ children }: Props) => {
  const [loading, setLoadingState] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<GeneralContextType['redirectUrl']>(undefined);

  /**
   * Sets the loading state.
   * @param {boolean} isLoading
   */
  const setLoading: GeneralContextType['setLoading'] = (isLoading: boolean): void => {
    setLoadingState(isLoading);
  };

  return (
    <GeneralContext.Provider
      value={{
        setLoading,
        loading,
        redirectUrl,
        setRedirectUrl,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};
