import { BarcodeScanner, ScanResult, SupportedFormat } from '@capacitor-community/barcode-scanner';
import { useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/react';
import { Scope } from '@sentry/types';
import { createContext, ReactNode, useContext, useState } from 'react';
import { ScanningResult, ScanningState, SupportedFormats } from '../custom-types/scanning';

enum CameraPermission {
  HasPermission = 0,
  JustDenied = 1,
  AlreadyDenied = 2,
}

export type ScanningContextType = {
  scanning: boolean;
  scan: (format: SupportedFormats) => Promise<ScanningResult>;
  prepare: typeof BarcodeScanner.prepare;
  closeScanner: () => void;
};

export const ScanningContext = createContext<ScanningContextType>({
  scanning: false,
  scan: async (format: SupportedFormats) => undefined,
  prepare: BarcodeScanner.prepare,
  closeScanner: () => null,
});

export const useScanning = () => useContext(ScanningContext);

interface Props {
  children: ReactNode;
}

export const ScanningProvider = ({ children }: Props) => {
  const [presentAlert] = useIonAlert();
  const [scanning, setScanning] = useState<ScanningState>(ScanningState.Closed);

  const didUserGrantPermission = async (): Promise<CameraPermission> => {
    // check if user already granted permission
    const status = await BarcodeScanner.checkPermission({ force: false });

    if (status.granted) {
      // user granted permission
      return CameraPermission.HasPermission;
    }

    if (status.denied) {
      // user denied permission
      return CameraPermission.AlreadyDenied;
    }

    if (status.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }

    if (status.restricted || status.unknown) {
      // ios only
      // probably means the permission has been denied
      return CameraPermission.AlreadyDenied;
    }

    // user has not denied permission
    // but the user also has not yet granted the permission
    // so request it
    const statusRequest = await BarcodeScanner.checkPermission({ force: true });

    if (statusRequest.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }

    if (statusRequest.granted) {
      // the user did grant the permission now
      return CameraPermission.HasPermission;
    }

    // user did not grant the permission, so he must have declined the request
    return CameraPermission.JustDenied;
  };

  const scan = async (format: SupportedFormats): Promise<ScanningResult> => {
    const hasPermission: CameraPermission = await didUserGrantPermission();
    if (hasPermission === CameraPermission.HasPermission) {
      try {
        BarcodeScanner.hideBackground();
        document.body.classList.add('scanner-content');
        setScanning(ScanningState.Scanning);
        const result: ScanResult = await BarcodeScanner.startScan({
          targetedFormats:
            format === SupportedFormats.Barcode
              ? [
                  SupportedFormat.UPC_A,
                  SupportedFormat.UPC_E,
                  SupportedFormat.UPC_EAN_EXTENSION,
                  SupportedFormat.EAN_8,
                  SupportedFormat.EAN_13,
                ]
              : [SupportedFormat.QR_CODE],
        });
        closeScanner(false);
        if (result.hasContent && !!result.content) {
          return result.content;
        }
        return;
      } catch (error) {
        Sentry.captureException(error, (scope: Scope) => {
          scope.setTag('function', 'Scanning.scan');
          return scope;
        });
        closeScanner();
        throw error;
      }
    } else if (hasPermission === CameraPermission.JustDenied) {
      return;
    } else if (hasPermission === CameraPermission.AlreadyDenied) {
      presentAlert({
        header: 'Camera Permissions',
        message: `In order to scan your book's barcodes, we need access to your camera.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Settings',
            handler: () => {
              BarcodeScanner.openAppSettings();
            },
          },
        ],
      });
    }
    return;
  };

  const closeScanner = (stopScan: boolean = true) => {
    BarcodeScanner.showBackground();
    setScanning(ScanningState.Closed);
    if (stopScan) BarcodeScanner.stopScan();
    document.body.classList.remove('scanner-content');
  };

  return (
    <ScanningContext.Provider
      value={{
        scanning: scanning === ScanningState.Scanning,
        scan,
        prepare: BarcodeScanner.prepare,
        closeScanner,
      }}
    >
      {children}
    </ScanningContext.Provider>
  );
};
