import Button, { ButtonProps } from '../core/Button';

type Props = {
  message: string;
  buttonProps?: ButtonProps;
};

const NoResults = ({ message, buttonProps }: Props) => {
  return (
    <div className="absolute inset-0 flex flex-col justify-center items-center text-center px-8">
      <p className="text-2xl mb-4">{message}</p>
      {!!buttonProps && <Button {...buttonProps} />}
    </div>
  );
};

export default NoResults;
