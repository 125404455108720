import { DatetimeChangeEventDetail } from '@ionic/core';
import { IonDatetime, IonLabel, IonModal } from '@ionic/react';
import classNames from 'classnames';
import { ReactNode, useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import { getISODate, getReadableDate } from '../../utils/dates';

type Props = {
  label?: string;
  name: string;
  placeholder: string;
  control: Control<any>;
  required?: boolean;
  min?: Date;
  max?: Date;
  error?: string;
  iconRight?: ReactNode;
  onIonChange?: (value: string) => void;
  onIonBlur?: (value: string) => void;
};

const DatePicker = ({
  label,
  name,
  placeholder,
  control,
  required = false,
  min = new Date('2000-01-01'),
  max = new Date('2099-12-31'),
  error,
  iconRight,
  onIonChange,
  onIonBlur,
}: Props) => {
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <div className="pb-4 relative">
      {!!label && (
        <div className="mb-1">
          <IonLabel position="fixed" color={!!error ? 'danger' : undefined}>
            {label}
            {required ? ' *' : ''}
          </IonLabel>
        </div>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => {
          const valueAsISO: string | undefined = value ? getISODate(value) : undefined;
          const minISO: string = getISODate(min);
          const maxISO: string = getISODate(max);
          return (
            <>
              <div className="flex justify-center items-center">
                <div
                  className={classNames(
                    'transform duration-150 ease-in-out rounded border-2 pl-2 py-[10px] cursor-pointer w-full',
                    {
                      'border-medium': !error,
                      'border-danger': !!error,
                    }
                  )}
                  onClick={() => modal.current?.present()}
                >
                  {!valueAsISO ? (
                    <span className="">{placeholder}</span>
                  ) : (
                    <span>{getReadableDate(valueAsISO)}</span>
                  )}
                </div>
                {iconRight && iconRight}
              </div>
              <IonModal className="datetime-modal" ref={modal}>
                <IonDatetime
                  presentation="date"
                  onIonChange={(event: CustomEvent<DatetimeChangeEventDetail>) => {
                    if (event.detail.value && event.detail.value !== value) {
                      const valueOnChange: string =
                        typeof event.detail.value === 'string'
                          ? event.detail.value
                          : event.detail.value.length > 0
                          ? event.detail.value[0]
                          : '';
                      onChange(valueOnChange);
                      if (onIonChange) onIonChange(valueOnChange);
                    }
                    modal.current?.dismiss();
                  }}
                  // Using onIonCancel because onIonBlur gets triggered right away.
                  onIonCancel={() => {
                    if (!!onIonBlur && !!valueAsISO) onIonBlur(valueAsISO);
                    onBlur();
                  }}
                  value={valueAsISO}
                  min={minISO}
                  max={maxISO}
                  showDefaultButtons={true}
                />
              </IonModal>
            </>
          );
        }}
      />
      <p
        className={classNames(
          'opacity-0 text-danger text-sm transform duration-150 ease-in-out absolute',
          { 'opacity-100': !!error }
        )}
      >
        {error}
      </p>
    </div>
  );
};

export default DatePicker;
