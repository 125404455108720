import { SearchbarChangeEventDetail } from '@ionic/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonSearchbar,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  ActivityContextType,
  AuthContextType,
  GeneralContextType,
  MyLibraryContextType,
  ScanningContextType,
  useActivity,
  useAuth,
  useGeneral,
  useMyLibrary,
  useScanning,
} from '@libs/apps-shared/contexts';
import {
  BorrowerInfoForm,
  BorrowerTypes,
  MyLibraryBookItem,
  UserbaseError,
} from '@libs/apps-shared/custom-types';
import classNames from 'classnames';
import { History } from 'history';
import { arrowBack, arrowForward, closeOutline, save } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import Button from '../../components/core/Button';
import ChooseBook from '../../components/lend-slides/ChooseBook';
import ChooseBorrower from '../../components/lend-slides/ChooseBorrower';
import SortPopover, { SortOptionKeys } from '../../components/search-filter-sort/SortPopover';
import { useAvailableCopies } from '../../hooks/useAvailableCopies';

interface Props {
  history: History;
}

const LendBook = ({ history }: Props) => {
  const slideRef = useRef<HTMLIonSlidesElement>(null);
  let { itemId } = useParams<{ itemId: string }>();
  const { currentUser }: AuthContextType = useAuth();
  const { setLoading }: GeneralContextType = useGeneral();
  const { myLibrary }: MyLibraryContextType = useMyLibrary();
  const { addActivity }: ActivityContextType = useActivity();
  const { scanning }: ScanningContextType = useScanning();
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activeSort, setActiveSort] = useState<SortOptionKeys>('date-desc');
  const [selectedBook, setSelectedBook] = useState<MyLibraryBookItem | null>(null);
  const { availableCopies } = useAvailableCopies(selectedBook?.itemId);
  const [borrowerType, setBorrowerType] = useState<BorrowerTypes>('form');
  const [borrowerInfoForm, setBorrowerInfoForm] = useState<BorrowerInfoForm>();

  /**
   * Sets the active tab when it changes.
   */
  const slideDidChange = async (): Promise<void> => {
    if (slideRef.current) setActiveSlideIndex(await slideRef.current.getActiveIndex());
  };

  const onPrevious = () => {
    slideRef.current?.slidePrev();
  };

  const onNext = async (): Promise<void> => {
    if (!itemId && activeSlideIndex === 0) {
      if (!selectedBook) {
        alert('Please select a book before proceeding.');
        return;
      }
      slideRef.current?.slideNext();
      // } else if (activeSlideIndex === (!!id ? 0 : 1)) {
      //   if (!borrowerType) {
      //     alert('Please select the borrower type before proceeding.');
      //     return;
      //   }
      //   slideRef.current?.slideNext();
    } else if (activeSlideIndex === (!!itemId ? 0 : 1)) {
      if (borrowerInfoForm?.valid && currentUser && selectedBook) {
        try {
          setLoading(true);
          await addActivity(borrowerType, {
            book: {
              itemId: selectedBook.itemId,
              isbn: selectedBook.item.isbn,
            },
            borrower: {
              ...(borrowerType === 'user'
                ? {
                    borrowerType: 'user',
                    username: borrowerInfoForm.values.username!,
                    firstName: borrowerInfoForm.values.firstName,
                  }
                : {
                    borrowerType,
                    firstName: borrowerInfoForm.values.firstName!,
                  }),
              lastName: borrowerInfoForm.values.lastName,
              phoneNumber: borrowerInfoForm.values.phoneNumber,
              email: borrowerInfoForm.values.email,
            },
            lender: {
              userId: currentUser.userId,
              username: currentUser.username,
            },
            borrowDate: borrowerInfoForm.values.borrowDate,
            dueDate: borrowerInfoForm.values.dueDate,
            numberOfCopiesBorrowed: borrowerInfoForm.values.numberOfCopiesBorrowed,
            notes: borrowerInfoForm.values.notes,
            dateReturned: null,
          });
          history.replace(`/app/library/details/${selectedBook.item.isbn}`, {
            activeTab: 'lending',
          });
        } catch (error) {
          const err: UserbaseError = error as UserbaseError;
          alert(err.message);
        } finally {
          setLoading(false);
        }
      } else {
        alert(`Not valid!`);
      }
    }
  };

  useEffect(() => {
    if (itemId) {
      const foundBook: MyLibraryBookItem | undefined = myLibrary.find(
        (myBook: MyLibraryBookItem) => {
          return myBook.itemId === itemId;
        }
      );
      if (!!foundBook) {
        setSelectedBook(foundBook);
      }
    }
  }, [itemId, myLibrary]);

  return (
    <IonPage>
      <Prompt
        message={(location) => {
          if (location.pathname.includes('/app/library/details/')) return true;
          return 'Going back will cancel your progress. Are you sure you want to cancel?';
        }}
      />
      <IonHeader>
        <IonToolbar className="pl-2">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={
                selectedBook ? `/app/library/details/${selectedBook.item.isbn}` : '/app/library'
              }
              text=""
              icon={closeOutline}
            />
          </IonButtons>
          <IonTitle>Lend Book</IonTitle>
        </IonToolbar>
        {!itemId && activeSlideIndex === 0 && (
          <IonToolbar>
            <IonSearchbar
              value={searchTerm}
              onIonChange={(e: CustomEvent<SearchbarChangeEventDetail>) => {
                setSearchTerm(e.detail.value || '');
              }}
              showClearButton="always"
              enterkeyhint="search"
            />
            <IonButtons slot="end">
              <SortPopover
                sortOptions={['date-asc', 'date-desc', 'az', 'za']}
                activeSort={activeSort}
                setActiveSort={setActiveSort}
              />
            </IonButtons>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent className={classNames({ hidden: scanning })}>
        <IonSlides
          ref={slideRef}
          options={{
            allowTouchMove: false,
            initialSlide: 0,
            speed: 400,
          }}
          onIonSlideDidChange={slideDidChange}
        >
          {!itemId && (
            <IonSlide className="block">
              <ChooseBook
                searchTerm={searchTerm}
                activeSort={activeSort}
                setSelectedBook={setSelectedBook}
                selectedBook={selectedBook}
              />
            </IonSlide>
          )}
          {/* <IonSlide className="block">
            <ChooseBorrowerType borrowerType={borrowerType} setBorrowerType={setBorrowerType} />
          </IonSlide> */}
          <IonSlide className="block">
            <ChooseBorrower
              borrowerType={borrowerType}
              maxNumberOfCopies={availableCopies}
              borrowerInfoChanged={setBorrowerInfoForm}
            />
          </IonSlide>
        </IonSlides>
      </IonContent>
      <IonFooter>
        <IonToolbar className="px-2">
          {/* 
            Revert these styling changes and conditionally showing the previous button when we 
            add back choosing borrower type.
          */}
          <div className={classNames('grid grid-rows-1 gap-4', { 'grid-cols-2': !itemId })}>
            {!itemId && (
              <Button
                text={
                  <>
                    <IonIcon icon={arrowBack} className="mr-2" />
                    <span>Previous</span>
                  </>
                }
                disabled={activeSlideIndex === 0}
                onClick={onPrevious}
              />
            )}
            <Button
              text={
                <>
                  {activeSlideIndex === (!!itemId ? 0 : 1) ? 'Save' : 'Next'}
                  <IonIcon
                    icon={activeSlideIndex === (!!itemId ? 0 : 1) ? save : arrowForward}
                    className="ml-2"
                  />
                </>
              }
              onClick={onNext}
              disabled={
                (activeSlideIndex === 0 && !selectedBook) ||
                (activeSlideIndex === (!!itemId ? 0 : 1) ? !borrowerInfoForm?.valid : false)
              }
              color={activeSlideIndex === (!!itemId ? 0 : 1) ? 'success' : undefined}
            />
          </div>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default LendBook;
