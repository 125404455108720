import { StripeEnvironment } from '@libs/apps-shared/custom-types';

export const stripe: StripeEnvironment = {
  price_1LUdweCoLr3qMb0HqPRSax7q: {
    PRICE: '$9.99',
    FREQUENCY: 'annual',
    ACTIVE: true,
  },
  price_1LUdx6CoLr3qMb0HD8TUvVIJ: {
    PRICE: '$0.99',
    FREQUENCY: 'monthly',
    ACTIVE: true,
  },
  price_1LJeNACoLr3qMb0HHACpMnkV: {
    PRICE: '$49.99',
    FREQUENCY: 'annual',
    ACTIVE: false,
  },
  price_1KLWDXCoLr3qMb0H1s0kYoGV: {
    PRICE: '$4.99',
    FREQUENCY: 'monthly',
    ACTIVE: false,
  },
};
