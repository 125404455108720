import { InfiniteScrollCustomEvent, SearchbarChangeEventDetail } from '@ionic/core';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonMenuButton,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import {
  AuthContextType,
  BooksContextType,
  GeneralContextType,
  MyLibraryContextType,
  ScanningContextType,
  useAuth,
  useBooks,
  useGeneral,
  useMyLibrary,
  useScanning,
} from '@libs/apps-shared/contexts';
import {
  MyLibraryBookItem,
  ScanningResult,
  SupportedFormats,
} from '@libs/apps-shared/custom-types';
import * as Sentry from '@sentry/react';
import { Scope } from '@sentry/types';
import { History } from 'history';
import { useCallback, useEffect, useState } from 'react';
import SearchBookByISBN from '../../components/books/SearchBookByISBN';
import SkeletonList from '../../components/lists/SkeletonList';
import MyLibraryListItem from '../../components/lists/library/MyLibraryListItem';
import ManageSubscriptionOverlay from '../../components/payments/ManageSubscriptionOverlay';
import TrialToolbar from '../../components/payments/TrialToolbar';
import NoResults from '../../components/search-filter-sort/NoResults';
import SortPopover, { SortOptionKeys } from '../../components/search-filter-sort/SortPopover';
import { compareChars } from '../../utils/arrays';
import { compareDates } from '../../utils/dates';

interface Props {
  history: History;
}

const Library = ({ history }: Props) => {
  const { redirectUrl, setRedirectUrl }: GeneralContextType = useGeneral();
  const { currentUser, subscriptionInfo }: AuthContextType = useAuth();
  const { myLibrary, isFetchingMyLibrary }: MyLibraryContextType = useMyLibrary();
  const { getBooks }: BooksContextType = useBooks();
  const { scan }: ScanningContextType = useScanning();
  const [getBooksError, setGetBooksError] = useState<string | null>(null);
  const [filteredMyLibrary, setFilteredMyLibrary] = useState<MyLibraryBookItem[]>([]);
  const [myLibraryToShow, setMyLibraryToShow] = useState<MyLibraryBookItem[]>([]);
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activeSort, setActiveSort] = useState<SortOptionKeys>('date-desc');
  const [isMobileApp] = useState<boolean>(!isPlatform('desktop') && !isPlatform('mobileweb'));
  const [showSearchBookByISBNModal, setShowSearchBookByISBNModal] = useState<boolean>(false);

  /**
   * Handles when a book has been clicked.
   */
  const onBookItemClick = useCallback((isbn?: string) => {
    if (!!isbn) {
      history.push(`/app/library/details/${isbn}`);
    }
  }, []);

  /**
   * Loads more books into view on scroll.
   * @param {CustomEvent<Void>} event
   */
  const loadMoreBooks = (event: InfiniteScrollCustomEvent): void => {
    let numberToAdd: number = 10;
    if (itemsToShow + 10 > filteredMyLibrary.length) {
      numberToAdd = filteredMyLibrary.length - itemsToShow;
    }
    setItemsToShow(itemsToShow + numberToAdd);
    event.target.complete();
  };

  /**
   * Opens the scanner and handles the result.
   */
  const openScanner = async () => {
    try {
      const result: ScanningResult = await scan(SupportedFormats.Barcode);
      if (!!result) {
        history.push(`/app/library/details/${result}`);
      }
    } catch (error) {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'Library.openScanner');
        return scope;
      });
      alert(JSON.stringify(error));
    }
  };

  useEffect(() => {
    let myLibraryToSearchAndFilter: MyLibraryBookItem[] = [];
    if (searchTerm) {
      myLibraryToSearchAndFilter = [
        ...myLibrary.filter(({ item }: MyLibraryBookItem) =>
          item.title?.toLowerCase().includes(searchTerm.toLowerCase())
        ),
      ];
    } else {
      myLibraryToSearchAndFilter = [...myLibrary];
    }
    if (activeSort === 'date-desc') {
      myLibraryToSearchAndFilter.sort((item1: MyLibraryBookItem, item2: MyLibraryBookItem) =>
        compareDates(item2.createdBy.timestamp, item1.createdBy.timestamp)
      );
    } else if (activeSort === 'date-asc') {
      myLibraryToSearchAndFilter.sort((item1: MyLibraryBookItem, item2: MyLibraryBookItem) =>
        compareDates(item1.createdBy.timestamp, item2.createdBy.timestamp)
      );
    } else if (activeSort === 'az') {
      myLibraryToSearchAndFilter.sort((item1: MyLibraryBookItem, item2: MyLibraryBookItem) =>
        compareChars(item1.item.title || '', item2.item.title || '', 0)
      );
    } else if (activeSort === 'za') {
      myLibraryToSearchAndFilter.sort((item1: MyLibraryBookItem, item2: MyLibraryBookItem) =>
        compareChars(item2.item.title || '', item1.item.title || '', 0)
      );
    }
    setFilteredMyLibrary([...myLibraryToSearchAndFilter]);
  }, [searchTerm, activeSort, myLibrary]);

  useEffect(() => {
    setMyLibraryToShow(filteredMyLibrary.slice(0, itemsToShow));
  }, [filteredMyLibrary, itemsToShow]);

  useEffect(() => {
    (async () => {
      if (!!currentUser && myLibrary.length > 0) {
        try {
          await getBooks(
            myLibraryToShow.map(({ item }) => {
              return item.isbn;
            }),
            currentUser.authToken
          );
        } catch (error) {
          setGetBooksError((error as Error).message);
        }
      }
    })();
  }, [currentUser, myLibraryToShow]);

  useEffect(() => {
    if (!!redirectUrl) {
      history.push(redirectUrl);
      setRedirectUrl(undefined);
    }
  }, [redirectUrl]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton id="sideMenu"></IonMenuButton>
          </IonButtons>
          <IonTitle id="library-tab-title">Library</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={searchTerm}
            onIonChange={(e: CustomEvent<SearchbarChangeEventDetail>) =>
              setSearchTerm(e.detail.value || '')
            }
            showClearButton="always"
            disabled={subscriptionInfo?.disableAppUse}
          />
          <IonButtons slot="end">
            <SortPopover
              sortOptions={['date-asc', 'date-desc', 'az', 'za']}
              activeSort={activeSort}
              setActiveSort={setActiveSort}
            />
          </IonButtons>
        </IonToolbar>
        <TrialToolbar />
      </IonHeader>
      <IonContent scrollY={!!subscriptionInfo ? !subscriptionInfo.disableAppUse : true}>
        <ManageSubscriptionOverlay showInstallAppModal />
        {!getBooksError ? (
          isFetchingMyLibrary ? (
            <SkeletonList type="library" />
          ) : filteredMyLibrary.length > 0 ? (
            <>
              <IonList>
                {myLibraryToShow.map(({ item, itemId }: MyLibraryBookItem) => {
                  return (
                    <MyLibraryListItem
                      key={`my-library-list-item-${itemId}`}
                      isbn={item.isbn}
                      pagesRead={item.pagesRead || 0}
                      customTotalPages={item.customBookDetails?.pages}
                      showSwipeableActions
                      itemId={itemId}
                      onClick={onBookItemClick}
                    />
                  );
                })}
              </IonList>
              <IonInfiniteScroll
                onIonInfinite={loadMoreBooks}
                threshold="100px"
                disabled={itemsToShow >= filteredMyLibrary.length}
              >
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading more data…"
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </>
          ) : (
            <NoResults
              message={
                isMobileApp
                  ? "Scan a book's barcode to add it to your Library!"
                  : `Search by a book's ISBN to add it to your Library!`
              }
              buttonProps={{
                text: isMobileApp ? 'Scan Barcode' : 'Search by ISBN',
                size: 'large',
                onClick: isMobileApp ? openScanner : () => setShowSearchBookByISBNModal(true),
              }}
            />
          )
        ) : (
          <NoResults message={`There was an error loading your library: ${getBooksError}`} />
        )}
        <SearchBookByISBN
          showModal={showSearchBookByISBNModal}
          onClose={() => setShowSearchBookByISBNModal(false)}
        />
      </IonContent>
    </IonPage>
  );
};

export default Library;
