import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import { AuthContextType, useAuth } from '@libs/apps-shared/contexts';
import { useHistory } from 'react-router';
import Button from '../core/Button';
import InstallAppModal from '../InstallAppModal';

interface OverlayProps {
  title: string;
}

const Overlay = ({ title }: OverlayProps) => {
  const history = useHistory();
  return (
    <div className="fixed inset-0 flex flex-col justify-center items-center z-50">
      <div className="absolute inset-0 bg-black opacity-50" />
      <IonCard>
        <IonCardHeader>
          <IonCardTitle id="manage-sub-overlay-title" color="danger">
            {title}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent color="primary">
          To continue having full access, purchase a monthly or yearly subscription.
          <div className="flex justify-end mt-4">
            <Button
              id="manage-sub-overlay-btn"
              text="Manage Subscription"
              color="primary"
              onClick={() => history.push('/app/settings/manage-subscription')}
            />
          </div>
        </IonCardContent>
      </IonCard>
    </div>
  );
};

interface Props {
  showInstallAppModal?: boolean;
}

const ManageSubscriptionOverlay = ({ showInstallAppModal = false }: Props) => {
  const { subscriptionInfo }: AuthContextType = useAuth();

  if (!!subscriptionInfo) {
    if (!!subscriptionInfo.subscriptionDetails && !!subscriptionInfo.subscriptionStatuses) {
      // First check is if the user's subscription is canceled.
      if (subscriptionInfo.subscriptionStatuses.isCancelled) {
        // Even though their subscription is canceled, make sure they don't have any free trial left.
        if (!subscriptionInfo.trialDetails.isInTrial) {
          return <Overlay title="You cancelled your subscription" />;
        }
      } else if (
        !subscriptionInfo.subscriptionStatuses.isActive &&
        !subscriptionInfo.subscriptionStatuses.setToCancel
      ) {
        return <Overlay title="Your free trial has ended" />;
      }
      // If the user has never bought a subscription, make sure they don't have any free trial left.
    } else if (!subscriptionInfo.trialDetails.isInTrial) {
      return <Overlay title="Your free trial has ended" />;
    }
  }
  return showInstallAppModal ? <InstallAppModal /> : <></>;
};

export default ManageSubscriptionOverlay;
